import { Action, createReducer, on } from "@ngrx/store";

import * as ZenotiActions from "../actions/zenoti.actions";

export interface State {
  loading: boolean;
  error: boolean;
}

export const initialState: State = {
  loading: false,
  error: undefined,
};

const zenotiReducer = createReducer(
  initialState,
  on(ZenotiActions.addProvider, (state) => ({ ...state, loading: true })),
  on(ZenotiActions.addProviderSuccess, (state) => ({ ...state, loading: false })),
  on(ZenotiActions.addProviderFail, (state) => ({ ...state, loading: false, error: true })),
  on(ZenotiActions.close, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return zenotiReducer(state, action);
}

export const featureKey = "zenoti";
