<div class="page-content">
  <ng-container *ngIf="(loading$ | async) === false; else loadingElement">
    <ng-container *ngIf="(done$ | async) === false">
      <h4>Revoke access to <span class="highlight-text">Square?</span></h4>

      <alert-component type="warning">
        <p>
          Vish will no longer have access to your Square Account. You’ll need to grant access if you want to use this app or service again.
        </p>
      </alert-component>
      <div
        class="controls"
        style="position: relative; margin: 0 10%">
        <button
          (click)="revokeAccess()"
          type="button"
          class="button primary"
          style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
          Revoke Access
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="(done$ | async) === true">
      <ng-container *ngIf="(error$ | async) === false">
        <h4>Access Revoked Successfully</h4>

        <alert-component type="success">
          <p>Access to Square has been revoked. You can close this panel now.</p>
        </alert-component>
      </ng-container>
      <ng-container *ngIf="(error$ | async) === true">
        <h4>Something went wrong</h4>

        <alert-component type="danger">
          <p>Something went wrong. Please try again.</p>
        </alert-component>
      </ng-container>

      <div class="controls">
        <button
          (click)="done()"
          class="button primary">
          <span>Done</span>
        </button>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #loadingElement>
    <large-loading-indicator></large-loading-indicator>
  </ng-template>
</div>
