<ng-container *ngIf="integrations | notEmpty; else emptyState">
  <table>
    <thead>
      <tr>
        <th></th>
        <th>Provider</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let integration of integrations">
        <td class="icon-container">
          <img
            src="{{ integration.logo }}"
            alt="integration logo" />
        </td>
        <td>
          <span class="row-title">{{ integration.name }}</span>
        </td>
        <td></td>
        <td>
          <span class="button-container">
            <button
              *ngIf="integration.active === false"
              class="button primary"
              (click)="addProvider.emit(integration)">
              Add
            </button>
            <button
              *ngIf="integration.active === true && integration.canEdit === true"
              class="button primary"
              (click)="editProvider.emit(integration)">
              Manage
            </button>
            <button
              *ngIf="integration.active === true && integration.enabled === false && isAdmin() | async"
              class="button outline"
              (click)="enableProvider.emit(integration)">
              Enable
            </button>
            <button
              *ngIf="integration.active === true && integration.enabled === false && integration.type === 'pos' && isAdmin() | async"
              class="button outline"
              (click)="mapProvider.emit(integration)">
              Map Data
            </button>
            <button
              *ngIf="integration.active === true && integration.enabled === true && isAdmin() | async"
              class="button outline"
              (click)="disableProvider.emit(integration)">
              Disable
            </button>
            <button
              *ngIf="integration.active === true && integration.canEdit === false && integration.canRemove"
              class="button outline"
              (click)="removeProvider.emit(integration)">
              Remove
            </button>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
<ng-template #emptyState>
  <empty-state-message
    title="No Integrations Available"
    icon="plug">
    Looks like there aren't any integrations available for your salon. Please contact customer support if this doesn't seem right.
  </empty-state-message>
</ng-template>
