import { Action, createReducer, on } from "@ngrx/store";
import * as RosyActions from "../actions/rosy.actions";

export interface RosyState {
  loading: boolean;
  error: boolean;
}

export const initialState: RosyState = {
  loading: false,
  error: undefined,
};

const rosyReducer = createReducer(
  initialState,
  on(RosyActions.addProvider, (state) => ({ ...state, loading: true })),
  on(RosyActions.addProviderSuccess, (state) => ({ ...state, loading: false })),
  on(RosyActions.addProviderFail, (state) => ({ ...state, loading: false, error: true })),
  on(RosyActions.close, () => initialState)
);

export function reducer(state: RosyState | undefined, action: Action) {
  return rosyReducer(state, action);
}

export const rosyFeatureKey = "rosy";
