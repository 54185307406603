import { Injectable } from "@angular/core";
import { HttpError } from "@getvish/stockpile";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { AddIntegrationRequest } from "app/+integrations/models/mytime-integration-provider";
import { SalonSoftwareIntegrationService } from "app/+integrations/services";
import { go } from "app/kernel/store/actions/router.actions";
import { catchError, map, mapTo, switchMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import { either } from "fp-ts";

import { AppState } from "app/kernel";
import { Close } from "../integrations.actions";
import { getApiKey } from "../selectors/mytime.selector";

import * as actions from "../actions/mytime.actions";

@Injectable()
export class MytimeEffects {
  navigateAddMytimeProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateAddMytimeProvider),
      mapTo(go({ path: ["/integrations", { outlets: { panel: "add-provider/mytime" } }] }))
    )
  );

  fetchLocationsRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.fetchLocationsRequest),
      switchMap(({ request }) =>
        this._salonSoftwareIntegrationService.findMytimeAvailableSalons(request).pipe(
          map((locations) => actions.fetchLocationsRequestSuccess({ availableSalons: locations })),
          catchError((error) => of(actions.fetchLocationsRequestFail({ error })))
        )
      )
    )
  );

  addProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addProvider),
      map((action) => action.location),
      withLatestFrom(this._store.pipe(select(getApiKey))),
      switchMap(([location, apiKey]) => {
        const request: AddIntegrationRequest = { locationId: location.mytime_id.toString(), apiKey, isEnabled: false };

        return this._salonSoftwareIntegrationService.addMytimeProvider(request).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => actions.addProviderFail({ error }),
              () => actions.addProviderSuccess()
            )
          )
        );
      })
    )
  );

  close$ = createEffect(() => this._actions$.pipe(ofType(actions.close, actions.addProviderSuccess), mapTo(new Close())));

  constructor(
    private _store: Store<AppState>,
    private _actions$: Actions,
    private _salonSoftwareIntegrationService: SalonSoftwareIntegrationService
  ) {}
}
