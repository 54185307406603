import { Injectable } from "@angular/core";
import { HttpError } from "@getvish/stockpile";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { SalonSoftwareIntegrationService } from "app/+integrations/services";
import { go } from "app/kernel/store/actions/router.actions";
import { map, mapTo, switchMap } from "rxjs/operators";
import { either } from "fp-ts";
import { Close } from "../integrations.actions";

import * as actions from "../actions/zenoti.actions";

@Injectable()
export class ZenotiEffects {
  public navigateAddZenotiProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateAddZenotiProvider),
      mapTo(go({ path: ["/integrations", { outlets: { panel: "add-provider/zenoti" } }] }))
    )
  );

  public addProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addProvider),
      map((action) => action.request),
      switchMap((integrationRequest) =>
        this._salonSoftwareIntegrationService.addZenotiProvider(integrationRequest).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => actions.addProviderFail({ error }),
              () => actions.addProviderSuccess()
            )
          )
        )
      )
    )
  );

  public close$ = createEffect(() => this._actions$.pipe(ofType(actions.close, actions.addProviderSuccess), mapTo(new Close())));

  constructor(
    private _actions$: Actions,
    private _salonSoftwareIntegrationService: SalonSoftwareIntegrationService
  ) {}
}
