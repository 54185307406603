<dialog-contents
  *ngIf="selectedProductsGroups$ | async as selectedProductsGroups"
  id="dialog-container"
  dialogTitle="Import Products"
  [progressText]="(pricingProducts$ | async) ? 'Pricing Products...' : undefined"
  (close)="dismiss(selectedProductsGroups)">
  <div class="dialog-content">
    <mat-stepper [selectedIndex]="importStep$ | async">
      <mat-step
        class="manufacturer-select-step"
        label="Select a manufacturer">
        <div
          *ngIf="loading$ | async"
          class="loading-container">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
        <div
          *ngIf="(loading$ | async) === false"
          class="manufacturers-container">
          <manufacturers-list
            class="manufacturers-container-panel small"
            [filteredManufacturers]="manufacturers$ | async"
            [options]="{ showNumProducts: true, showPricingIncomplete: false }"
            (searchedByName)="setSearchFilter($event)"
            (manufacturerSelected)="selectManufacturer($event)">
          </manufacturers-list>
        </div>
        <div
          class="btn-container"
          *ngIf="hasSeenConfirm$ | async">
          <button
            (click)="confirmImportedProducts()"
            class="button inverted small back-btn">
            Back
          </button>
        </div>
      </mat-step>
      <mat-step label="Select a product">
        <ng-container *ngIf="(loading$ | async) === false">
          <div
            *ngIf="salonProducts$ | async as salonProducts"
            class="select-container">
            <div class="product-search-container">
              <search-input
                class="product-name-search"
                placeholder="Search for a product or category"
                (filter)="setSearchFilter($event)"></search-input>
              <div class="available-filters">
                <mat-select
                  [value]="flagFilters$ | async"
                  (selectionChange)="setFlagFilters($event.value)"
                  placeholder="Filters"
                  panelWidth=""
                  multiple>
                  <mat-option
                    *ngFor="let filter of availableFilters"
                    [value]="filter.key">
                    {{ filter.val }}
                  </mat-option>
                </mat-select>
              </div>
              <button
                *ngIf="(allProductsSelected$ | async) === false"
                class="button outline small"
                (click)="selectProducts(salonProducts)">
                Select All
              </button>
              <button
                *ngIf="allProductsSelected$ | async"
                class="button outline small"
                (click)="deselectProducts(salonProducts)">
                Deselect All
              </button>
            </div>
            <breadcrumbs-line
              class="breadcrumbs-line"
              [items]="breadcrumbsItems$ | async"
              (itemSelected)="popToCategory($event._id)">
              <span
                class="breadcrumb-item"
                (click)="clearSelectedCategories()"
                >{{ (selectedManufacturer$ | async)?.name }}</span
              >
            </breadcrumbs-line>
            <div class="products-details-section">
              <products-categories-list
                class="categories-filter"
                [productCategories]="productCategories$ | async"
                (categorySelected)="pushCategory($event)"></products-categories-list>
              <select-products-grid
                class="product-grid"
                [products]="salonProducts"
                (toggleProduct)="toggleProduct($event)"
                (selectProducts)="selectProducts($event)"
                (deselectProducts)="deselectProducts($event)"></select-products-grid>
            </div>
            <div class="btn-container">
              <button
                *ngIf="(previousStep$ | async) !== 3"
                (click)="previousStep()"
                class="button inverted small back-btn">
                Back
              </button>
              <div *ngIf="(previousStep$ | async) === 3"></div>
              <button
                (click)="verifyPricing()"
                class="button small"
                [disabled]="false">
                Next
              </button>
            </div>
          </div>
        </ng-container>
      </mat-step>
      <mat-step label="Verify pricing">
        <div class="verify-pricing">
          <verify-pricing-step></verify-pricing-step>
          <div class="btn-container">
            <button
              (click)="backToProductList()"
              class="button inverted small back-btn">
              Back
            </button>
            <button
              (click)="confirmImportedProducts()"
              class="button small next-btn"
              [matTooltip]="(pricingComplete$ | async) !== true ? 'Pricing incomplete' : undefined">
              <fa-icon *ngIf="(pricingComplete$ | async) !== true" icon="circle-exclamation"></fa-icon> Next
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Review and Save">
        <div class="review-container">
          <div class="selected-manufacturers-container">
            <div class="selected-manufacturer-panel">
              <manufacturer-card
                *ngFor="let group of selectedProductsGroups; trackBy: trackByGroup"
                class="selected-manufacturer-card"
                [manufacturer]="group.manufacturer"
                (clicked)="selectManufacturer(group.manufacturer)">
                <mat-chip
                  footer
                  class="">
                  {{ group.products.length }} Product{{ group.products.length > 1 ? "s" : "" }} Selected
                </mat-chip>
                <div header-actions>
                  <a
                    [matMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"
                    class="button secondary transparent">
                    <fa-icon icon="ellipsis-v"></fa-icon>
                  </a>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="selectManufacturer(group.manufacturer)">
                      Edit
                    </button>
                    <button
                      mat-menu-item
                      (click)="removeAllProductsForManufacturer(group.manufacturer)">
                      Delete
                    </button>
                  </mat-menu>
                </div>
              </manufacturer-card>
              <add-manufacturer-card
                class="selected-manufacturer-card"
                (clicked)="importAdditional()">
              </add-manufacturer-card>
            </div>
          </div>
          <div class="btn-container save-btn-container">
            <button
              [disabled]="selectedProductsGroups | isEmpty"
              (click)="save(selectedProductsGroups)"
              class="button">
              <span *ngIf="(saving$ | async) === false">Import</span>
              <mat-spinner
                *ngIf="saving$ | async"
                diameter="25"></mat-spinner>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</dialog-contents>
