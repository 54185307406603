import { Action, createReducer, on } from "@ngrx/store";
import { LocationView } from "app/+integrations/models/mytime-integration-provider";
import * as MytimeActions from "../actions/mytime.actions";

export interface MytimeState {
  loading: boolean;
  availableSalons: LocationView[];
  error: string;
  apiKey: string;
}

export const initialState: MytimeState = {
  loading: false,
  availableSalons: undefined,
  error: undefined,
  apiKey: undefined,
};

const mytimeReducer = createReducer(
  initialState,
  on(MytimeActions.fetchLocationsRequest, (state, { request }) => ({
    ...state,
    loading: true,
    apiKey: request.apiKey,
  })),
  on(MytimeActions.fetchLocationsRequestSuccess, (state, { availableSalons }) => ({
    ...state,
    loading: false,
    error: undefined,
    availableSalons,
  })),
  on(MytimeActions.fetchLocationsRequestFail, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),
  on(MytimeActions.addProviderSuccess, (state) => ({ ...state, loading: false, error: undefined })),
  on(MytimeActions.addProviderFail, (state, { error }) => ({ ...state, loading: false, error: JSON.stringify(error.payload) })),
  on(MytimeActions.close, () => initialState)
);

export function reducer(state: MytimeState | undefined, action: Action) {
  return mytimeReducer(state, action);
}

export const mytimeFeatureKey = "mytime";
