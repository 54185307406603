import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { complement, isNil } from "ramda";

import { FetchLocationsRequest, LocationView } from "../models/meevo2-integration-provider";
import { addProvider, close, fetchLocationsRequest } from "../store/actions/meevo2.actions";
import { getAvailableSalons, getLoading, getError } from "../store/selectors/meevo2.selector";

@Component({
  selector: "add-provider-meevo2-container",
  templateUrl: "add-provider-meevo2.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderMeevo2Container {
  public availableSalons$: Observable<LocationView[]>;
  public loading$: Observable<boolean>;
  public error$: Observable<string>;
  public isStep1$: Observable<boolean>;
  public isStep2$: Observable<boolean>;

  constructor(public _store: Store<AppState>) {
    this.availableSalons$ = this._store.select(getAvailableSalons);
    this.loading$ = this._store.select(getLoading);
    this.error$ = this._store.select(getError);

    this.isStep1$ = this.availableSalons$.pipe(map(isNil));

    this.isStep2$ = this.availableSalons$.pipe(map(complement(isNil)));
  }

  public fetchLocationsRequest(request: FetchLocationsRequest): void {
    this._store.dispatch(fetchLocationsRequest({ request }));
  }

  public chooseLocation(location: LocationView): void {
    this._store.dispatch(addProvider({ location }));
  }

  public close(): void {
    this._store.dispatch(close());
  }
}
