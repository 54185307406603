import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { complement, isNil } from "ramda";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FetchLocationsRequest, LocationView } from "../models/boulevard-integration-provider";
import { fetchLocationsRequest, addProvider, close } from "../store/actions/boulevard.actions";
import { getAvailableSalons, getLoading, getError } from "../store/selectors";

@Component({
  selector: "add-provider-boulevard-container",
  templateUrl: "add-provider-boulevard.container.html",
})
export class AddProviderBoulevardContainer {
  public availableSalons$: Observable<LocationView[]>;

  public loading$: Observable<boolean>;
  public error$: Observable<boolean>;
  public isStep1$: Observable<boolean>;
  public isStep2$: Observable<boolean>;

  constructor(public _store: Store<AppState>) {
    this.availableSalons$ = this._store.select(getAvailableSalons);
    this.loading$ = this._store.select(getLoading);
    this.error$ = this._store.select(getError);

    this.isStep1$ = this.availableSalons$.pipe(map(isNil));

    this.isStep2$ = this.availableSalons$.pipe(map(complement(isNil)));
  }

  public fetchLocationsRequest(locationsRequest: FetchLocationsRequest): void {
    this._store.dispatch(fetchLocationsRequest({ locationsRequest }));
  }

  public chooseLocation(location: LocationView): void {
    this._store.dispatch(addProvider({ location }));
  }

  public close(): void {
    this._store.dispatch(close());
  }
}
