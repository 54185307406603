import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LocationView } from "app/+integrations/models/mytime-integration-provider";

@Component({
  selector: "mytime-choose-location",
  templateUrl: "mytime-choose-location.component.html",
  styleUrls: ["mytime-choose-location.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MytimeChooseLocationComponent implements OnInit {
  @Input() availableSalons: LocationView[];
  @Output() save: EventEmitter<LocationView>;

  public form: FormGroup;

  constructor(private _fb: FormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      salon: [undefined, Validators.required],
    });
  }

  public saveForm(value): void {
    this.save.emit(value.salon);
  }
}
