import { ProductCategory } from "@getvish/model";
import { isNil, sort } from "ramda";

export const createSortedCategoryChain = (categories: ProductCategory[]) => {
  const catById = categories.reduce((acc, c) => {
    acc[c._id] = c;
    return acc;
  }, {});

  const createChain = (category: ProductCategory, acc: ProductCategory[] = []) => {
    const parent = catById[category.parentCategoryId];

    if (parent != null) {
      createChain(parent, acc);
    }

    acc.push(category);
    return acc;
  };

  const categoryChains = categories.reduce((acc, category) => {
    acc[category._id] = createChain(category);
    return acc;
  }, {});

  const sorted = sort((c1, c2) => {
    const chain1 = categoryChains[c1._id];
    const chain2 = categoryChains[c2._id];

    const rootCategory1 = chain1[0];
    const rootCategory2 = chain2[0];

    if (rootCategory1._id === rootCategory2._id) {
      return chain1.reduce((c, category, i) => {
        if (c !== 0) {
          return c;
        }

        const category2 = chain2.length > i ? chain2[i] : undefined;

        if (isNil(category2)) {
          return 1;
        } else if (category._id === category2._id) {
          return 0;
        }

        const order1 = category.order ?? Number.MAX_SAFE_INTEGER;
        const order2 = category2.order ?? Number.MAX_SAFE_INTEGER;

        return order1 - order2;
      }, 0);
    } else {
      const order1 = rootCategory1.order ?? Number.MAX_SAFE_INTEGER;
      const order2 = rootCategory2.order ?? Number.MAX_SAFE_INTEGER;

      const ret = order1 - order2;
      return ret === 0 ? rootCategory1.name.localeCompare(rootCategory2.name) : ret;
    }
  }, categories);

  return sorted;
}
