<slideout-panel (close)="close()">
  <header panel-header>Add MyTime Integration</header>

  <div panel-body>
    <div *ngIf="error$ | async | isDefined">
      <alert-component type="danger">
        <h6>Something went wrong</h6>
        <p>{{ error$ | async }}</p>
      </alert-component>
    </div>
    <div *ngIf="(loading$ | async) === false; else loadingElement">
      <mytime-fetch-locations-form
        *ngIf="isStep1$ | async"
        (save)="fetchLocationsRequest($event)">
      </mytime-fetch-locations-form>

      <mytime-choose-location
        *ngIf="isStep2$ | async"
        [availableSalons]="availableSalons$ | async"
        (save)="chooseLocation($event)">
      </mytime-choose-location>
    </div>
    <ng-template #loadingElement>
      <large-loading-indicator></large-loading-indicator>
    </ng-template>
  </div>
</slideout-panel>
