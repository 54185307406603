import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { SalonbizIntegrationProvider } from "../../../kernel/models";

@Component({
  selector: "add-provider-salonbiz",
  templateUrl: "add-provider-salonbiz.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderSalonbizComponent implements OnInit {
  @Output() save: EventEmitter<SalonbizIntegrationProvider>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      businessId: [undefined, Validators.required],
      storeId: [undefined, Validators.required],
      formulaTypeName: [undefined, Validators.required],
    });
  }

  public saveForm(data: SalonbizIntegrationProvider): void {
    this.save.emit(data);
  }
}
