<dialog-contents
  dialogTitle="Edit Product Pricing"
  (close)="close()">
  <edit-salon-product-component
    [product]="data.product"
    [showFlags]="false"
    [measurementUnit]="data.measurementUnit"
    [currency]="data.currency"
    (save)="save($event)">
  </edit-salon-product-component>
</dialog-contents>
