import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mapTo, switchMap, withLatestFrom } from "rxjs/operators";
import { go } from "app/kernel/store/actions/router.actions";
import { Action, Store, select } from "@ngrx/store";
import { either } from "fp-ts";
import { HttpError } from "@getvish/stockpile";
import { Close } from "../integrations.actions";
import { AppState } from "app/kernel";
import { SalonSoftwareIntegrationService } from "app/+integrations/services";
import { getTenantSubdomain, getToken } from "../selectors/aura.selector";
import { of } from "rxjs";

import * as actions from "../actions/aura.actions";

@Injectable()
export class AuraEffects {
  public navigateAddAuraProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateAddAuraProvider),
      mapTo(go({ path: ["/integrations", { outlets: { panel: "add-provider/aura" } }] }))
    )
  );

  public fetchLocationsRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.fetchLocationsRequest),
      map((action) => action.locationsRequest),
      switchMap((locationsRequest) =>
        this._salonSoftwareIntegrationService.findAuraAvailableSalons(locationsRequest).pipe(
          map((locations) => actions.fetchLocationsRequestSuccess({ availableSalons: locations })),
          catchError((error) => of(actions.fetchLocationsRequestFail({ error })))
        )
      )
    )
  );

  public addProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addProvider),
      map((action) => action.location),
      withLatestFrom(this._store.pipe(select(getToken)), this._store.pipe(select(getTenantSubdomain))),
      switchMap(([location, token, tenantSubdomain]) => {
        return this._salonSoftwareIntegrationService.addAuraProvider({ token, tenantSubdomain, location, isEnabled: false }).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => actions.addProviderFail({ error }),
              () => actions.addProviderSuccess()
            )
          )
        );
      })
    )
  );

  public close$ = createEffect(() => this._actions$.pipe(ofType(actions.close, actions.addProviderSuccess), mapTo(new Close())));

  constructor(
    private _store: Store<AppState>,
    private _actions$: Actions,
    private _salonSoftwareIntegrationService: SalonSoftwareIntegrationService
  ) {}
}
