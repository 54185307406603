import { Injectable } from "@angular/core";
import { HttpError } from "@getvish/stockpile";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { isAdmin, isManager } from "app/+auth/services";
import { getUser } from "app/+auth/store";
import { WindowService } from "app/kernel/services";
import { either } from "fp-ts";
import { isNil, not } from "ramda";
import { first, map, mapTo, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { AppState } from "../../kernel/store";
import { SalonSoftwareIntegrationService } from "../services";

import * as fromAuth from "app/+auth/store";
import * as SalonConfigActions from "app/+salon-config/store/salon-config.actions";
import * as fromCurrentTenant from "../../kernel/store/reducers/current-tenant.reducer";

import * as routerActions from "../../kernel/store/actions/router.actions";
import * as snackbarActions from "../../kernel/store/actions/snackbar.actions";
import * as auraActions from "./actions/aura.actions";
import * as boulevardActions from "./actions/boulevard.actions";
import * as envisionActions from "./actions/envision.actions";
import * as kitomba from "./actions/kitomba.actions";
import * as meevo2Actions from "./actions/meevo2.actions";
import * as rosyActions from "./actions/rosy.actions";
import * as saloniqActions from "./actions/saloniq.actions";
import * as actions from "./integrations.actions";

@Injectable()
export class IntegrationsEffects {
  // ok so we know I don't love doing things like this
  // but we're in a bit of an awkward situation where we need to wait until we have a current user
  // which means we have to wait for the user to log in/the automatic login process to complete
  // but because we're lazy loading modules and it's not guaranteed in what order modules will load/what effects will run exactly when
  // if we try to just select the current user from the store too early it'll be null/undefined
  // so, basically, what I'm doing is when we get the "INIT..." action we'll effectively subscribe to the #getUser
  // observable and take the first value that's not undefined/null - which means we'll have a current user which we can then
  // pass to the service that decides which integrations should be available
  // it's quite possible there's a better way to do this, but this should suffice for the moment/shouldn't be too awkward to think about
  public initAndLoadData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.INIT_AND_LOAD_DATA),
      switchMap(() =>
        this._store.select(getUser).pipe(
          first((user) => not(isNil(user))),
          // this is a bit of a hack, just to get this in really quickly for Square's benefit
          // really we should implement a more robust route guard(s)
          // but we already have something like this on the list of things to implement (properly)
          // later, so I won't implement some 30% solution here and just wait until we have the real solution later
          map((user) => (isManager(user) || isAdmin(user) ? new actions.LoadData() : routerActions.go({ path: ["/not-found"] })))
        )
      )
    )
  );

  public appInit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SalonConfigActions.Types.LOAD_CURRENT_SALON_SUCCESS),
      map(() => new actions.LoadData())
    )
  );

  public loadData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.LOAD_DATA),
      withLatestFrom(this._store.select(fromAuth.getUser)),
      switchMap(([_, user]) =>
        this._salonSoftwareIntegrationService
          .loadProvidersAndActiveIntegrations(user)
          .pipe(map((data) => new actions.LoadDataSuccess({ data })))
      )
    )
  );

  public navigateAddPhorestIntegration$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.NavigateAddPhorestProvider>(actions.Types.NAVIGATE_ADD_PHOREST_PROVIDER),
      map(() => routerActions.go({ path: ["/integrations", { outlets: { panel: "add-provider/phorest" } }] }))
    )
  );

  public addProviderPhorest$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddPhorestProvider>(actions.Types.ADD_PHOREST_PROVIDER),
      map((action) => action.payload),
      switchMap((payload) =>
        this._salonSoftwareIntegrationService.addPhorestProvider(payload.value).pipe(map(() => new actions.AddPhorestProviderSuccess()))
      )
    )
  );

  public addProviderBooker$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddBookerProvider>(actions.Types.ADD_BOOKER_PROVIDER),
      withLatestFrom(this._store.select(fromCurrentTenant.getCurrentTenantId)),
      mergeMap(([_, salonId]) => this._salonSoftwareIntegrationService.getBookerExternalUrl(salonId)),
      mergeMap((url) =>
        this._windowService
          .openWindowObserved(url, { width: 600, height: 750, status: 0, toolbar: 0 })
          .pipe(mapTo(new actions.AddBookerProviderComplete()))
      )
    )
  );

  public addProviderSquare$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddSquareProvider>(actions.Types.ADD_SQUARE_PROVIDER),
      withLatestFrom(this._store.select(fromCurrentTenant.getCurrentTenantId)),
      mergeMap(([_, salonId]) => this._salonSoftwareIntegrationService.getSquareExternalUrl(salonId)),
      mergeMap((url) =>
        this._windowService
          .openWindowObserved(url, { width: 600, height: 750, status: 0, toolbar: 0 })
          .pipe(mapTo(new actions.AddSquareProviderComplete()))
      )
    )
  );

  public addProviderSquareComplete$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddSquareProviderComplete>(actions.Types.ADD_SQUARE_PROVIDER_COMPLETE),
      map(() => new actions.LoadData())
    )
  );

  public addProviderSalonInteractive$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddSalonInteractiveProvider>(actions.Types.ADD_SALON_INTERACTIVE_PROVIDER),
      withLatestFrom(this._store.select(fromCurrentTenant.getCurrentTenantId)),
      mergeMap(([_, salonId]) => this._salonSoftwareIntegrationService.getSalonInteractiveExternalUrl(salonId)),
      mergeMap((url) => {
        console.log(`SalonInteractive URL: ${url}`);
        return this._windowService
          .openWindowObserved(url, { width: 600, height: 750, status: 0, toolbar: 0 })
          .pipe(mapTo(new actions.AddSalonInteractiveProviderComplete()));
      })
    )
  );

  public addProviderSalonInteractiveComplete$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddSalonInteractiveProviderComplete>(actions.Types.ADD_SALON_INTERACTIVE_PROVIDER_COMPLETE),
      map(() => new actions.LoadData())
    )
  );

  public navigateAddShortcutsIntegration$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.NavigateAddShortcutsProvider>(actions.Types.NAVIGATE_ADD_SHORTCUTS_PROVIDER),
      map(() => routerActions.go({ path: ["/integrations", { outlets: { panel: "add-provider/shortcuts" } }] }))
    )
  );

  public addProviderShortcuts$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddShortcutsProvider>(actions.Types.ADD_SHORTCUTS_PROVIDER),
      map((action) => action.payload),
      switchMap((payload) =>
        this._salonSoftwareIntegrationService.addShortcutsProvider(payload.value).pipe(map(() => new actions.AddShortcutsProviderSuccess()))
      )
    )
  );

  public navigateAddProviderSalonbiz$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.NavigateAddSalonbizProvider>(actions.Types.NAVIGATE_ADD_SALONBIZ_PROVIDER),
      map(() => routerActions.go({ path: ["/integrations", { outlets: { panel: "add-provider/salonbiz" } }] }))
    )
  );

  public addProviderSalonbiz$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddSalonbizProvider>(actions.Types.ADD_SALONBIZ_PROVIDER),
      map((action) => action.payload),
      switchMap((payload) =>
        this._salonSoftwareIntegrationService.addSalonbizProvider(payload.value).pipe(map(() => new actions.AddSalonbizProviderSuccess()))
      )
    )
  );

  public addProviderSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        actions.Types.ADD_BOOKER_PROVIDER_COMPLETE,
        actions.Types.ADD_PHOREST_PROVIDER_SUCCESS,
        actions.Types.ADD_SHORTCUTS_PROVIDER_SUCCESS,
        actions.Types.ADD_SALONBIZ_PROVIDER_SUCCESS,
        boulevardActions.addProviderSuccess,
        envisionActions.addProviderSuccess,
        rosyActions.addProviderSuccess,
        saloniqActions.saloniqAddProviderSuccess,
        auraActions.addProviderSuccess,
        meevo2Actions.addProviderSuccess,
        kitomba.addProviderSuccess
      ),
      mergeMap(() => [new actions.LoadData(), routerActions.go({ path: ["/integrations"] })])
    )
  );

  public removeIntegration$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.RemoveIntegration>(actions.Types.REMOVE_INTEGRATION),
      switchMap(({ payload }) =>
        this._salonSoftwareIntegrationService.removeIntegration(payload.provider).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => new actions.RemoveIntegrationFail({ error: new Error(error.payload.toString()) }),
              () => new actions.RemoveIntegrationSuccess()
            )
          )
        )
      )
    )
  );

  public removeIntegrationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.REMOVE_INTEGRATION_SUCCESS),
      mergeMap(() => [
        new actions.LoadData(),
        routerActions.go({ path: ["/integrations"] }),
        new snackbarActions.Info({ message: "Integration removed successfully" }),
      ])
    )
  );

  public removeIntegrationFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.RemoveIntegrationFail>(actions.Types.REMOVE_INTEGRATION_FAIL),
      map(({ payload }) => new snackbarActions.Info({ message: `Removing integration failed. ${payload.error.message}` }))
    )
  );

  public enableIntegration$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.enableIntegration),
      switchMap(({ payload }) =>
        this._salonSoftwareIntegrationService.enableIntegration(payload.provider).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => actions.enableIntegrationFail(new Error(error.payload.toString())),
              () => actions.enableIntegrationSuccess()
            )
          )
        )
      )
    )
  );

  public enableIntegrationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.enableIntegrationSuccess),
      mergeMap(() => [
        new actions.LoadData(),
        routerActions.go({ path: ["/integrations"] }),
        new snackbarActions.Info({ message: "Integration enabled successfully" }),
      ])
    )
  );

  public enableIntegrationFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.enableIntegrationFail),
      map(({ payload }) => new snackbarActions.Info({ message: `Enabling integration failed. ${payload.error.message}` }))
    )
  );

  public disableIntegration$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.disableIntegration),
      switchMap(({ payload }) =>
        this._salonSoftwareIntegrationService.disableIntegration(payload.provider).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => actions.disableIntegrationFail(new Error(error.payload.toString())),
              () => actions.disableIntegrationSuccess()
            )
          )
        )
      )
    )
  );

  public disableIntegrationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.disableIntegrationSuccess),
      mergeMap(() => [
        new actions.LoadData(),
        routerActions.go({ path: ["/integrations"] }),
        new snackbarActions.Info({ message: "Integration disabled successfully" }),
      ])
    )
  );

  public disableIntegrationFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.disableIntegrationFail),
      map(({ payload }) => new snackbarActions.Info({ message: `Disabling integration failed. ${payload.error.message}` }))
    )
  );

  public navigateMapProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateMapProvider),
      map(() => routerActions.go({ path: ["/integrations/map-provider-data"] }))
    )
  );

  public editIntegration$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.EditIntegration>(actions.Types.EDIT_INTEGRATION),
      map((action) => action.payload),
      map(({ integration }) => actions.editProvider(integration))
    )
  );

  public close$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.CLOSE),
      map(() => routerActions.go({ path: ["/integrations", { outlets: { panel: null } }] }))
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _windowService: WindowService,
    private _salonSoftwareIntegrationService: SalonSoftwareIntegrationService
  ) {}
}
