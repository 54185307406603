import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MeasurementUnit } from "@getvish/model";
import { CategoryPricing } from "app/+product/+salon-products/common";

@Component({
  templateUrl: "./edit-product-category-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProductCategoryDialogComponent {
  constructor(
    private _matDialogRef: MatDialogRef<EditProductCategoryDialogComponent, CategoryPricing>,
    @Inject(MAT_DIALOG_DATA)
    public data: { categoryId: string; measurementUnit: MeasurementUnit; currency: string }
  ) {}

  public close(): void {
    this._matDialogRef.close();
  }

  public save(categoryPricing: CategoryPricing): void {
    this._matDialogRef.close(categoryPricing);
  }
}
