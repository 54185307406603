import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faArrowLeft,
  faAt,
  faBalanceScale,
  faCaretDown,
  faCaretRight,
  faChartBar,
  faCheckCircle,
  faChevronRight,
  faCircleNotch,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCodeMerge,
  faEllipsisV,
  faEyeSlash,
  faGripLines,
  faLock,
  faMinusCircle,
  faMortarPestle,
  faPencil,
  faSortDown,
  faTimesCircle,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { MeasurementUnitConversionService } from "../../+salon-config/services";
import { RegionService, SnackbarService } from "../services";
import { ExternalLinkDirective, NgLetDirective } from "./directives";
import { pipes } from "./pipes";
import { RocketUiModule } from "./rocket-ui";

@NgModule({
  declarations: [ExternalLinkDirective, NgLetDirective, ...pipes],
  imports: [
    CommonModule,
    RocketUiModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSliderModule,
    MatTabsModule,
    MatIconModule,
    MatDividerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatRadioModule,
    MatBadgeModule,
    MatCardModule,
    MatTableModule,
    FontAwesomeModule,
  ],
  exports: [
    CommonModule,
    RocketUiModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSliderModule,
    MatTabsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatExpansionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatRadioModule,
    MatBadgeModule,
    MatCardModule,
    MatTableModule,
    ExternalLinkDirective,
    NgLetDirective,
    FontAwesomeModule,
    ...pipes,
  ],
  providers: [MeasurementUnitConversionService, RegionService, SnackbarService],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faChevronRight,
      faCloudDownloadAlt,
      faEllipsisV,
      faChartBar,
      faBalanceScale,
      faTrash,
      faAt,
      faLock,
      faCircleNotch,
      faCloudUploadAlt,
      faCodeMerge,
      faCheckCircle,
      faMinusCircle,
      faTimesCircle,
      faSortDown,
      faArrowLeft,
      faCaretRight,
      faEyeSlash,
      faCaretDown,
      faPencil,
      faGripLines,
      faMortarPestle
    );
  }
}
