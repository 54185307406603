import { Action, createReducer, on } from "@ngrx/store";
import { LocationView } from "app/+integrations/models/meevo2-integration-provider";
import * as Meevo2Actions from "../actions/meevo2.actions";

export interface Meevo2State {
  loading: boolean;
  availableSalons: LocationView[];
  error: string;
  tenantId: number;
  visitNoteTypeName: string;
}

export const initialState: Meevo2State = {
  loading: false,
  availableSalons: undefined,
  error: undefined,
  tenantId: undefined,
  visitNoteTypeName: undefined,
};

const meevo2Reducer = createReducer(
  initialState,
  on(Meevo2Actions.fetchLocationsRequest, (state, { request }) => ({
    ...state,
    loading: true,
    tenantId: request.tenantId,
    visitNoteTypeName: request.visitNoteTypeName,
  })),
  on(Meevo2Actions.fetchLocationsRequestSuccess, (state, { availableSalons }) => ({
    ...state,
    loading: false,
    error: undefined,
    availableSalons,
  })),
  on(Meevo2Actions.fetchLocationsRequestFail, (state, { error }) => ({
    ...state,
    loading: false,
    error: error.message,
  })),
  on(Meevo2Actions.addProviderSuccess, (state) => ({ ...state, loading: false, error: undefined })),
  on(Meevo2Actions.addProviderFail, (state, { error }) => ({ ...state, loading: false, error: JSON.stringify(error.payload) })),
  on(Meevo2Actions.close, () => initialState)
);

export function reducer(state: Meevo2State | undefined, action: Action) {
  return meevo2Reducer(state, action);
}

export const meevo2FeatureKey = "meevo2";
