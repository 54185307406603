import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { Region } from "../models";
import { HttpClient } from "@angular/common/http";
import { FeatureCollection } from "geojson";

@Injectable()
export class RegionService {
  private _regions: Observable<Region[]>;

  constructor(private httpClient: HttpClient) {
    this._regions = this.httpClient.get("/assets/geojson/countries.geo.json").pipe(
      map((response) => {
        const featureCollection = response as FeatureCollection;

        return featureCollection.features.map((feature) => ({
          id: feature.id,
          name: feature.properties.name,
          geometry: feature.geometry,
        }) as Region);
      })
    );
  }

  public getAllAvailableRegions(): Observable<Region[]> {
    return this._regions;
  }
}
