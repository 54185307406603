import { Action, createReducer, on } from "@ngrx/store";
import { LocationView } from "app/+integrations/models/boulevard-integration-provider";
import * as BoulevardActions from "../actions/boulevard.actions";

export interface BoulevardState {
  loading: boolean;
  availableSalons: LocationView[];
  error: boolean;
  businessId: string;
}

export const initialState: BoulevardState = {
  loading: false,
  availableSalons: undefined,
  error: undefined,
  businessId: undefined,
};

const boulevardReducer = createReducer(
  initialState,
  on(BoulevardActions.fetchLocationsRequest, (state, { locationsRequest }) => ({
    ...state,
    loading: true,
    businessId: locationsRequest.businessId,
  })),
  on(BoulevardActions.fetchLocationsRequestSuccess, (state, { availableSalons }) => ({
    ...state,
    loading: false,
    availableSalons,
  })),
  on(BoulevardActions.fetchLocationsRequestFail, (state) => ({
    ...state,
    loading: false,
    error: true,
  })),
  on(BoulevardActions.addProviderSuccess, (state) => ({ ...state, loading: false })),
  on(BoulevardActions.addProviderFail, (state) => ({ ...state, loading: false, error: true })),
  on(BoulevardActions.close, () => initialState)
);

export function reducer(state: BoulevardState | undefined, action: Action) {
  return boulevardReducer(state, action);
}

export const boulevardFeatureKey = "boulevard";
