<div
  class="hero-box"
  [ngClass]="{ active }"
  (click)="clicked.emit()">
  <div
    class="title"
    [ngStyle]="{ color: title.color | default: 'white', backgroundColor: title.backgroundColor | default: '#2196F3' }">
    {{ title.text }}
  </div>
  <div
    class="body"
    [ngStyle]="{
      color: content.color | default: title.backgroundColor | default: '#2196F3',
      borderColor: title.backgroundColor | default: '#2196F3'
    }">
    {{ content.text }}
  </div>
</div>
