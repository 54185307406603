import { Injectable } from "@angular/core";
import { HttpError } from "@getvish/stockpile";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { AddIntegrationRequest, LocationView } from "app/+integrations/models/envision-integration-provider";
import { SalonSoftwareIntegrationService } from "app/+integrations/services";
import { go } from "app/kernel/store/actions/router.actions";
import { map, mapTo, switchMap, withLatestFrom } from "rxjs/operators";
import { either } from "fp-ts";

import * as actions from "../actions/envision.actions";
import { AppState } from "app/kernel";
import { getApiKey, getPassword, getUsername } from "../selectors/envision.selector";
import { Close } from "../integrations.actions";

@Injectable()
export class EnvisionEffects {
  navigateAddEnvisionProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateAddEnvisionProvider),
      mapTo(go({ path: ["/integrations", { outlets: { panel: "add-provider/envision" } }] }))
    )
  );

  fetchLocationsRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.fetchLocationsRequest),
      map((action) => action.locationsRequest),
      switchMap((locationsRequest) =>
        this._salonSoftwareIntegrationService.findEnvisionAvailableSalons(locationsRequest).pipe(
          map(
            either.fold<HttpError, LocationView[], Action>(
              (error) => actions.fetchLocationsRequestFail({ error: new Error(error.payload.toString()) }),
              (availableSalons) => actions.fetchLocationsRequestSuccess({ availableSalons })
            )
          )
        )
      )
    )
  );

  addProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addProvider),
      map((action) => action.location),
      withLatestFrom(this._store.pipe(select(getApiKey)), this._store.pipe(select(getUsername)), this._store.pipe(select(getPassword))),
      switchMap(([location, apiKey, username, password]) => {
        const companyId = location.CompanyId;
        const integrationRequest: AddIntegrationRequest = { apiKey, username, password, companyId, isEnabled: false };
        return this._salonSoftwareIntegrationService.addEnvisionProvider(integrationRequest).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => actions.addProviderFail({ error }),
              () => actions.addProviderSuccess()
            )
          )
        );
      })
    )
  );

  close$ = createEffect(() => this._actions$.pipe(ofType(actions.close, actions.addProviderSuccess), mapTo(new Close())));

  constructor(
    private _store: Store<AppState>,
    private _actions$: Actions,
    private _salonSoftwareIntegrationService: SalonSoftwareIntegrationService
  ) {}
}
