import { createReducer, on } from "@ngrx/store";
import { IntegrationDetails } from "app/+integrations/services";
import {
  Actions,
  revokeAccess,
  revokeAccessSuccess,
  close,
  revokeAccessFail,
  loadData,
  loadDataSuccess,
  loadDataFail,
} from "../actions/edit-square-integration.actions";

export interface State {
  loading: boolean;
  done: boolean;
  error: boolean;
  errorMessage: string;
  details: IntegrationDetails;
}

export const initialState: State = {
  loading: false,
  done: false,
  error: false,
  errorMessage: undefined,
  details: undefined,
};

export const reducer = createReducer<State, Actions>(
  initialState,
  on(loadData, (state) => ({
    ...state,
    error: false,
    loading: true,
  })),
  on(loadDataSuccess, (state, { details }) => ({
    ...state,
    details,
    loading: false,
  })),
  on(loadDataFail, (state, { error }) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: error.message,
  })),
  on(revokeAccess, (state) => ({
    ...state,
    loading: true,
    done: false,
  })),
  on(revokeAccessSuccess, (state) => ({
    ...state,
    loading: false,
    done: true,
    error: false,
  })),
  on(revokeAccessFail, (state) => ({
    ...state,
    loading: false,
    done: true,
    error: true,
  })),
  on(close, () => initialState)
);

export const featureKey = "editSquareIntegration";
