import { ModuleWithProviders, NgModule } from "@angular/core";
import { MetaReducer, USER_PROVIDED_META_REDUCERS } from "@ngrx/store";
import { AppState } from "app/kernel";
import { LogRocketManagerService, REDACTED_TEXT } from "./services";

import { EffectsModule } from "@ngrx/effects";
import * as LogRocket from "logrocket";
import createNgrxMiddleware from "logrocket-ngrx";
import * as R from "ramda";
import { LogRocketEffects } from "./store/logrocket.effects";

import { Types as resetTypes } from "app/+reset-password/store/reset-password.actions";
import { Types as authTypes } from "app/+auth/store/auth.actions";
import { Types as employeeTypes } from "app/+employees/store/employee.actions";
import { logIn } from "app/+multi-tenant-login/store/multi-tenant-login.actions";

const IGNOREABLE_ACTIONS = {
  [resetTypes.SET_PASSWORD]: {
    redactedKeys: ["payload.password", "payload.confirmPassword"],
  },
  [logIn.type]: {
    redactedKeys: ["password"],
  },
  [authTypes.LOGIN]: {
    redactedKeys: ["password"],
  },
  [employeeTypes.ADD]: {
    redactedKeys: ["payload.employee.password"],
  },
  [employeeTypes.UPDATE]: {
    redactedKeys: ["payload.employee.password"],
  },
};

export function metaReducerFactory(): MetaReducer<AppState> {
  return createNgrxMiddleware(LogRocket, {
    actionSanitizer: (action: any) => {
      if (IGNOREABLE_ACTIONS[action.type] != null) {
        for (const redactedKey of IGNOREABLE_ACTIONS[action.type].redactedKeys as string[]) {
          action = R.set(R.lensPath(redactedKey.split(".")), REDACTED_TEXT, action);
        }
      }
      return action;
    },
  });
}

@NgModule({
  imports: [EffectsModule.forFeature([LogRocketEffects])],
})
export class LogRocketModule {
  public static forRoot(): ModuleWithProviders<LogRocketModule> {
    return {
      ngModule: LogRocketModule,
      providers: [
        LogRocketManagerService,
        {
          provide: USER_PROVIDED_META_REDUCERS,
          useFactory: metaReducerFactory,
        },
      ],
    };
  }
}
