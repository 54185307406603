import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { isAdmin } from "app/+auth/services";
import { getUser } from "app/+auth/store";
import { AppState } from "app/kernel";
import { IntegrationSoftwareProvider, SalonSoftwareIntegration } from "app/kernel/models/salon-software-provider";
import { map, Observable } from "rxjs";

@Component({
  selector: "software-providers",
  templateUrl: "software-providers.component.html",
  styleUrls: ["software-providers.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftwareProvidersComponent {
  @Input() public integrations: SalonSoftwareIntegration[];
  @Output() public addProvider: EventEmitter<IntegrationSoftwareProvider>;
  @Output() public enableProvider: EventEmitter<SalonSoftwareIntegration>;
  @Output() public mapProvider: EventEmitter<SalonSoftwareIntegration>;
  @Output() public disableProvider: EventEmitter<SalonSoftwareIntegration>;
  @Output() public removeProvider: EventEmitter<SalonSoftwareIntegration>;
  @Output() public editProvider: EventEmitter<SalonSoftwareIntegration>;

  constructor(private _store: Store<AppState>) {
    this.addProvider = new EventEmitter(true);
    this.enableProvider = new EventEmitter(true);
    this.mapProvider = new EventEmitter(true);
    this.disableProvider = new EventEmitter(true);
    this.removeProvider = new EventEmitter(true);
    this.editProvider = new EventEmitter(true);
  }

  public isAdmin(): Observable<boolean> {
    return this._store.select(getUser).pipe(map(isAdmin));
  }
}
