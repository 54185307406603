import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { PhorestIntegrationProvider } from "../../../kernel/models";
import { PhorestCredentialValidator } from "../../validators";

@Component({
  selector: "add-provider-phorest",
  templateUrl: "add-provider-phorest.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderPhorestComponent implements OnInit {
  @Output() save: EventEmitter<PhorestIntegrationProvider>;

  public form: UntypedFormGroup;

  public siloOptions = [
    { label: "Default", value: null },
    { label: "North America/Australia", value: "us" },
    { label: "EU, UAE & ROW", value: "eu" },
  ];

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      businessId: [undefined, [Validators.required, PhorestCredentialValidator]],
      branchId: [undefined, [Validators.required, PhorestCredentialValidator]],
      silo: [undefined],
    });
  }

  public saveForm(data: PhorestIntegrationProvider): void {
    this.save.emit({ ...data, isEnabled: false });
  }
}
