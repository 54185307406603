import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FetchLocationsRequest } from "app/+integrations/models/mytime-integration-provider";

@Component({
  selector: "mytime-fetch-locations-form",
  templateUrl: "mytime-fetch-locations-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MytimeFetchLocationsFormComponent implements OnInit {
  @Output() save: EventEmitter<FetchLocationsRequest>;

  public form: FormGroup;

  constructor(private _fb: FormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      apiKey: [undefined, Validators.required],
    });
  }

  public saveForm(data: FetchLocationsRequest): void {
    this.save.emit(data);
  }
}
