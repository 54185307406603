import { SalonSoftwareIntegration } from "app/kernel/models/salon-software-provider";

import * as actions from "./integrations.actions";

export interface IntegrationsState {
  loading: boolean;
  saving: boolean;
  error: boolean;
  selectedIntegration: SalonSoftwareIntegration;
  integrations: SalonSoftwareIntegration[];
}

const initialState: IntegrationsState = {
  loading: false,
  saving: false,
  error: false,
  selectedIntegration: undefined,
  integrations: undefined,
};

export function integrationsReducer(state: IntegrationsState = initialState, action: actions.Actions): IntegrationsState {
  switch (action.type) {
    case actions.Types.LOAD_DATA: {
      const loading = true;

      return {
        ...state,
        loading,
      };
    }
    case actions.Types.LOAD_DATA_SUCCESS: {
      const loading = false;
      const integrations = action.payload.data;

      return {
        ...state,
        loading,
        integrations,
      };
    }

    case actions.Types.CLOSE: {
      const error = false;
      const loading = false;

      return {
        ...state,
        error,
        loading,
      };
    }

    default: {
      return state;
    }
  }
}
