import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "hero-box",
  templateUrl: "hero-box.component.html",
  styleUrls: ["hero-box.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroBoxComponent {
  @Input()
  public title: {
    text: string;
    color?: string;
    backgroundColor?: string;
  };

  @Input()
  public content: {
    text: string | number;
    color?: string;
  };

  @Input()
  public active: boolean = false;

  @Output()
  public clicked: EventEmitter<void>;

  constructor() {
    this.clicked = new EventEmitter();
  }
}
