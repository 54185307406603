<div>
  <form
    [formGroup]="form"
    (submit)="saveForm(form.value)"
    novalidate>
    <div class="form-title">Select a Location</div>
    <mat-radio-group formControlName="salon">
      <mat-radio-button
        *ngFor="let salon of availableSalons"
        [value]="salon">
        <b>{{ salon.storeName }}</b>
      </mat-radio-button>
    </mat-radio-group>
  </form>
  <div
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="saveForm(form.value)"
      type="button"
      [disabled]="form.invalid"
      class="button primary"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Continue
    </button>
  </div>
</div>
