<slideout-panel (close)="close()">
  <header panel-header>Add Kitomba Integration</header>

  <div panel-body>
    <div *ngIf="error$ | async | isDefined">
      <alert-component type="danger">
        <h6>Something went wrong</h6>
        <p>{{ error$ | async }}</p>
      </alert-component>
    </div>
    <div *ngIf="(saving$ | async) === false; else savingElement">
      <add-provider-kitomba (save)="addProvider($event)"> </add-provider-kitomba>
    </div>
    <ng-template #savingElement>
      <large-loading-indicator></large-loading-indicator>
    </ng-template>
  </div>
</slideout-panel>
