<page
  pageTitle="Integrations"
  [progressText]="(loading$ | async) ? 'Loading...' : undefined">
  <software-providers
    *ngIf="(loading$ | async) === false"
    [integrations]="integrations$ | async"
    (addProvider)="addProvider($event)"
    (enableProvider)="enableProvider($event)"
    (mapProvider)="mapProvider($event)"
    (disableProvider)="disableProvider($event)"
    (removeProvider)="removeProvider($event)"
    (editProvider)="editProvider($event)"
    page-body></software-providers>
</page>
