import { createSelector, createFeatureSelector } from "@ngrx/store";
import * as FromMeevo2 from "../reducers/meevo2.reducer";

export const getState = createFeatureSelector<FromMeevo2.Meevo2State>(FromMeevo2.meevo2FeatureKey);

export const getAvailableSalons = createSelector(getState, (state) => state.availableSalons);
export const getTenantId = createSelector(getState, (state) => state.tenantId);
export const getVisitNoteTypeName = createSelector(getState, (state) => state.visitNoteTypeName);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getError = createSelector(getState, (state) => state.error);
