import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Product } from "@getvish/model";
import { AuthStorageService } from "app/+auth/services";
import { ProductPricing } from "app/+product/+master-pricing/models/pricing";
import { WindowService } from "app/kernel";
import { HTTP_URL } from "app/kernel/services/common";
import { splitEvery, uniq } from "ramda";
import { catchError, forkJoin, map, Observable, of, switchMap, tap } from "rxjs";
import { SalonSoftwareIntegrationService } from "./salon-integration.service";

@Injectable()
export class IntegrationPricingService {
  constructor(
    protected _http: HttpClient,
    @Inject(HTTP_URL) private _httpUrl: string,
    private _windowService: WindowService,
    private _authStorage: AuthStorageService,
    private _salonSoftwareIntegrationService: SalonSoftwareIntegrationService
  ) {}

  public getProductPricing(products: Product[]): Observable<{ [productId: string]: Partial<ProductPricing> }> {
    return this._salonSoftwareIntegrationService.findActiveForSalon().pipe(
      switchMap((integrations) => {
        if (integrations?.find((integration) => integration.provider === "SALONINTERACTIVE")) {
          return this._getSalonInteractiveProductPricing(products);
        }

        return of({});
      }),
      catchError(() => of({}))
    );
  }

  private _getSalonInteractiveProductPricing(products: Product[]): Observable<{ [productId: string]: Partial<ProductPricing> }> {
    if (products.length === 0) {
      return of({});
    }

    const productIds = uniq(products.map((product) => product._id));
    const idChunks = splitEvery(40, productIds);

    const requests = idChunks.map((_ids) => this._getSalonInteractiveProductPricingWithIds(_ids));

    return forkJoin(requests).pipe(map((results: Array<any>) => results.reduce((acc, result) => ({ ...acc, ...result }), {})));
  }

  private _getSalonInteractiveProductPricingWithIds(productIds: string[]): Observable<{ [productId: string]: Partial<ProductPricing> }> {
    const headers = new HttpHeaders({
      "X-Salon-Slug": this._windowService.tenantPathName,
      "X-Auth-Token": this._authStorage.getAuthToken(),
    });

    const baseUrl = this._httpUrl.replace("/v2", "");

    return this._http
      .get(`${baseUrl}/salonInteractive/matches`, {
        params: { criteria: JSON.stringify({ vishProductId: { $in: productIds } }) },
        headers,
      })
      .pipe(
        switchMap((result: Array<any>) => {
          if (result.length === 0) {
            return of({});
          }

          return this._http
            .get(`${baseUrl}/salonInteractiveProducts`, {
              params: { criteria: JSON.stringify({ salonInteractiveId: { $in: result.map((r: any) => r.salonInteractiveProductId) } }) },
              headers,
            })
            .pipe(
              map((siProducts: Array<unknown>) => {
                return siProducts.reduce((acc, siProduct: any) => {
                  const distributor = siProduct.distributors?.find((d) => d.name === "SalonCentric");
                  const price = distributor?.salonPrice;

                  if (price != null) {
                    const vishProduct = result.find((r) => r.salonInteractiveProductId === siProduct.salonInteractiveId);

                    acc[vishProduct.vishProductId] = {
                      wholesalePrice: price,
                      containerSize: siProduct.containerSize,
                    } as Partial<ProductPricing>;
                  }

                  return acc;
                }, {}) as { [productId: string]: Partial<ProductPricing> };
              })
            );
        })
      );
  }
}
