import { createAction, props } from "@ngrx/store";
import { SalonIqSalon } from "app/+integrations/models/saloniq-salon";

export const navigateAddSaloniqProvider = createAction("[Integrations] Navigate Add Salon IQ Provider");

export const saloniqLoadAvailableSalons = createAction("[Integrations] SaloniQ: Load Available Salons", props<{ idToken: string }>());

export const saloniqLoadAvailableSalonsSuccess = createAction(
  "[Integrations] SaloniQ: Load Available Salons Success",
  props<{ availableSalons: SalonIqSalon[] }>()
);

export const saloniqLoadAvailableSalonsFail = createAction("[Integrations] SaloniQ: Load Available Salons Fail", props<{ error: Error }>());

export const saloniqAddProvider = createAction("[Integrations] SaloniQ: Add Provider", props<{ location: SalonIqSalon }>());

export const saloniqAddProviderSuccess = createAction("[Integrations] SaloniQ: Add Provider Success");

export const saloniqAddProviderFail = createAction("[Integrations] SaloniQ: Add Provider Fail", props<{ error: any }>());

export const close = createAction("[Integrations] SaloniQ: Close");
