import { Injectable } from "@angular/core";
import { Manufacturer, Product, ProductCategory, SalonProduct } from "@getvish/model";
import { HttpRepositoryFactory, EntityService, HttpRequestHandler } from "@getvish/stockpile";
import { either } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Observable, OperatorFunction, forkJoin, iif, merge, of } from "rxjs";
import { catchError, map, mergeMap, reduce, switchMap } from "rxjs/operators";

import { ProductService } from "app/+product/+products/services";
import { option } from "fp-ts";
import { findFirst, separate } from "fp-ts/lib/Array";
import { toUndefined } from "fp-ts/lib/Option";
import { flatten, isEmpty, splitEvery, uniq } from "ramda";
import { CategoryPricing } from "../common";
import { SalonProductCategoryService } from "./salon-product-category.service";
import { ProductPricing } from "app/+product/+master-pricing/models/pricing";

interface multiImportResponse {
  numInserted: number;
}

interface SalonInteractiveOrderResponse {
  orderId: number;
  orderUrl: string;
}

@Injectable()
export class SalonProductService extends EntityService<SalonProduct> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _requestHandler: HttpRequestHandler,
    private _productService: ProductService,
    private _salonProductCategoryService: SalonProductCategoryService
  ) {
    super(repositoryFactory, { entityKey: "salonProducts" });
  }

  public findAll(): Observable<SalonProduct[]> {
    return this.find().pipe(
      map((x) => x.records),
      this._processResults()
    );
  }

  public findByIds(ids: string[]): Observable<SalonProduct[]> {
    return super.findByIds(ids).pipe(this._processResults());
  }

  public findByProductIds(productIds: string[], hydrate = true): Observable<SalonProduct[]> {
    const uniqueIds = uniq(productIds);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((_ids) => this.find({ productId: { $in: _ids } }).pipe(map((result) => result.records)));

    return isEmpty(requests)
      ? of([])
      : forkJoin(requests).pipe(
          map(flatten),
          mergeMap((v) => iif(() => hydrate, of(v).pipe(this._processResults()), of(v)))
        );
  }

  // TODO: this is a bit hacky. Refactor this
  public _processResults(): OperatorFunction<SalonProduct[], SalonProduct[]> {
    return mergeMap((salonProducts) =>
      this._salonProductCategoryService.findAll().pipe(
        map((salonProductCategories) => salonProductCategories.map((c) => c.productCategoryId)),
        switchMap((categoryIds) => this._productService.findByCategoryIds(categoryIds)),
        map((products) =>
          salonProducts.map((salonProduct) => {
            // handle situation: if product isn't found for a particular salon product
            const product = pipe(
              findFirst((product: Product) => product._id === salonProduct.productId)(products),
              option.map((product) => ({
                name: product.name,
                manufacturerId: product.manufacturerId,
                categoryId: product.categoryId,
                hexColorCode: product.hexColorCode,
                order: product.order,
              })),
              option.toUndefined
            );

            return {
              ...salonProduct,
              ...product,
              order: salonProduct.order ?? product?.order,
            };
          })
        )
      )
    );
  }

  public importManufacturerById(manufacturerId: string): Observable<either.Either<Error, number>> {
    const payload = { manufacturerId };

    return this._requestHandler.post<multiImportResponse>("salonProducts/importManufacturer", payload).pipe(
      map((response) =>
        pipe(
          response,
          either.bimap(
            (fail) => new Error(fail.payload["message"]),
            (success) => success.numInserted
          )
        )
      )
    );
  }

  public placeSalonInteractiveOrder(productIds: string[]): Observable<either.Either<Error, string>> {
    const payload = { orderItems: productIds.map((productId) => ({ vishProductId: productId, quantity: 1 })) };

    return this._requestHandler.post<SalonInteractiveOrderResponse>("salonInteractive/order", payload).pipe(
      map((response) =>
        pipe(
          response,
          either.bimap(
            (fail) => new Error(fail.payload["message"]),
            // TODO: FIX THE BACKEND TO GENERATE THE RIGHT URL (doing api instead atm)
            (success) => `https://app.saloninteractive.net/salon_orders/${success.orderId}`
          )
        )
      )
    );
  }

  public importManufacturer(manufacturer: Manufacturer): Observable<either.Either<Error, number>> {
    const id = manufacturer._id;

    return this.importManufacturerById(id);
  }

  public importProductCategoryById(productCategoryId: string): Observable<either.Either<Error, number>> {
    const payload = { productCategoryId };

    return this._requestHandler.post<multiImportResponse>("salonProducts/importProductCategory", payload).pipe(
      map((response) =>
        pipe(
          response,
          either.bimap(
            (fail) => new Error(fail.payload["message"]),
            (success) => success.numInserted
          )
        )
      )
    );
  }

  public importProductCategory(category: ProductCategory): Observable<either.Either<Error, number>> {
    const id = category._id;

    return this.importProductCategoryById(id);
  }

  // TODO: eventually hook back up paging for salon products
  public findForManufacturer(manufacturerId: string): Observable<SalonProduct[]> {
    return this._requestHandler.get<SalonProduct[]>(`salonProducts/manufacturer/${manufacturerId}`).pipe(
      map(toUndefined),
      mergeMap((salonProducts) => {
        const productIds = salonProducts.map((salonProduct) => salonProduct.productId);

        return this._productService.findByIds(productIds).pipe(
          map((products) => {
            return salonProducts.map((salonProduct) => {
              // handle situation: if product isn't not found for a particular salon product
              const product = pipe(
                findFirst((product: Product) => product._id === salonProduct.productId)(products),
                option.map((product) => ({
                  name: product.name,
                  manufacturerId: product.manufacturerId,
                  categoryId: product.categoryId,
                  hexColorCode: product.hexColorCode,
                  order: product.order,
                })),
                option.toUndefined
              );

              return {
                ...salonProduct,
                ...product,
                order: salonProduct.order ?? product.order,
              };
            });
          })
        );
      })
    );
  }

  public setCategoryPricing(pricing: CategoryPricing): Observable<either.Either<Error, number>> {
    return this._requestHandler.post<number>("salonProducts/setCategoryPricing", pricing).pipe(
      map((response) =>
        pipe(
          response,
          either.mapLeft((fail) => new Error(fail.payload["message"]))
        )
      )
    );
  }

  public setCategoryInactive(categoryId: string): Observable<either.Either<Error, number>> {
    const payload = { categoryId };

    return this._requestHandler.post<number>("salonProducts/setCategoryInactive", payload).pipe(
      map((response) =>
        pipe(
          response,
          either.mapLeft((fail) => new Error(fail.payload["message"]))
        )
      )
    );
  }

  public setCategoryMarkup(categoryId: string, markup: number): Observable<either.Either<Error, number>> {
    return this._requestHandler.post<number>("salonProducts/setCategoryMarkup", { categoryId, markup }).pipe(
      map((response) =>
        pipe(
          response,
          either.mapLeft((fail) => new Error(fail.payload["message"]))
        )
      )
    );
  }

  public setManufacturerMarkup(manufacturerId: string, markup: number): Observable<either.Either<Error, number>> {
    return this._requestHandler.post<number>("salonProducts/setManufacturerMarkup", { manufacturerId, markup }).pipe(
      map((response) =>
        pipe(
          response,
          either.mapLeft((fail) => new Error(fail.payload["message"]))
        )
      )
    );
  }

  public setSalonProductsActive(salonProducts: SalonProduct[]): Observable<either.Either<Error[], number>> {
    const productsToActivate = salonProducts.filter((sp) => sp.flags?.includes("INACTIVE"));

    if (productsToActivate.length === 0) {
      return of(either.right(0));
    }

    return this._batchUpdateSalonProducts(productsToActivate, (sp) => ({
      ...sp,
      flags: sp.flags?.filter((f) => f !== "INACTIVE") ?? [],
    }));
  }

  public setSalonProductsInactive(salonProducts: SalonProduct[]): Observable<either.Either<Error[], number>> {
    const productsToDeactivate = salonProducts.filter((sp) => !sp.flags?.includes("INACTIVE"));

    if (productsToDeactivate.length === 0) {
      return of(either.right(0));
    }

    return this._batchUpdateSalonProducts(productsToDeactivate, (sp) => ({
      ...sp,
      flags: [...(sp.flags ?? []), "INACTIVE"],
    }));
  }

  public setSalonProductPricing(
    salonProducts: SalonProduct[],
    pricing: Partial<CategoryPricing>
  ): Observable<either.Either<Error[], number>> {
    return this._batchUpdateSalonProducts(salonProducts, (sp) => ({
      ...sp,
      wholesalePrice: pricing.wholesalePrice ?? sp.wholesalePrice,
      markup: pricing.markup ?? sp.markup,
      containerSize: pricing.containerSize ?? sp.containerSize,
    }));
  }

  private _batchUpdateSalonProducts(
    salonProducts: SalonProduct[],
    updateFunc: (sp: SalonProduct) => SalonProduct
  ): Observable<either.Either<Error[], number>> {
    return forkJoin(salonProducts.map((sp) => this.update(updateFunc(sp)))).pipe(
      map(separate),
      map(({ left: errors }) => {
        if (errors.length > 0) {
          return either.left(errors);
        }

        return either.right(salonProducts.length);
      })
    );
  }

  public importGroupedProducts(
    productGroups: { manufacturer: Manufacturer; products: Product[] }[],
    pricing: { [productId: string]: ProductPricing }
  ) {
    const productIds = flatten(productGroups.map((group) => group.products)).map((product) => product._id);

    return this.importProductsByIds(productIds).pipe(
      switchMap(
        either.fold<Error, SalonProduct[], Observable<either.Either<Error, SalonProduct[]>>>(
          (error) => of(either.left(error)),
          (salonProducts) => {
            const pricedSalonProducts = salonProducts.reduce((acc, salonProduct) => {
              const productPricing = pricing[salonProduct.productId];

              if (productPricing == null) {
                acc.push(salonProduct);
                return acc;
              }

              acc.push({
                ...salonProduct,
                ...productPricing
              })

              return acc;
            }, []);

            return merge(...pricedSalonProducts.map((sp) => this.updateOrDie(sp)), 5).pipe(
              reduce((acc, result) => [...acc, result], []),
              map((results) => either.right(results)),
              catchError((e) => of(either.left(e)))
            );
          }
        )
      )
    );
  }

  public importProductsByIds(productIds: string[]): Observable<either.Either<Error, SalonProduct[]>> {
    return this._requestHandler.post<SalonProduct[]>("salonProducts/importProducts", { productIds }).pipe(
      map(
        either.fold(
          (httpError) => either.left(new Error(`Importing products failed with status code ${httpError.code}`)),
          (result) => either.right(result)
        )
      )
    );
  }

  public updateMany(salonProducts: SalonProduct[]): Observable<either.Either<Error, SalonProduct[]>> {
    return forkJoin(salonProducts.map((salonProduct) => this.updateOrDie(salonProduct))).pipe(
      map(flatten),
      map(either.right),
      catchError((e) => of(either.left(e)))
    );
  }
}
