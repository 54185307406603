import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from "@angular/core";
import { HeroBoxComponent } from "./hero-box.component";

@Component({
  selector: "hero-boxes",
  templateUrl: "hero-boxes.component.html",
  styleUrls: ["hero-boxes.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroBoxesComponent {
  @ContentChildren(HeroBoxComponent)
  public items: QueryList<HeroBoxComponent>;
}
