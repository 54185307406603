<div *ngIf="error$ | async">
  <alert-component type="danger">
    <p>It looks like something bad happened. Try the request again.</p>
    <p>{{ errorMessage$ | async }}</p>
  </alert-component>
</div>
<div *ngIf="(loading$ | async) === false; else loadingElement">
  <div
    *ngIf="details$ | async as details"
    style="margin: 20px">
    <table class="info">
      <tbody>
        <tr>
          <td class="registration-token-code-cell">
            <span class="row-subtitle">Location ID:</span>
          </td>
          <td>
            <span
              class="row-title"
              class="registration-token-code">
              {{ details.location?.id }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="registration-token-code-cell">
            <span class="row-subtitle">Location Name:</span>
          </td>
          <td>
            <span
              class="row-title"
              class="registration-token-code">
              {{ details.location?.name }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="registration-token-code-cell">
            <span class="row-subtitle">Integration Enabled:</span>
          </td>
          <td>
            <span
              class="row-title"
              class="registration-token-code">
              {{ details.isEnabled ? "Yes" : "No" }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="registration-token-code-cell">
            <span class="row-subtitle">Authentication Valid:</span>
          </td>
          <td>
            <span
              class="row-title"
              class="registration-token-code">
              {{ details.isAuthTokenValid ? "Yes" : "No" }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="registration-token-code-cell">
            <span class="row-subtitle">Supports Seller Writes:</span>
          </td>
          <td>
            <span
              class="row-title"
              class="registration-token-code">
              {{ details.supportsSellerLevelWrites === true ? "Yes" : ("No" | default: "Unknown") }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <alert-component
      type="info"
      *ngIf="details.supportsSellerLevelWrites !== true">
      <p>
        To take full advantage of this integration and enable Vish to add <b>formula notes in Square</b>, upgrade your Square Appointments
        account to the Plus or Premium plan. Learn more
        <a
          href="https://squareup.com/t/cmtp_performance/pr_developers/d_partnerships/p_vish/?route=/us/en/appointments/pricing"
          external
          >here</a
        >.
      </p>
    </alert-component>
  </div>
  <div
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="revokeAccess()"
      type="button"
      class="button outline"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Revoke Access
    </button>
  </div>
</div>
<ng-template #loadingElement>
  <large-loading-indicator></large-loading-indicator>
</ng-template>
