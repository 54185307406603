import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { getDone, getLoading, getError } from "app/+integrations/store/selectors/edit-square-integration.selectors";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { close, revokeAccess } from "../../store/actions/edit-square-integration.actions";

@Component({
  selector: "revoke-access",
  templateUrl: "revoke-access.component.html",
  styleUrls: ["revoke-access.component.less"],
})
export class RevokeAccessComponent {
  public error$: Observable<boolean>;
  public done$: Observable<boolean>;
  public loading$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.done$ = this._store.select(getDone);
    this.loading$ = this._store.select(getLoading);
    this.error$ = this._store.select(getError);
  }

  public revokeAccess(): void {
    this._store.dispatch(revokeAccess());
  }

  public done(): void {
    this._store.dispatch(close());
  }
}
