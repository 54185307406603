import { Action, createReducer, on } from "@ngrx/store";
import * as KitombaActions from "../actions/kitomba.actions";

export interface KitombaState {
  saving: boolean;
  error: string;
}

export const initialState: KitombaState = {
  saving: false,
  error: undefined,
};

const kitombaReducer = createReducer(
  initialState,
  on(KitombaActions.addProviderSuccess, (state) => ({ ...state, saving: false, error: undefined })),
  on(KitombaActions.addProviderFail, (state, { error }) => ({ ...state, saving: false, error: JSON.stringify(error.payload) })),
  on(KitombaActions.close, () => initialState)
);

export function reducer(state: KitombaState | undefined, action: Action) {
  return kitombaReducer(state, action);
}

export const kitombaFeatureKey = "kitomba";
