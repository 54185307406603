<div>
  <form
    [formGroup]="form"
    (submit)="saveForm(form.value)"
    novalidate>
    <div class="form-title">Select a Location</div>
    <mat-radio-group formControlName="salon">
      <mat-radio-button
        *ngFor="let salon of availableSalons"
        [value]="salon">
        <div class="address">
          <div
            class="nickname"
            *ngIf="salon.nickname">
            {{ salon.nickname }}
          </div>
          <div>{{ salon.street_address }}</div>
          <div *ngIf="salon.street_address_2">{{ salon.street_address_2 }}</div>
          <div>{{ salon.city }}, {{ salon.state }}, {{ salon.zip_code }}</div>
          <div>{{ salon.country }}</div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </form>
  <div
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="saveForm(form.value)"
      type="button"
      [disabled]="form.invalid"
      class="button primary"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Continue
    </button>
  </div>
</div>
