import { AbstractControl, ValidationErrors } from "@angular/forms";
import { isNil } from "ramda";

export function PhorestCredentialValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  if (isNil(value)) {
    return null; // since we aren't validating that this field is required we don't care if it's null/undefined

    // test if the value has a space anywhere - these credentials aren't have spaces/whitespace anywhere, either leading/trailing/middle/wherever
  } else if (/\s/g.test(value) === true) {
    return { phorestCredential: true };
  }

  return null;
}
