import { ServiceDescription, ServiceDescriptionModule } from "@getvish/model";
import { EntityService, HttpError, HttpRepositoryFactory, HttpRequestHandler } from "@getvish/stockpile";
import { Injectable } from "@angular/core";
import { Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { Either, isRight } from "fp-ts/Either";
import { flatten, isEmpty, splitEvery, uniq } from "ramda";

@Injectable()
export class ServiceDescriptionService extends EntityService<ServiceDescription> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _requestHandler: HttpRequestHandler
  ) {
    super(repositoryFactory, { entityKey: "serviceDescriptions" });
  }

  public updateMany(records: ServiceDescription[], partial: Partial<ServiceDescription>): Observable<ServiceDescription[]> {
    const patchedRecords = records.map((record) => ({ ...record, ...partial }));

    const requests$ = patchedRecords.map((record) => this.update(record));

    return forkJoin(requests$).pipe(map((response) => response.filter(isRight).map((record) => record.right)));
  }

  public updateManyToggleIsActive(records: ServiceDescription[]): Observable<ServiceDescription[]> {
    const requests$ = records.map((serviceDescription) => {
      const method = ServiceDescriptionModule.isActive(serviceDescription) ? "deactivate" : "activate";

      return this._requestHandler.post<ServiceDescription>(`serviceDescriptions/${serviceDescription._id}/${method}`, {});
    });

    return forkJoin(requests$).pipe(map((response) => response.filter(isRight).map((record) => record.right)));
  }

  public updateManyToggleMode(records: ServiceDescription[]): Observable<Either<HttpError, ServiceDescription[]>> {
    return this._requestHandler.post<ServiceDescription[]>(`serviceDescriptions/togglePricingMode/many`, {
      serviceDescriptionIds: records.map((record) => record._id),
    });
  }

  public getUncategorizedServiceCount(): Observable<number> {
    return this.find({ flags: { $ne: "INACTIVE" }, categoryId: null }).pipe(map((response) => response.paging.count));
  }

  public findByIds(ids: string[]): Observable<ServiceDescription[]> {
    const uniqueIds = uniq(ids);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((_ids) => super.findByIds(_ids));

    return isEmpty(requests) ? of([]) : forkJoin(requests).pipe(map(flatten));
  }
}
