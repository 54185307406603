<div
  class="data-table"
  [ngClass]="paging ? 'with-paging' : null">
  <div class="data-table-content">
    <table
      [ngClass]="hasHeader ? 'with-header' : null"
      #table>
      <thead *ngIf="hasHeader">
        <tr>
          <th
            [id]="_column.id"
            *ngFor="let _column of displayedColumns; trackBy: columnTrackBy"
            [ngStyle]="getStickyStyle(_column)">
            <div
              class="data-table-header"
              [ngClass]="getColumnHeaderClass(_column)"
              (click)="_column.sort?.updateSort(_column.sort?.direction === 1 ? -1 : 1)">
              <button
                *ngIf="selections?.[_column.id]?.count > 0 && selections?.[_column.id]?.total == null"
                class="button transparent clearSelections"
                (click)="selections[_column.id].clearSelections()"
                [title]="'Clear ' + selections[_column.id].count + ' selection' + (selections[_column.id].count > 1 ? 's' : '')">
                <fa-icon icon="xmark"></fa-icon>
              </button>
              <mat-checkbox
                *ngIf="selections?.[_column.id]?.total > 0"
                class="toggleSelections"
                [indeterminate]="selections[_column.id].count > 0 && selections[_column.id].count !== selections[_column.id].total"
                [checked]="selections[_column.id].count > 0 && selections[_column.id].count === selections[_column.id].total"
                [title]="selections[_column.id].count + ' selection' + (selections[_column.id].count > 1 ? 's' : '')"
                (click)="
                  selections[_column.id].toggleAllSelections(
                    selections[_column.id].count === 0 || selections[_column.id].count !== selections[_column.id].total
                  )
                "></mat-checkbox>
              <ng-container *ngIf="_column.title | isDefined">
                {{ _column.title }}
              </ng-container>
              <button
                class="button transparent"
                (click)="filtersOpened(_column.id); $event.stopPropagation()"
                *ngIf="_column.filter?.value || _filtersOpenedColId === _column.id">
                <fa-icon
                  class="filtered-icon"
                  icon="filter"></fa-icon>
              </button>
              <fa-icon
                [ngClass]="(_column.sort?.direction | isDefined) ? 'sorted-icon' : 'sortable-icon'"
                [icon]="!(_column.sort?.direction | isDefined) ? 'sort' : _column.sort.direction === 1 ? 'sort-up' : 'sort-down'"
                *ngIf="_column.sort | isDefined"></fa-icon>
              <div
                class="icons"
                *ngIf="_column.icons?.values.length">
                <button
                  class="button iconButton transparent"
                  (click)="iconClicked(i); $event.stopPropagation()"
                  *ngFor="let i of _column.icons.values ?? []">
                  <fa-icon [icon]="i.icon"></fa-icon>
                </button>
              </div>
              <div
                *ngIf="(_column.sort | isDefined) || _column.filter || (managedColumns.length > 0 && _column.title != null)"
                class="menu-icon">
                <mat-menu
                  #controlsMenu="matMenu"
                  (closed)="menuOpened()">
                  <button
                    mat-menu-item
                    *ngIf="_column.sort | isDefined"
                    (click)="_column.sort?.updateSort(1)">
                    <div class="menu-item">
                      <fa-icon icon="arrow-up"></fa-icon>
                      <span>Sort Ascending</span>
                    </div>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="_column.sort | isDefined"
                    (click)="_column.sort?.updateSort(-1)">
                    <div class="menu-item">
                      <fa-icon icon="arrow-down"></fa-icon>
                      <span>Sort Descending</span>
                    </div>
                  </button>
                  <mat-divider *ngIf="(_column.sort | isDefined) && _column.filter"></mat-divider>
                  <button
                    mat-menu-item
                    *ngIf="_column.filter"
                    (click)="filtersOpened(_column.id)">
                    <div class="menu-item">
                      <fa-icon icon="filter"></fa-icon>
                      <span>Filter</span>
                    </div>
                  </button>
                  <mat-divider *ngIf="((_column.sort | isDefined) || _column.filter) && managedColumns.length > 0"></mat-divider>
                  <button
                    mat-menu-item
                    *ngIf="_column.showInColumnManager"
                    (click)="setColumnVisibilty(_column.id, false)">
                    <div class="menu-item">
                      <fa-icon icon="eye-slash"></fa-icon>
                      <span>Hide Column</span>
                    </div>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="managedColumns.length > 0"
                    (click)="manageColumnsOpened()">
                    <div class="menu-item">
                      <fa-icon icon="table-columns"></fa-icon>
                      <span>Manage Columns</span>
                    </div>
                  </button>
                </mat-menu>
                <button
                  [matMenuTriggerFor]="controlsMenu"
                  class="button transparent"
                  (click)="$event.stopPropagation()"
                  (menuOpened)="menuOpened(_column.id)"
                  [ngClass]="_menuOpenedColId === _column.id ? 'menu-open' : ''">
                  <fa-icon icon="ellipsis-v"></fa-icon>
                </button>
              </div>
            </div>
            <div
              *ngIf="_filtersOpenedColId === _column.id"
              class="column-menu"
              #columnMenu>
              <ng-template
                [ngTemplateOutlet]="_column.filter.filterRenderer"
                [ngTemplateOutletContext]="{ column: _column }">
              </ng-template>
              <button
                class="button transparent"
                (click)="filtersOpened()">
                <fa-icon icon="times"></fa-icon>
              </button>
            </div>
          </th>
        </tr>
        <tr>
          <th [colSpan]="displayedColumns.length">
            <mat-progress-bar [mode]="loading ? 'indeterminate' : 'determinate'"></mat-progress-bar>
          </th>
        </tr>
      </thead>
      <tbody
        [ngClass]="bodyClass"
        *ngFor="let rowByCategory of rowsByCategory; trackBy: rowsByCategoryTrackBy">
        <tr
          *ngIf="rowByCategory.category?.title"
          class="category">
          <th
            *ngIf="rowByCategory.category?.change.observed; let selectionCell"
            class="selection-cell">
            <mat-checkbox
              [checked]="rowByCategory.category.selected"
              (click)="rowByCategory.category._onChange()"></mat-checkbox>
          </th>
          <th
            class="category-title"
            [ngClass]="rowByCategory.category?.change.observed ? 'with-selection' : ''">
            {{ rowByCategory.category.title }}
          </th>
          <th [attr.colspan]="getCategoryColSpan(rowByCategory.category)"></th>
        </tr>
        <tr
          *ngFor="let row of rowByCategory.rows; trackBy: rowTrackBy"
          [ngClass]="row?.classNames"
          (click)="row.onClick()">
          <td
            *ngFor="let _column of displayedColumns"
            [ngStyle]="getStickyStyle(_column)"
            [ngClass]="row.cellsByColumnId[_column.id]?.className">
            <ng-template *ngTemplateOutlet="row.cellsByColumnId[_column.id]?.renderer"> </ng-template>
          </td>
        </tr>
      </tbody>
      <tfoot *ngIf="footer?.values?.length > 0 && rowsByCategory?.length > 0">
        <tr *ngFor="let row of footer.values; trackBy: rowTrackBy">
          <td
            *ngFor="let _column of displayedColumns"
            [ngStyle]="getStickyStyle(_column)"
            [ngClass]="row.cellsByColumnId[_column.id]?.className">
            <ng-template *ngTemplateOutlet="row.cellsByColumnId[_column.id]?.renderer"> </ng-template>
          </td>
        </tr>
      </tfoot>
    </table>
    <ng-container *ngIf="rowsByCategory == null || rowsByCategory.length === 0">
      <span #emptyContent
        ><ng-content
          select="[data-table-empty-state]"
          #emptyContent></ng-content
      ></span>
      <empty-state-message
        title="No data available"
        icon="empty-set"
        *ngIf="emptyContent.childNodes.length === 0">
        Adjust your filters, or if none exist, add some data
      </empty-state-message>
    </ng-container>
  </div>
  <div
    *ngIf="paging"
    class="data-table-paging">
    <paging
      [count]="paging.count"
      [currPage]="paging.currentPage"
      [limit]="paging.limit"
      (changePage)="paging.changePage($event)">
    </paging>
  </div>
  <div
    *ngIf="_manageColumnsOpened"
    class="column-menu"
    #columnMenu>
    <div class="column-menu-list">
      <mat-slide-toggle
        *ngFor="let _managedColumn of managedColumns; trackBy: columnTrackBy"
        [checked]="isColumnVisible(_managedColumn)"
        (change)="setColumnVisibilty(_managedColumn.id, $event.checked)">
        {{ _managedColumn.title }}
      </mat-slide-toggle>
    </div>
    <button
      class="button transparent top"
      title="Close"
      (click)="manageColumnsOpened(false)">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>
</div>
