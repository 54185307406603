import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { complement, isNil } from "ramda";
import { map } from "rxjs/operators";
import { addProvider, close, fetchLocationsRequest } from "../store/actions/aura.actions";
import { FetchLocationsRequest, LocationView } from "../models/aura-integration-provider";
import { getAvailableSalons, getError, getLoading } from "../store/selectors/aura.selector";

@Component({
  selector: "add-provider-aura-container",
  templateUrl: "add-provider-aura.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderAuraContainer {
  public loading$: Observable<boolean>;
  public error$: Observable<boolean>;
  public availableSalons$: Observable<LocationView[]>;
  public isStep1$: Observable<boolean>;
  public isStep2$: Observable<boolean>;

  constructor(public _store: Store<AppState>) {
    this.loading$ = this._store.select(getLoading);
    this.error$ = this._store.select(getError);

    this.availableSalons$ = this._store.select(getAvailableSalons);

    this.isStep1$ = this.availableSalons$.pipe(map(isNil));
    this.isStep2$ = this.availableSalons$.pipe(map(complement(isNil)));
  }

  public fetchLocationsRequest(locationsRequest: FetchLocationsRequest): void {
    this._store.dispatch(fetchLocationsRequest({ locationsRequest }));
  }

  public chooseLocation(location: LocationView): void {
    this._store.dispatch(addProvider({ location }));
  }

  public close(): void {
    this._store.dispatch(close());
  }
}
