import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { FetchSalonsRequest, SalonIqSalon } from "app/+integrations/models/saloniq-salon";
import { SalonSoftwareIntegrationService } from "app/+integrations/services";
import { go } from "app/kernel/store/actions/router.actions";
import { map, mapTo, switchMap, withLatestFrom } from "rxjs/operators";
import { either } from "fp-ts";
import { HttpError } from "@getvish/stockpile";

import * as actions from "../actions/saloniq.actions";
import { getIdToken } from "../selectors/saloniq.selector";
import { Close } from "../integrations.actions";

@Injectable()
export class SalonIQEffects {
  navigateAddProviderSalonIQ$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateAddSaloniqProvider),
      mapTo(go({ path: ["/integrations", { outlets: { panel: "add-provider/saloniq" } }] }))
    )
  );

  saloniqLoadAvailableSalons$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.saloniqLoadAvailableSalons),
      map((action) => action.idToken),
      switchMap((tokenId) => {
        const request: FetchSalonsRequest = { tokenId };
        return this._salonSoftwareIntegrationService.findSaloniqAvailableSalons(request).pipe(
          map(
            either.fold<HttpError, SalonIqSalon[], Action>(
              (error) => actions.saloniqLoadAvailableSalonsFail({ error: new Error(error.payload.toString()) }),
              (availableSalons) => actions.saloniqLoadAvailableSalonsSuccess({ availableSalons })
            )
          )
        );
      })
    )
  );

  addProviderSaloniq$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.saloniqAddProvider),
      map((action) => action.location),
      withLatestFrom(this._store.select(getIdToken)),
      switchMap(([location, tokenId]) =>
        this._salonSoftwareIntegrationService.addSaloniqProvider({ salonId: location.id, tokenId, isEnabled: false }).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => actions.saloniqAddProviderFail({ error }),
              () => actions.saloniqAddProviderSuccess()
            )
          )
        )
      )
    )
  );

  close$ = createEffect(() => this._actions$.pipe(ofType(actions.close, actions.saloniqAddProviderSuccess), mapTo(new Close())));

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _salonSoftwareIntegrationService: SalonSoftwareIntegrationService
  ) {}
}
