import { NgModule } from "@angular/core";
import { HttpRequestHandler, HttpRequestTransformer, BaseHttpRepository, HttpRepositoryFactory } from "@getvish/stockpile";
import { WindowService } from "./window.service";
import { SalonService } from "../../+salons/services/salon.service";
import { GlobalSettingsService } from "./global-settings.service";
import {
  SERVER_CONFIG,
  HTTP_URL,
  HTTP_REQUEST_TRANSFORMER,
  WEBSOCKET_URL,
  buildWebsocketUrlFromConfig,
  buildHttpUrlFromConfig,
} from "./common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { not, isNil } from "ramda";
import { fromNullable, getOrElse, some } from "fp-ts/Option";
import { AuthStorageService } from "../../+auth/services";
import { CurrentTenantService, CurrentSalonService } from "./current-tenant.service";
import { pipe } from "fp-ts/function";
import { AppMode } from "../models/app-mode";
import { EmployeeService } from "app/+employees/services/employee.service";

export function appModeFactory(windowService: WindowService): AppMode {
  const tenantPath = windowService.tenantPath;

  const multiTenantBaseHrefs = ["/", "/login"];

  if (multiTenantBaseHrefs.includes(tenantPath)) {
    return new AppMode("MULTI_TENANT");
  } else if (tenantPath === "/forgot-password") {
    return new AppMode("FORGOT_PASSWORD");
  } else if (tenantPath === "/admin") {
    return new AppMode("ADMIN");
  } else if (tenantPath === "/booker") {
    return new AppMode("BOOKER-OAUTH");
  } else if (tenantPath === "/square") {
    return new AppMode("SQUARE-OAUTH");
  } else if (tenantPath === "/salon-interactive") {
    return new AppMode("SALON-INTERACTIVE-OAUTH");
  } else if (tenantPath === "/inventory-integration") {
    return new AppMode("INVENTORY-INTEGRATION");
  } else {
    return new AppMode("SINGLE_TENANT");
  }
}

export function httpRequestTransformerFn(
  windowService: WindowService,
  authStorage: AuthStorageService,
  currentSalonService: CurrentSalonService
): HttpRequestTransformer {
  return (maybeHeaders?: HttpHeaders): HttpHeaders => {
    const slug = windowService.tenantPathName;
    const authToken = authStorage.getAuthToken();
    const salonId = currentSalonService.getCurrentSalonId();

    const headersToAppend = [
      { key: "X-Salon-Slug", value: slug },
      { key: "X-Auth-Token", value: authToken },
      { key: "X-Salon-Id", value: salonId },
    ].filter((item) => not(isNil(item.value)));

    return headersToAppend.reduce(
      (acc, curr) => {
        return acc.append(curr.key, curr.value);
      },
      pipe(
        fromNullable(maybeHeaders),
        getOrElse(() => new HttpHeaders())
      )
    );
  };
}

export function httpRequestHandlerFactory(
  httpClient: HttpClient,
  baseUrl: string,
  requestTransformer: HttpRequestTransformer
): HttpRequestHandler {
  return new HttpRequestHandler(httpClient, baseUrl, some(requestTransformer));
}

export function httpRepositoryFactoryFn(baseHttpRepository: BaseHttpRepository): HttpRepositoryFactory {
  return new HttpRepositoryFactory(baseHttpRepository);
}

export function baseHttpRepositoryFn(
  httpClient: HttpClient,
  baseUrl: string,
  httpRequestTransformer: HttpRequestTransformer
): BaseHttpRepository {
  return new BaseHttpRepository(httpClient, baseUrl, some(httpRequestTransformer));
}

@NgModule({
  providers: [SalonService, EmployeeService],
})
export class KernelServicesModule {
  public static forRoot() {
    return {
      ngModule: KernelServicesModule,
      providers: [
        WindowService,
        GlobalSettingsService,
        { provide: CurrentTenantService, useClass: CurrentTenantService, deps: [SalonService, CurrentSalonService, EmployeeService] },
        { provide: CurrentSalonService, useClass: CurrentSalonService },
        {
          provide: HTTP_URL,
          useFactory: buildHttpUrlFromConfig,
          deps: [SERVER_CONFIG],
        },
        {
          provide: HTTP_REQUEST_TRANSFORMER,
          useFactory: httpRequestTransformerFn,
          deps: [WindowService, AuthStorageService, CurrentSalonService],
        },
        { provide: HttpRequestHandler, useFactory: httpRequestHandlerFactory, deps: [HttpClient, HTTP_URL, HTTP_REQUEST_TRANSFORMER] },
        {
          provide: HttpRepositoryFactory,
          useFactory: httpRepositoryFactoryFn,
          deps: [BaseHttpRepository],
        },
        {
          provide: BaseHttpRepository,
          useFactory: baseHttpRepositoryFn,
          deps: [HttpClient, HTTP_URL, HTTP_REQUEST_TRANSFORMER],
        },
        {
          provide: WEBSOCKET_URL,
          useFactory: buildWebsocketUrlFromConfig,
          deps: [SERVER_CONFIG],
        },
        {
          provide: AppMode,
          useFactory: appModeFactory,
          deps: [WindowService],
        },
      ],
    };
  }
}
