<div>
  <form
    [formGroup]="form"
    (submit)="saveForm(form.value)"
    novalidate>
    <mat-form-field class="full-width">
      <mat-label>Token</mat-label>
      <input
        matInput
        formControlName="token" />
      <mat-error *ngIf="form.get('token').hasError('required')">Required</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Tenant Subdomain</mat-label>
      <input
        matInput
        formControlName="tenantSubdomain" />
      <mat-error *ngIf="form.get('tenantSubdomain').hasError('required')">Required</mat-error>
    </mat-form-field>
  </form>
  <div
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="saveForm(form.value)"
      type="button"
      [disabled]="form.invalid"
      class="button primary"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Continue
    </button>
  </div>
</div>
