import { Injectable } from "@angular/core";
import { HttpError } from "@getvish/stockpile";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { SalonSoftwareIntegrationService } from "app/+integrations/services";
import { go } from "app/kernel/store/actions/router.actions";
import { map, switchMap } from "rxjs/operators";
import { either } from "fp-ts";

import { Close } from "../integrations.actions";

import * as actions from "../actions/kitomba.actions";

@Injectable()
export class KitombaEffects {
  navigateAddKitombaProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateAddKitombaProvider),
      map(() => go({ path: ["/integrations", { outlets: { panel: "add-provider/kitomba" } }] }))
    )
  );

  addProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addProvider),
      switchMap(({ request }) => {
        return this._salonSoftwareIntegrationService.addKitombaProvider(request).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => actions.addProviderFail({ error }),
              () => actions.addProviderSuccess()
            )
          )
        );
      })
    )
  );

  close$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.close, actions.addProviderSuccess),
      map(() => new Close())
    )
  );

  constructor(
    private _actions$: Actions,
    private _salonSoftwareIntegrationService: SalonSoftwareIntegrationService
  ) {}
}
