import { createSelector, createFeatureSelector } from "@ngrx/store";
import * as FromEnvision from "../reducers/envision.reducer";

export const getState = createFeatureSelector<FromEnvision.EnvisionState>(FromEnvision.envisionFeatureKey);

export const getAvailableSalons = createSelector(getState, (state) => state.availableSalons);
export const getApiKey = createSelector(getState, (state) => state.apiKey);
export const getUsername = createSelector(getState, (state) => state.username);
export const getPassword = createSelector(getState, (state) => state.password);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getError = createSelector(getState, (state) => state.error);
