import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { LocationView } from "app/+integrations/models/boulevard-integration-provider";

@Component({
  selector: "boulevard-choose-location",
  templateUrl: "boulevard-choose-location.component.html",
  styleUrls: ["boulevard-choose-location.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoulevardChooseLocationComponent implements OnInit {
  @Input() availableSalons: LocationView[];
  @Output() save: EventEmitter<LocationView>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      salon: [undefined, Validators.required],
    });
  }

  public saveForm(value): void {
    this.save.emit(value.salon);
  }
}
