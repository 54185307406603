import { HttpError } from "@getvish/stockpile";
import { createAction, props } from "@ngrx/store";
import { AddIntegrationRequest } from "app/+integrations/models/kitomba-integration-provider";

export const navigateAddKitombaProvider = createAction("[Integration/Kitomba] Navigate Add Kitomba Provider");

export const addProvider = createAction("[Integration/Kitomba] Add Provider", props<{ request: AddIntegrationRequest }>());

export const addProviderSuccess = createAction("[Integration/Kitomba] Add Provider Success");

export const addProviderFail = createAction("[Integration/Kitomba] Add Provider Fail", props<{ error: HttpError }>());

export const close = createAction("[Integration/Kitomba] Close");
