<dialog-contents
  dialogTitle="Set Markup"
  (close)="close()">
  <div>
    <form
      [formGroup]="form"
      (submit)="save(form.value.markupPercent)"
      novalidate>
      <mat-form-field class="full-width">
        <mat-label>Markup</mat-label>
        <span class="input-item">
          <input
            matInput
            formControlName="markupPercent"
            type="number"
            min="0"
            step="25" />
          <span matSuffix>%</span>
        </span>
        <mat-error>Required</mat-error>
      </mat-form-field>
    </form>

    <div
      class="controls"
      style="position: relative; margin: 0 10%">
      <button
        (click)="save(form.value.markupPercent)"
        type="button"
        [disabled]="form.invalid"
        class="button primary"
        style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
        Save
      </button>
    </div>
  </div>
</dialog-contents>
