import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ManageProviderSquareComponent, RevokeAccessComponent } from "./components";

import {
  SoftwareProvidersContainer,
  AddProviderSalonbizContainer,
  AddProviderSalonIQContainer,
  AddProviderBoulevardContainer,
  AddProviderEnvisionContainer,
  AddProviderPhorestContainer,
  AddProviderShortcutsContainer,
  AddProviderRosyContainer,
  AddProviderMeevo2Container,
  AddProviderMytimeContainer,
  AddProviderZenotiContainer,
  AddProviderKitombaContainer,
  EditProviderSquareContainer,
  AddProviderAuraContainer,
} from "./containers";

export const routes = [
  { path: "", component: SoftwareProvidersContainer },
  { path: "add-provider/phorest", component: AddProviderPhorestContainer, outlet: "panel" },
  { path: "add-provider/shortcuts", component: AddProviderShortcutsContainer, outlet: "panel" },
  { path: "add-provider/salonbiz", component: AddProviderSalonbizContainer, outlet: "panel" },
  { path: "add-provider/saloniq", component: AddProviderSalonIQContainer, outlet: "panel" },
  { path: "add-provider/boulevard", component: AddProviderBoulevardContainer, outlet: "panel" },
  { path: "add-provider/envision", component: AddProviderEnvisionContainer, outlet: "panel" },
  { path: "add-provider/rosy", component: AddProviderRosyContainer, outlet: "panel" },
  { path: "add-provider/zenoti", component: AddProviderZenotiContainer, outlet: "panel" },
  {
    path: "edit-provider/square",
    component: EditProviderSquareContainer,
    outlet: "panel",
    children: [
      { path: "", component: ManageProviderSquareComponent },
      { path: "revoke-access", component: RevokeAccessComponent },
    ],
  },
  { path: "add-provider/aura", component: AddProviderAuraContainer, outlet: "panel" },
  { path: "add-provider/meevo2", component: AddProviderMeevo2Container, outlet: "panel" },
  { path: "add-provider/mytime", component: AddProviderMytimeContainer, outlet: "panel" },
  { path: "add-provider/kitomba", component: AddProviderKitombaContainer, outlet: "panel" },
  { path: "map-provider-data", loadChildren: () => import("./+mapping/mapping.module").then((m) => m.MappingModule) },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IntegrationsRoutingModule {}
