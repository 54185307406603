import { Salon, SalonModule } from "@getvish/model";
import { EntityService, HttpRepositoryFactory, HttpError, PagedResult, HttpRequestHandler, JsonObject } from "@getvish/stockpile";
import { Injectable } from "@angular/core";
import { mergeMap, Observable, of, throwError } from "rxjs";
import { Either } from "fp-ts/Either";
import { validate as validateUuid } from "uuid";
import { option } from "fp-ts";

import * as escapeStringRegexp from "escape-string-regexp";
import { pipe } from "fp-ts/lib/function";
import { Geometry, Point } from "geojson";
import { fold } from "fp-ts/lib/Option";

@Injectable()
export class SalonService extends EntityService<Salon> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _httpRequestHandler: HttpRequestHandler
  ) {
    super(repositoryFactory, { entityKey: "salons" });
  }

  public insert(doc: Salon): Observable<Either<Error, Salon>> {
    // check if the salon to insert has a slug; if it doesn't, create one for it
    const salonToInsert = SalonModule.hasSlug(doc) ? doc : SalonModule.createSlug(doc);

    return super.insert(salonToInsert);
  }

  public createOrders(salonId: string): Observable<Either<HttpError, object>> {
    return this._httpRequestHandler.post("retroactiveOrderGenerator/createOrders", salonId);
  }

  public search(filter: string, sort?: JsonObject, page?: number, limit?: number): Observable<PagedResult<Salon>> {
    const escapeRegexpIfNotUuid = (input: string): string => (validateUuid(input) ? input : escapeStringRegexp.default(input));

    const emptyCriteria = {};

    const makeCriteria = (filter: string) => {
      const inner = filter
        .split(" ")
        // because we want the user to be able to search by the Salon's ID
        // but ID will be a UUID, it'll get regex-escaped and all messed up
        // so to avoid that we'll only escape if the input is _not_ a UUID
        .map(escapeRegexpIfNotUuid)
        // it seems that &s don't get properly URL-encoded (or something else that I don't quite understand)
        // so we'll explicitly replace &s with their URL-encoded counterpart for now
        // at least it works - if anyone knows _why_ it doesn't work when not URL-encoded please fix
        // (I would have assumed that an & would be URL-encoded automatically when the network request was made)
        .map((term) => term.replace("&", `%26`))
        .map((term) => {
          return {
            $or: [{ name: { $regex: `${term}`, $options: "i" } }, { slug: { $regex: `${term}`, $options: "i" } }, { _id: term }],
          };
        });

      return {
        $and: inner,
      };
    };

    const criteria = pipe(
      option.fromNullable(filter),
      option.fold(
        () => emptyCriteria,
        (value) => makeCriteria(value)
      )
    );

    return this.find(criteria, sort, page, limit);
  }

  public migrateToV2(salonId: string): Observable<Either<HttpError, void>> {
    return this._httpRequestHandler.post(`salon/${salonId}/migrate`, {});
  }

  public revokeAccess(salonId: string): Observable<Either<HttpError, void>> {
    return this._httpRequestHandler.delete(`salons/${salonId}/entitlements`);
  }

  public restoreAccess(salonId: string): Observable<Either<HttpError, void>> {
    return this._httpRequestHandler.post(`salons/${salonId}/entitlements/restore`, {});
  }

  public createOrUpdateLocation(salonId: string, geometry: Geometry): Observable<void> {
    const _point = geometry as Point;

    return this._httpRequestHandler.postOrDie(`salons/${salonId}/location`, {
      point: { lat: _point.coordinates[1], long: _point.coordinates[0] },
      tenantId: salonId,
    });
  }

  public getLocation(salonId: string): Observable<Geometry> {
    return this._httpRequestHandler.get<any>(`salons/${salonId}/location`).pipe(
      mergeMap(
        fold(
          () => throwError(() => new Error("Something went wrong while fetching location")),
          (geometry) => of({ type: "Point", coordinates: [geometry.point.long, geometry.point.lat] } as Point)
        )
      )
    );
  }
}
