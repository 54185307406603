import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faPlug } from "@fortawesome/free-solid-svg-icons";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { BookerConfig } from "app/+booker-oauth/models";
import { CardModule, PageModule } from "app/+components";
import { environment } from "src/environments/environment";
import { KernelServicesModule } from "../kernel";
import { SharedModule } from "../kernel/shared";
import {
  AddProviderKitombaComponent,
  AddProviderPhorestComponent,
  AddProviderRosyComponent,
  AddProviderSalonbizComponent,
  AddProviderSalonIQComponentSelectSalonComponent,
  AddProviderShortcutsComponent,
  AddProviderZenotiComponent,
  AuraChooseLocationComponent,
  AuraFetchLocationsFormComponent,
  BoulevardChooseLocationComponent,
  BoulevardFetchLocationsFormComponent,
  EnvisionChooseLocationComponent,
  EnvisionFetchLocationsFormComponent,
  IdTokenFormComponent,
  ManageProviderSquareComponent,
  Meevo2ChooseLocationComponent,
  Meevo2FetchLocationsFormComponent,
  MytimeChooseLocationComponent,
  MytimeFetchLocationsFormComponent,
  RevokeAccessComponent,
  SoftwareProvidersComponent,
} from "./components";
import {
  AddProviderAuraContainer,
  AddProviderBoulevardContainer,
  AddProviderEnvisionContainer,
  AddProviderKitombaContainer,
  AddProviderMeevo2Container,
  AddProviderMytimeContainer,
  AddProviderPhorestContainer,
  AddProviderRosyContainer,
  AddProviderSalonbizContainer,
  AddProviderSalonIQContainer,
  AddProviderShortcutsContainer,
  AddProviderZenotiContainer,
  EditProviderSquareContainer,
  SoftwareProvidersContainer,
} from "./containers";
import { IntegrationsRoutingModule } from "./integrations-routing.module";
import { IntegrationSoftwareProviderService, SalonSoftwareIntegrationService, SquareIntegrationService } from "./services";
import {
  BoulevardEffects,
  IntegrationsEffects,
  integrationsReducer,
  KitombaEffects,
  Meevo2Effects,
  MytimeEffects,
  RosyEffects,
  ZenotiEffects,
} from "./store";
import { AuraEffects } from "./store/effects/aura.effects";
import { EditSquareIntegrationEffects } from "./store/effects/edit-square-integration.effects";
import { EnvisionEffects } from "./store/effects/envision.effects";
import { SalonIQEffects } from "./store/effects/saloniq.effects";

import * as fromAura from "./store/reducers/aura.reducer";
import * as fromBoulevard from "./store/reducers/boulevard.reducer";
import * as fromEditSquareIntegration from "./store/reducers/edit-square-integration.reducer";
import * as fromEnvision from "./store/reducers/envision.reducer";
import * as fromKitomba from "./store/reducers/kitomba.reducer";
import * as fromMeevo2 from "./store/reducers/meevo2.reducer";
import * as fromMytime from "./store/reducers/mytime.reducer";
import * as fromRosy from "./store/reducers/rosy.reducer";
import * as fromSaloniq from "./store/reducers/saloniq.reducer";
import * as fromZenoti from "./store/reducers/zenoti.reducer";
import { IntegrationPricingService } from "./services/integration-pricing.service";

@NgModule({
  imports: [
    CardModule,
    PageModule,
    KernelServicesModule,
    SharedModule,
    StoreModule.forFeature("integrations", integrationsReducer),
    StoreModule.forFeature(fromBoulevard.boulevardFeatureKey, fromBoulevard.reducer),
    StoreModule.forFeature(fromEnvision.envisionFeatureKey, fromEnvision.reducer),
    StoreModule.forFeature(fromRosy.rosyFeatureKey, fromRosy.reducer),
    StoreModule.forFeature(fromSaloniq.saloniqFeatureKey, fromSaloniq.reducer),
    StoreModule.forFeature(fromMeevo2.meevo2FeatureKey, fromMeevo2.reducer),
    StoreModule.forFeature(fromMytime.mytimeFeatureKey, fromMytime.reducer),
    StoreModule.forFeature(fromZenoti.featureKey, fromZenoti.reducer),
    StoreModule.forFeature(fromEditSquareIntegration.featureKey, fromEditSquareIntegration.reducer),
    StoreModule.forFeature(fromAura.auraFeatureKey, fromAura.reducer),
    StoreModule.forFeature(fromKitomba.kitombaFeatureKey, fromKitomba.reducer),
    EffectsModule.forFeature([
      IntegrationsEffects,
      BoulevardEffects,
      EnvisionEffects,
      RosyEffects,
      SalonIQEffects,
      ZenotiEffects,
      EditSquareIntegrationEffects,
      AuraEffects,
      Meevo2Effects,
      MytimeEffects,
      KitombaEffects,
    ]),
    ReactiveFormsModule,
    IntegrationsRoutingModule,
  ],
  providers: [IntegrationSoftwareProviderService, SalonSoftwareIntegrationService, SquareIntegrationService, IntegrationPricingService],
  declarations: [
    AddProviderKitombaComponent,
    AddProviderPhorestComponent,
    AddProviderRosyComponent,
    AddProviderSalonbizComponent,
    AddProviderSalonIQComponentSelectSalonComponent,
    AddProviderShortcutsComponent,
    AddProviderZenotiComponent,
    AuraChooseLocationComponent,
    AuraFetchLocationsFormComponent,
    BoulevardChooseLocationComponent,
    BoulevardFetchLocationsFormComponent,
    EnvisionChooseLocationComponent,
    EnvisionFetchLocationsFormComponent,
    IdTokenFormComponent,
    ManageProviderSquareComponent,
    Meevo2ChooseLocationComponent,
    Meevo2FetchLocationsFormComponent,
    MytimeChooseLocationComponent,
    MytimeFetchLocationsFormComponent,
    RevokeAccessComponent,
    SoftwareProvidersComponent,
    AddProviderAuraContainer,
    AddProviderBoulevardContainer,
    AddProviderEnvisionContainer,
    AddProviderKitombaContainer,
    AddProviderMeevo2Container,
    AddProviderMytimeContainer,
    AddProviderPhorestContainer,
    AddProviderRosyContainer,
    AddProviderSalonbizContainer,
    AddProviderSalonIQContainer,
    AddProviderShortcutsContainer,
    AddProviderZenotiContainer,
    EditProviderSquareContainer,
    SoftwareProvidersContainer,
  ],
})
export class IntegrationsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faPlug);
  }
}
