import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { go } from "app/kernel/store/actions/router.actions";
import { catchError, map, mapTo, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { AppState } from "app/kernel";
import { getCurrentTenantId } from "app/kernel/store/reducers/current-tenant.reducer";
import { SquareIntegrationService } from "app/+integrations/services";
import { of } from "rxjs";

import * as actions from "../actions/edit-square-integration.actions";
import * as routerActions from "../../../kernel/store/actions/router.actions";
import * as integrationsActions from "../integrations.actions";

@Injectable()
export class EditSquareIntegrationEffects {
  public navigate$ = createEffect(() =>
    this._actions$.pipe(ofType(actions.navigate), mapTo(go({ path: ["/integrations", { outlets: { panel: "edit-provider/square" } }] })))
  );

  public loadData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.loadData),
      switchMap(() =>
        this._squareIntegrationService.getDetails().pipe(
          map((details) => actions.loadDataSuccess({ details })),
          catchError((error) => of(actions.loadDataFail({ error })))
        )
      )
    )
  );

  public navigateRevokeAccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateRevokeAccess),
      mapTo(go({ path: ["/integrations", { outlets: { panel: "edit-provider/square/revoke-access" } }] }))
    )
  );

  public revokeAccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.revokeAccess),
      withLatestFrom(this._store.select(getCurrentTenantId)),
      mergeMap(([_, salonId]) =>
        this._squareIntegrationService.revokeAccess(salonId).pipe(
          map(() => actions.revokeAccessSuccess()),
          catchError(() => of(actions.revokeAccessFail()))
        )
      )
    )
  );

  public revokeAccessSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.revokeAccessSuccess),
      map(() => new integrationsActions.LoadData())
    )
  );

  public close$ = createEffect(() =>
    this._actions$.pipe(ofType(actions.close), mapTo(routerActions.go({ path: ["/integrations", { outlets: { panel: null } }] })))
  );

  constructor(
    private _store: Store<AppState>,
    private _squareIntegrationService: SquareIntegrationService,
    private _actions$: Actions
  ) {}
}
