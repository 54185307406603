import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { FetchLocationsRequest } from "app/+integrations/models/envision-integration-provider";

@Component({
  selector: "envision-fetch-locations-form",
  templateUrl: "envision-fetch-locations-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvisionFetchLocationsFormComponent implements OnInit {
  @Output() save: EventEmitter<FetchLocationsRequest>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      apiKey: [undefined, Validators.required],
      username: [undefined, Validators.required],
      password: [undefined, Validators.required],
    });
  }

  public saveForm(data: FetchLocationsRequest): void {
    this.save.emit(data);
  }
}
