<div>
  <form
    [formGroup]="form"
    (submit)="saveForm(form.value)"
    novalidate>
    <mat-form-field class="full-width">
      <mat-label>Business ID</mat-label>
      <input
        matInput
        formControlName="businessId"
        type="number" />
      <mat-error *ngIf="form.get('businessId').hasError('required')">Required</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Store ID</mat-label>
      <input
        matInput
        formControlName="storeId"
        type="number" />
      <mat-error *ngIf="form.get('storeId').hasError('required')">Required</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Formula Type Name</mat-label>
      <input
        matInput
        formControlName="formulaTypeName" />
      <mat-error *ngIf="form.get('formulaTypeName').hasError('required')">Required</mat-error>
    </mat-form-field>
  </form>
  <div
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="saveForm(form.value)"
      type="button"
      [disabled]="form.invalid"
      class="button primary"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Save
    </button>
  </div>
</div>
