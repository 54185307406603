import { HttpError } from "@getvish/stockpile";
import { createAction, props } from "@ngrx/store";
import { FetchLocationsRequest, LocationView } from "app/+integrations/models/meevo2-integration-provider";

export const navigateAddMeevo2Provider = createAction("[Integration/Meevo2] Navigate Add Meevo2 Provider");

export const fetchLocationsRequest = createAction(
  "[Integration/Meevo2] Fetch Locations Request",
  props<{ request: FetchLocationsRequest }>()
);

export const fetchLocationsRequestSuccess = createAction(
  "[Integration/Meevo2] Fetch Locations Request Success",
  props<{ availableSalons: LocationView[] }>()
);

export const fetchLocationsRequestFail = createAction("[Integration/Meevo2] Fetch Locations Request Fail", props<{ error: Error }>());

export const addProvider = createAction("[Integration/Meevo2] Add Provider", props<{ location: LocationView }>());

export const addProviderSuccess = createAction("[Integration/Meevo2] Add Provider Success");

export const addProviderFail = createAction("[Integration/Meevo2] Add Provider Fail", props<{ error: HttpError }>());

export const close = createAction("[Integration/Meevo2] Close");
