import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MeasurementUnit, SalonProduct } from "@getvish/model";

@Component({
  templateUrl: "./edit-product-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProductDialogComponent {
  constructor(
    private _matDialogRef: MatDialogRef<EditProductDialogComponent, SalonProduct>,
    @Inject(MAT_DIALOG_DATA)
    public data: { product: SalonProduct; measurementUnit: MeasurementUnit; currency: string }
  ) {}

  public close(): void {
    this._matDialogRef.close();
  }

  public save(product: SalonProduct): void {
    this._matDialogRef.close(product);
  }
}
