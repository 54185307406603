import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { FetchLocationsRequest } from "app/+integrations/models/boulevard-integration-provider";

@Component({
  selector: "boulevard-fetch-locations-form",
  templateUrl: "boulevard-fetch-locations-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoulevardFetchLocationsFormComponent implements OnInit {
  @Output() save: EventEmitter<FetchLocationsRequest>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      businessId: [undefined, Validators.required],
    });
  }

  public saveForm(data: FetchLocationsRequest): void {
    this.save.emit(data);
  }
}
