import { createReducer, on } from "@ngrx/store";
import {
  applyPricing,
  applyPricingFail,
  applyPricingSuccess,
  clearSelected,
  init,
  initFail,
  initSuccess,
  show,
  toggleSelected,
  updateTable,
  updateTableFail,
  updateTableStarted,
  updateTableSuccess,
} from "./master-pricing.actions";
import { MasterList } from "../models/master-pricing.model";
import { JsonObject } from "@getvish/stockpile";

export interface MasterPricingState {
  manufacturerId: string;
  masterLists: MasterList[];
  loading: boolean;
  applyingPricing: boolean;
  selectedRecords: MasterList[];
  sort: JsonObject;
  tableFilter: JsonObject;
  error: Error;
}

const initialState: MasterPricingState = {
  manufacturerId: undefined,
  masterLists: [],
  loading: false,
  applyingPricing: false,
  selectedRecords: [],
  sort: {
    createdAt: -1,
  },
  tableFilter: undefined,
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(show, () => ({
    ...initialState,
  })),
  on(init, (state, { manufacturerId }) => ({
    ...state,
    loading: true,
    manufacturerId,
  })),
  on(initSuccess, updateTableSuccess, (state, { masterLists }) => ({
    ...state,
    masterLists,
    loading: false,
  })),
  on(initFail, updateTableFail, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(toggleSelected, (state, masterList) => {
    return {
      ...state,
      selectedRecords: masterList.selected ? [] : [masterList],
    };
  }),
  on(clearSelected, (state) => ({
    ...state,
    selectedRecords: [],
  })),
  on(updateTable, (state, { sort, filter }) => ({
    ...state,
    sort: sort != null ? sort : state.sort,
    tableFilter: { ...state.tableFilter, ...filter },
  })),
  on(updateTableStarted, (state) => ({
    ...state,
    loading: true,
  })),
  on(applyPricing, (state) => ({
    ...state,
    applyingPricing: true,
  })),
  on(applyPricingSuccess, (state) => ({
    ...state,
    applyingPricing: false,
  })),
  on(applyPricingFail, (state, { error }) => ({
    ...state,
    applyingPricing: false,
    error,
  }))
);

export const featureKey = "masterPricing";
