import { Component } from "@angular/core";
import { MeasurementUnit, ProductCategory, SalonProduct } from "@getvish/model";
import { combineLatest, filter, map, Observable, pairwise, scan, take } from "rxjs";
import * as fromSalonConfig from "../../../../+salon-config/store/salon-config.selectors";
import * as fromImportSalonProducts from "../../store/import-salon-products.selectors";
import { select, Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { createSortedCategoryChain } from "app/+product/util/product-category-util";
import { CategoryPricing, fromProductCategory, SalonProductVM } from "app/+product/+salon-products/common";
import { pipe } from "fp-ts/lib/function";
import * as R from "ramda";
import { PricingFilter } from "../../models/pricing-filter";
import { applyPricingList, setPricingFilter, updateMarkup, updatePricing, updatePricingMulti } from "../../store/import-salon-products.actions";
import { MatDialog } from "@angular/material/dialog";
import { EditProductDialogComponent } from "./edit-product-dialog.component";
import { EditProductCategoryDialogComponent } from "./edit-product-category-dialog.component";
import { SetMarkupDialogComponent } from "./set-markup-dialog.component";
import * as fromAuth from "app/+auth/store/auth.selectors";
import { MasterPricingDialogComponent } from "app/+product/+master-pricing/components";
import { MasterList } from "app/+product/+master-pricing/models/master-pricing.model";

@Component({
  selector: "verify-pricing-step",
  templateUrl: "./verify-pricing-step.component.html",
  styleUrls: ["./verify-pricing-step.component.less"],
})
export class VerifyPricingStepComponent {
  public salonProducts$: Observable<SalonProductVM[]>;
  public filteredSalonProducts$: Observable<SalonProductVM[]>;
  public allProductCategories$: Observable<ProductCategory[]>;
  public pricingFilter$: Observable<PricingFilter>;
  public numPriced$: Observable<number>;
  public numUnpriced$: Observable<number>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public currency$: Observable<string>;
  public isRootUser$: Observable<boolean>;

  constructor(
    private _store: Store<AppState>,
    private _matDialog: MatDialog
  ) {
    this.salonProducts$ = combineLatest([
      this._store.select(fromImportSalonProducts.getSelectedManufacturer),
      this._store.select(fromImportSalonProducts.getSelectedProducts),
      this._store.select(fromImportSalonProducts.getPricing),
      this._store.select(fromImportSalonProducts.getAvailableCategories),
    ]).pipe(
      map(([manufacturer, selectedProducts, pricing, categories]) => {
        if (selectedProducts == null) {
          return [];
        }

        const products = selectedProducts.find((sp) => sp.manufacturer._id === manufacturer._id)?.products ?? [];

        return products.map((product) => {
          const productPricing = pricing[product._id];

          const wholesalePrice = productPricing?.wholesalePrice;
          const markup = productPricing?.markup;
          const containerSize = productPricing?.containerSize;

          return {
            ...product,
            productId: product._id,
            wholesalePrice,
            markup,
            containerSize,
            selected: false,
            pricingIncomplete: wholesalePrice == null || markup == null || containerSize == null,
            inactive: false,
            availableCategories: pipe(
              categories.filter((category) => product.categoryId === category._id),
              R.map(fromProductCategory([], categories))
            ),
          };
        });
      })
    );

    this.numPriced$ = this.salonProducts$.pipe(map((products) => products.filter((product) => !product.pricingIncomplete).length));
    this.numUnpriced$ = this.salonProducts$.pipe(map((products) => products.filter((product) => product.pricingIncomplete).length));

    this.numUnpriced$.pipe(
      pairwise(),
      filter(([prev, curr]) => prev !== 0 && curr === 0),
      take(1)
    ).subscribe(() => {
      this._store.dispatch(setPricingFilter({ filter: 'PRICED' }));
    });

    this.pricingFilter$ = combineLatest([this._store.pipe(select(fromImportSalonProducts.getPricingFilter)), this.numUnpriced$]).pipe(
      map(([filter, numUnpriced]) => {
        if (filter == null) {
          return numUnpriced > 0 ? "UNPRICED" : "PRICED";
        }

        return filter;
      })
    );

    this.filteredSalonProducts$ = combineLatest([this.salonProducts$, this.pricingFilter$]).pipe(
      map(([products, filter]) => {
        if (filter === "ALL") {
          return products;
        }

        return products.filter((product) => {
          if (filter === "PRICED") {
            return !product.pricingIncomplete;
          }

          return product.pricingIncomplete;
        });
      })
    );

    this.allProductCategories$ = _store
      .pipe(select(fromImportSalonProducts.getAvailableCategories))
      .pipe(map((productCategories) => createSortedCategoryChain(productCategories)));

    this.measurementUnit$ = _store.pipe(select(fromSalonConfig.getMeasurementUnitOrDefault));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
    this.isRootUser$ = _store.select(fromAuth.isRoot);
  }

  public setPricingFilter(filter: PricingFilter): void {
    this._store.dispatch(setPricingFilter({ filter }));
  }

  public editProductClicked(product: SalonProduct): void {
    combineLatest([this.measurementUnit$, this.currency$])
      .pipe(take(1))
      .subscribe(([measurementUnit, currency]) => {
        const dialogRef = this._matDialog.open<
          EditProductDialogComponent,
          { product: SalonProduct; measurementUnit: MeasurementUnit; currency: string },
          SalonProduct
        >(EditProductDialogComponent, {
          disableClose: true,
          maxHeight: "95vh",
          panelClass: "dlg-no-padding-pane",
          data: {
            product,
            measurementUnit,
            currency,
          },
        });

        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe((result) => {
            if (result != null) {
              this._store.dispatch(
                updatePricing({
                  productId: product._id,
                  pricing: {
                    wholesalePrice: result.wholesalePrice,
                    markup: result.markup,
                    containerSize: result.containerSize,
                  },
                })
              );
            }
          });
      });
  }

  public setCategoryPricingClicked(category: ProductCategory): void {
    combineLatest([this.filteredSalonProducts$, this.measurementUnit$, this.currency$])
      .pipe(take(1))
      .subscribe(([salonProducts, measurementUnit, currency]) => {
        const dialogRef = this._matDialog.open<
          EditProductCategoryDialogComponent,
          { categoryId: string; measurementUnit: MeasurementUnit; currency: string },
          CategoryPricing
        >(EditProductCategoryDialogComponent, {
          disableClose: true,
          maxHeight: "95vh",
          panelClass: "dlg-no-padding-pane",
          data: {
            categoryId: category._id,
            measurementUnit,
            currency,
          },
        });

        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe((result) => {
            if (result != null) {
              this._store.dispatch(
                updatePricingMulti({
                  pricing: salonProducts
                    .filter((product) => product.categoryId === category._id)
                    .reduce((acc, product) => {
                      acc[product.productId] = result;
                      return acc;
                    }, {}),
                })
              );
            }
          });
      });
  }

  public setAllMarkup(): void {
    this._setMarkup().pipe(take(1)).subscribe((markup) => {
      if (markup != null) {
        this.filteredSalonProducts$.pipe(take(1)).subscribe((products) => {
          this._store.dispatch(
            updateMarkup({
              markup: products.reduce((acc, product) => {
                acc[product.productId] = markup;
                return acc;
              }, {}),
            })
          );
        });
      }
    });
  }

  public setCategoryMarkup(category: ProductCategory): void {
    this._setMarkup().pipe(take(1)).subscribe((markup) => {
      if (markup != null) {
        this.filteredSalonProducts$.pipe(take(1)).subscribe((products) => {
          this._store.dispatch(
            updateMarkup({
              markup: products
                .filter((product) => product.categoryId === category._id)
                .reduce((acc, product) => {
                  acc[product.productId] = markup;
                  return acc;
                }, {}),
            })
          );
        });
      }
    });
  }

  private _setMarkup(): Observable<number> {
    const dialogRef = this._matDialog.open<
      SetMarkupDialogComponent,
      void,
      number
    >(SetMarkupDialogComponent, {
      disableClose: true,
      maxHeight: "95vh",
      panelClass: "dlg-no-padding-pane",
    });

    return dialogRef.afterClosed();
  }

  public showMasterPricing(): void {
    this._store.select(fromImportSalonProducts.getSelectedManufacturer).pipe(take(1)).subscribe((manufacturer) => {
      const dialogRef = this._matDialog.open<MasterPricingDialogComponent, any, void>(MasterPricingDialogComponent, {
        disableClose: true,
        width: "80vw",
        height: "95vh",
        maxHeight: "95vh",
        panelClass: "dlg-no-padding-pane",
        data: {
          manufacturer: { ...manufacturer },
          applyPricing: (masterList: MasterList) => {
            this._store.dispatch(applyPricingList({ pricingList: masterList }));
            dialogRef.close();
          }
        },
      })
    });
  }
}
