import { Observable, of, throwError } from "rxjs";
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HTTP_URL } from "app/kernel/services/common";
import { map, mergeMap } from "rxjs/operators";
import { Location } from "../models/square";
import { HttpRequestHandler } from "@getvish/stockpile";
import { fold } from "fp-ts/lib/Option";

export interface IntegrationDetails {
  location?: Location;
  isEnabled: boolean;
  isAuthTokenValid: boolean;
  supportsSellerLevelWrites: boolean;
}

@Injectable()
export class SquareIntegrationService {
  constructor(
    @Inject(HTTP_URL) private _httpUrl: string,
    private _httpRequestHandler: HttpRequestHandler,
    private _http: HttpClient
  ) {}

  public revokeAccess(salonId: string): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        "X-Salon-Id": salonId,
      }),
    };

    return this._http.delete(`${this._httpUrl}/integrations/square/oauth`, httpOptions).pipe(map(() => undefined));
  }

  public getDetails(): Observable<IntegrationDetails> {
    return this._httpRequestHandler.get<IntegrationDetails>("integrations/square/details").pipe(
      mergeMap(
        fold(
          () => throwError(new Error("Something went wrong while fetching Square Integration Details")),
          (details) => of(details)
        )
      )
    );
  }
}
