import { Injectable } from "@angular/core";
import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { Manufacturer } from "@getvish/model";
import { forkJoin, map, Observable, of } from "rxjs";
import { flatten, isEmpty, splitEvery, uniq } from "ramda";

@Injectable()
export class ManufacturerService extends EntityService<Manufacturer> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "manufacturers" });
  }

  public override findByIds(ids: string[]): Observable<Manufacturer[]> {
    const uniqueIds = uniq(ids);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((_ids) => super.findByIds(_ids));

    return isEmpty(requests) ? of([]) : forkJoin(requests).pipe(map(flatten));
  }
}
