<dialog-contents
  [dialogTitle]="_data.manufacturer.name + ' Master Pricing'"
  [progressText]="(loading$ | async) ? 'Loading...' : (applyingPricing$ | async) ? 'Applying Pricing...' : undefined"
  [error]="(error$ | async)?.message"
  (close)="close()"
  class="full-height-table">
  <master-pricing-table
    [masterLists]="masterLists$ | async"
    [sort]="sort$ | async"
    [tableFilter]="tableFilter$ | async"
    [disableSelections]="false"
    [showManufacturer]="false"
    (toggleSelected)="toggleSelected($event)"
    (clearSelected)="clearSelected()"
    (updateTableFilter)="updateTableFilter($event)"
    (updateSort)="updateSort($event)"></master-pricing-table>
  <div
    class="footer-buttons"
    dialog-footer>
    <button
      class="button small"
      (click)="applyPricing()"
      [disabled]="!((selectedRecords$ | async)?.length > 0)">
      Apply Pricing
    </button>
  </div>
</dialog-contents>
