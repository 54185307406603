import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { SharedModule } from "app/kernel/shared";
import { KernelServicesModule } from "../../kernel";
import { AddManufacturerCardComponent, EditSalonProductComponent, SetCategoryPricingComponent } from "./components";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { ProductsCategoriesListComponent } from "./components/products-categories-list/products-categories-list.component";
import { SalonProductsListComponent } from "./components/salon-products-list.component";
import { SalonProductCategoryService } from "./services/salon-product-category.service";
import { SalonProductService } from "./services/salon-product.service";
import { SalonProductsGroupedListComponent } from "./components/salon-products-grouped-list/salon-products-grouped-list.component";
import { ProgressIndicatorModule } from "app/+components";

@NgModule({
  imports: [SharedModule, KernelServicesModule, MatCardModule, ProgressIndicatorModule],
  declarations: [
    BreadcrumbsComponent,
    ProductsCategoriesListComponent,
    AddManufacturerCardComponent,
    SalonProductsListComponent,
    SalonProductsGroupedListComponent,
    EditSalonProductComponent,
    SetCategoryPricingComponent,
  ],
  exports: [
    BreadcrumbsComponent,
    ProductsCategoriesListComponent,
    AddManufacturerCardComponent,
    SalonProductsGroupedListComponent,
    EditSalonProductComponent,
    SetCategoryPricingComponent,
  ],
  providers: [SalonProductService, SalonProductCategoryService],
})
export class SalonProductsSharedModule {}
