import { Component, ChangeDetectionStrategy } from "@angular/core";
import { ShortcutsIntegrationProvider } from "../../kernel/models";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { AddShortcutsProvider } from "../store/integrations.actions";
import { go } from "../../kernel/store/actions/router.actions";

@Component({
  selector: "add-provider-shortcuts-container",
  templateUrl: "add-provider-shortcuts.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderShortcutsContainer {
  constructor(public _store: Store<AppState>) {}

  public save(value: ShortcutsIntegrationProvider): void {
    this._store.dispatch(new AddShortcutsProvider({ value }));
  }

  public close(): void {
    this._store.dispatch(go({ path: ["/integrations", { outlets: { panel: null } }] }));
  }
}
