import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey, MasterPricingState } from "./master-pricing.reducer";

export const getState = createFeatureSelector<MasterPricingState>(featureKey);
export const getError = createSelector(getState, (state) => state.error);
export const isLoading = createSelector(getState, (state) => state.loading);
export const isApplyingPricing = createSelector(getState, (state) => state.applyingPricing);
export const getMasterLists = createSelector(getState, (state) => state.masterLists);
export const getSelectedRecords = createSelector(getState, (state) => state.selectedRecords);
export const getSort = createSelector(getState, (state) => state.sort);
export const getTableFilter = createSelector(getState, (state) => state.tableFilter);
export const getManufacturerId = createSelector(getState, (state) => state.manufacturerId);
