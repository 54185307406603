import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ZenotiIntegrationRequest } from "../../models";

@Component({
  selector: "add-provider-zenoti",
  templateUrl: "add-provider-zenoti.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderZenotiComponent implements OnInit {
  @Output() save: EventEmitter<ZenotiIntegrationRequest>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      apiKey: [undefined, Validators.required],
      centerId: [undefined, Validators.required],
    });
  }

  public saveForm(data: ZenotiIntegrationRequest): void {
    this.save.emit({ ...data, isEnabled: false });
  }
}
