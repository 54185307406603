import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as L from "leaflet";
import { equals } from "ramda";

const MAP_LAYER = {
  urlTemplate: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  options: {
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
};

@Component({
  selector: "map",
  templateUrl: "map.component.html",
  styleUrls: ["map.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input()
  public layers: Array<L.Layer>;

  @ViewChild("map")
  private mapRef: ElementRef;

  private map!: L.Map;
  private resizeObserver: ResizeObserver;

  public ngAfterViewInit(): void {
    this._init();
    this._handleLayers(null, this.layers);
  }

  public ngOnDestroy(): void {
    if (this.resizeObserver != null) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.map != null) {
      if (!equals(changes.layers?.previousValue, changes.layers?.currentValue)) {
        this._handleLayers(changes.layers?.previousValue, changes.layers?.currentValue);
      }
    }
  }

  private _init() {
    this.map = L.map(this.mapRef.nativeElement);
    L.tileLayer(MAP_LAYER.urlTemplate, MAP_LAYER.options).addTo(this.map);

    this.resizeObserver = new ResizeObserver(() => {
      this.map.invalidateSize();
    });

    this.resizeObserver.observe(this.mapRef.nativeElement);
  }

  private _handleLayers(previousLayers: Array<L.Layer>, currentLayers: Array<L.Layer>) {
    if (previousLayers != null) {
      for (const previousLayer of previousLayers) {
        previousLayer.remove();
      }
    }

    if (currentLayers != null && currentLayers.length > 0) {
      for (const currentLayer of currentLayers) {
        currentLayer.addTo(this.map);
      }

      this.map.fitBounds(L.featureGroup(currentLayers).getBounds());
    } else {
      this.map.setView([0, 0], 1);
    }
  }
}
