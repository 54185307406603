import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { IntegrationSoftwareProvider, SalonSoftwareIntegration } from "app/kernel/models/salon-software-provider";
import { sortBy } from "ramda";
import { Observable, map } from "rxjs";
import { AppState } from "../../kernel";
import {
  EditIntegration,
  InitAndLoadData,
  RemoveIntegration,
  addProvider,
  disableIntegration,
  enableIntegration,
  navigateMapProvider,
} from "../store/integrations.actions";
import { getIntegrations, getLoading } from "../store/integrations.selectors";

@Component({
  selector: "software-providers-container",
  templateUrl: "software-providers.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftwareProvidersContainer {
  public integrations$: Observable<SalonSoftwareIntegration[]>;
  public loading$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    _store.dispatch(new InitAndLoadData());

    this.loading$ = _store.select(getLoading);
    this.integrations$ = _store.select(getIntegrations).pipe(map(sortBy((i) => i.name)));
  }

  public addProvider(integration: IntegrationSoftwareProvider): void {
    this._store.dispatch(addProvider(integration));
  }

  public enableProvider(integration: SalonSoftwareIntegration): void {
    this._store.dispatch(enableIntegration(integration));
  }

  public mapProvider(integration: SalonSoftwareIntegration): void {
    this._store.dispatch(navigateMapProvider(integration));
  }

  public disableProvider(integration: SalonSoftwareIntegration): void {
    this._store.dispatch(disableIntegration(integration));
  }

  public removeProvider(provider: SalonSoftwareIntegration): void {
    this._store.dispatch(new RemoveIntegration({ provider }));
  }

  public editProvider(integration: SalonSoftwareIntegration): void {
    this._store.dispatch(new EditIntegration({ integration }));
  }
}
