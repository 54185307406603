import { HttpError } from "@getvish/stockpile";
import { createAction, props } from "@ngrx/store";
import { AddIntegrationRequest } from "app/+integrations/models/rosy-integration-provider";

export const navigateAddRosyProvider = createAction("[Integration/Rosy] Navigate Add Rosy Provider");

export const addProvider = createAction("[Integration/Rosy] Add Provider", props<{ request: AddIntegrationRequest }>());

export const addProviderSuccess = createAction("[Integration/Rosy] Add Provider Sucess");

export const addProviderFail = createAction("[Integration/Rosy] Add Provider Fail", props<{ error: HttpError }>());

export const close = createAction("[Integration/Rosy] Close");
