import { Injectable, Injector } from "@angular/core";
import { combineLatest } from "rxjs";
import { LogRocketConfig } from "../models";

import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import * as LogRocket from "logrocket";
import * as R from "ramda";

import { AppMode } from "app/kernel/models";
import * as fromAuth from "../../../+auth/store/auth.selectors";
import * as fromCurrentTenant from "../../../kernel/store/reducers/current-tenant.reducer";
import { AppConfig } from "app/kernel/models/app-config";

const IGNOREABLE_NETWORK_REQUESTS = [
  {
    url: /api\/v2\/login$/,
    method: "POST",
    redactedKeys: ["password"],
  },
  {
    url: /api\/v2\/multiTenant\/login$/,
    method: "POST",
    redactedKeys: ["password"],
  },
  {
    url: /api\/v2\/user\/password\/reset\/set$/,
    method: "POST",
    redactedKeys: ["password", "confirmPassword"],
  },
  {
    url: /api\/v2\/employees$/,
    method: "POST",
    redactedKeys: ["password"],
  },
  {
    url: /api\/v2\/employees\/.+/,
    method: "PATCH",
    redactedPatches: ["/password"],
  },
];

export const REDACTED_TEXT = "<--REDACTED-->";

@Injectable()
export class LogRocketManagerService {
  constructor(
    private _logRocketConfig: LogRocketConfig,
    private _appConfig: AppConfig,
    private injector: Injector,
    private _appMode: AppMode
  ) {}

  public init() {
    if (this._logRocketConfig.appId == null) {
      return;
    }

    LogRocket.init(this._logRocketConfig.appId, {
      release: this._appConfig.appVersion,
      network: {
        requestSanitizer: (request) => {
          IGNOREABLE_NETWORK_REQUESTS.forEach((inr) => {
            if (request.method === inr.method && request.url.match(inr.url)) {
              if (inr.redactedKeys != null) {
                inr.redactedKeys.forEach((key) => {
                  if (request.body != null) {
                    request.body = JSON.stringify(R.set(R.lensPath(key.split(".")), REDACTED_TEXT, JSON.parse(request.body)));
                  }
                });
              } else if (inr.redactedPatches != null) {
                inr.redactedPatches.forEach((redactedPatch) => {
                  if (request.body != null) {
                    const jsonPatches = JSON.parse(request.body);

                    for (const jsonPatch of jsonPatches) {
                      if (jsonPatch.path === redactedPatch) {
                        jsonPatch.value = REDACTED_TEXT;
                      }
                    }

                    request.body = JSON.stringify(jsonPatches);
                  }
                });
              }
            }
          });

          return request;
        },
      },
    });

    const store = this.injector.get(Store<AppState>);

    // Identify the user in LogRocket
    combineLatest([store.select(fromAuth.getUser), store.select(fromCurrentTenant.getCurrentTenant)]).subscribe(([user, salon]) => {
      if (this._appMode.value === "SINGLE_TENANT") {
        if (user != null && salon != null) {
          LogRocket.identify(`${user._id}_${salon._id}`, {
            name: `${salon.name} (${user.firstName} ${user.lastName})`,
            email: user.email,
            roles: user._roles != null ? user._roles.join(", ") : "",
            userFirstName: user.firstName,
            userLastName: user.lastName,
            salonName: salon.name,
            salonSlug: salon.slug,
            salonAddressLine1: salon.address?.addressLine1,
            salonAddressLine2: salon.address?.addressLine2,
            salonCity: salon.address?.city,
            salonProvince: salon.address?.province,
            salonCountry: salon.address?.country,
            salonPostalCode: salon.address?.postalCode,
          });
        }
      } else if (user != null) {
        LogRocket.identify(user._id, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          roles: user._roles != null ? user._roles.join(", ") : "",
          userFirstName: user.firstName,
          userLastName: user.lastName,
        });
      }
    });
  }

  public captureException(exception: Error): void {
    return LogRocket.captureException(exception);
  }
}
