import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { SalonIqSalon } from "../../models/saloniq-salon";

interface ChooseLocationForm {
  salon: SalonIqSalon;
}

@Component({
  selector: "choose-location",
  templateUrl: "choose-location.component.html",
  styleUrls: ["choose-location.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderSalonIQComponentSelectSalonComponent implements OnInit {
  @Input() availableSalons: SalonIqSalon[];
  @Output() save: EventEmitter<SalonIqSalon>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      salon: [undefined, Validators.required],
    });
  }

  public saveForm(value: ChooseLocationForm): void {
    this.save.emit(value.salon);
  }
}
