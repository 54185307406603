import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Manufacturer } from "@getvish/model";
import { JsonObject } from "@getvish/stockpile";
import { Store } from "@ngrx/store";
import { Selections } from "app/+components";
import { AppState } from "app/kernel";
import { Observable, combineLatest, map, take } from "rxjs";
import { MasterList } from "../../models/master-pricing.model";
import * as MasterPricingActions from "../../store/master-pricing.actions";
import {
  getError,
  getMasterLists,
  getSelectedRecords,
  getSort,
  getTableFilter,
  isApplyingPricing,
  isLoading,
} from "../../store/master-pricing.selectors";

export interface MasterListVM extends MasterList {
  selected?: boolean;
}

@Component({
  templateUrl: "./master-pricing-dialog.component.html",
  styleUrls: ["./master-pricing-dialog.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterPricingDialogComponent {
  public loading$: Observable<boolean>;
  public applyingPricing$: Observable<boolean>;
  public error$: Observable<Error>;
  public masterLists$: Observable<MasterListVM[]>;
  public selectedRecords$: Observable<MasterList[]>;
  public selections$: Observable<Selections>;
  public sort$: Observable<{ [key: string]: 1 | -1 }>;
  public tableFilter$: Observable<JsonObject>;

  public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(
    private _store: Store<AppState>,
    private _matDialogRef: MatDialogRef<MasterPricingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public _data: {
      manufacturer: Manufacturer;
      applyPricing?: (masterList: MasterList) => void;
    }
  ) {
    this._store.dispatch(MasterPricingActions.init({ manufacturerId: _data.manufacturer._id }));

    this.loading$ = _store.select(isLoading);
    this.applyingPricing$ = _store.select(isApplyingPricing);
    this.error$ = _store.select(getError);
    this.selectedRecords$ = _store.select(getSelectedRecords);
    this.selections$ = this.selectedRecords$.pipe(
      map((selectedRecords) => {
        return {
          selection: {
            count: selectedRecords.length,
            clearSelections: this.clearSelected.bind(this),
          },
        };
      })
    );

    this.masterLists$ = combineLatest([this._store.select(getMasterLists), this.selectedRecords$]).pipe(
      map(([masterLists, selectedRecords]) => {
        return masterLists.map((masterList) => {
          const selected = selectedRecords.some((record) => record._id === masterList._id);

          return {
            ...masterList,
            selected,
          };
        });
      })
    );

    this.sort$ = this._store.select(getSort) as Observable<{ [key: string]: 1 | -1 }>;
    this.tableFilter$ = this._store.select(getTableFilter);
  }

  public close(): void {
    this._matDialogRef.close();
  }

  public toggleSelected(masterList: MasterListVM): void {
    this._store.dispatch(MasterPricingActions.toggleSelected(masterList));
  }

  public clearSelected(): void {
    this._store.dispatch(MasterPricingActions.clearSelected());
  }

  public updateTableFilter(filter: JsonObject): void {
    this._store.dispatch(MasterPricingActions.updateTable({ filter }));
  }

  public updateSort(sort: JsonObject): void {
    this._store.dispatch(MasterPricingActions.updateTable({ sort }));
  }

  public applyPricing(): void {
    if (this._data.applyPricing != null) {
      this._store
        .select(getSelectedRecords)
        .pipe(
          take(1),
          map((records) => records[0])
        )
        .subscribe((masterList) => {
          this._data.applyPricing(masterList);
        });
    } else {
      this._store.dispatch(MasterPricingActions.applyPricing());
    }
  }

  public masterListRowTrackBy(idx: number, masterList: MasterList) {
    return masterList._id;
  }
}
