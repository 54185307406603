<slideout-panel (close)="close()">
  <header panel-header>Add Boulevard Integration</header>

  <div panel-body>
    <div *ngIf="error$ | async">
      <alert-component type="danger"> It looks like something bad happened. Close this panel and try again. </alert-component>
    </div>
    <div *ngIf="(loading$ | async) === false; else loadingElement">
      <boulevard-fetch-locations-form
        *ngIf="isStep1$ | async"
        (save)="fetchLocationsRequest($event)">
      </boulevard-fetch-locations-form>

      <boulevard-choose-location
        *ngIf="isStep2$ | async"
        [availableSalons]="availableSalons$ | async"
        (save)="chooseLocation($event)">
      </boulevard-choose-location>
    </div>
    <ng-template #loadingElement>
      <large-loading-indicator></large-loading-indicator>
    </ng-template>
  </div>
</slideout-panel>
