import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ShortcutsIntegrationProvider } from "../../../kernel/models";

@Component({
  selector: "add-provider-shortcuts",
  templateUrl: "add-provider-shortcuts.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderShortcutsComponent implements OnInit {
  @Output() save: EventEmitter<ShortcutsIntegrationProvider>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      companyId: [undefined, Validators.required],
      siteId: [undefined, Validators.required],
      visitNoteTypeName: [undefined],
    });
  }

  public saveForm(data: ShortcutsIntegrationProvider): void {
    this.save.emit(data);
  }
}
