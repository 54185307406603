import { Component, ChangeDetectionStrategy } from "@angular/core";
import { PhorestIntegrationProvider } from "../../kernel/models";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { AddPhorestProvider } from "../store/integrations.actions";
import { go } from "../../kernel/store/actions/router.actions";

@Component({
  selector: "add-provider-phorest-container",
  templateUrl: "add-provider-phorest.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderPhorestContainer {
  constructor(public _store: Store<AppState>) {}

  public save(value: PhorestIntegrationProvider): void {
    this._store.dispatch(new AddPhorestProvider({ value }));
  }

  public close(): void {
    this._store.dispatch(go({ path: ["/integrations", { outlets: { panel: null } }] }));
  }
}
