import { Action, createReducer, on } from "@ngrx/store";
import { LocationView } from "app/+integrations/models/envision-integration-provider";
import * as EnvisionActions from "../actions/envision.actions";

export interface EnvisionState {
  loading: boolean;
  availableSalons: LocationView[];
  error: boolean;
  apiKey: string;
  username: string;
  password: string;
  companyId: string;
}

export const initialState: EnvisionState = {
  loading: false,
  availableSalons: undefined,
  error: undefined,
  apiKey: undefined,
  username: undefined,
  password: undefined,
  companyId: undefined,
};

const envisionReducer = createReducer(
  initialState,
  on(EnvisionActions.fetchLocationsRequest, (state, { locationsRequest }) => ({
    ...state,
    loading: true,
    apiKey: locationsRequest.apiKey,
    username: locationsRequest.username,
    password: locationsRequest.password,
  })),
  on(EnvisionActions.fetchLocationsRequestSuccess, (state, { availableSalons }) => ({
    ...state,
    loading: false,
    availableSalons,
  })),
  on(EnvisionActions.fetchLocationsRequestFail, (state) => ({
    ...state,
    loading: false,
    error: true,
  })),
  on(EnvisionActions.addProviderSuccess, (state) => ({ ...state, loading: false })),
  on(EnvisionActions.addProviderFail, (state) => ({ ...state, loading: false, error: true })),
  on(EnvisionActions.close, () => initialState)
);

export function reducer(state: EnvisionState | undefined, action: Action) {
  return envisionReducer(state, action);
}

export const envisionFeatureKey = "envision";
