<slideout-panel (close)="close()">
  <header panel-header>Manage Square Integration</header>

  <div panel-body>
    <router-outlet></router-outlet>

    <ng-template #loadingElement>
      <large-loading-indicator></large-loading-indicator>
    </ng-template>
  </div>
</slideout-panel>
