import booleanIntersects from "@turf/boolean-intersects";
import distance from "@turf/distance";
import pointToLineDistance from "@turf/point-to-line-distance"
import { Geometry, Point } from "geojson"
import { flatten } from "ramda";

export const geometriesIntersect = (geometry1: Geometry, geometry2: Geometry): boolean => {
  return booleanIntersects(geometry1, geometry2);
}

export const distanceBetweenGeometries = (geometry1: Geometry, geometry2: Geometry): number => {
  if (geometry1.type === "Point") {
    return distanceBetweenPointAndGeometry(geometry1, geometry2);
  } else if (geometry2.type === "Point") {
    return distanceBetweenPointAndGeometry(geometry2, geometry1);
  }

  const points1 = _collectPoints(geometry1);
  const points2 = _collectPoints(geometry2);

  return Math.min(
    points1.reduce((minDistance, point1) => Math.min(minDistance, distanceBetweenPointAndGeometry(point1, geometry2)), Infinity),
    points2.reduce((minDistance, point2) => Math.min(minDistance, distanceBetweenPointAndGeometry(point2, geometry1)), Infinity)
  );
}

export const distanceBetweenPointAndGeometry = (point: Point, geometry: Geometry): number => {
  if (geometry.type === "Point") {
    return distance(point, geometry);
  } else if (geometry.type === "MultiPoint") {
    return Math.min(...geometry.coordinates.map((coordinate) => distance(point, { type: "Point", coordinates: coordinate })));
  } else if (geometry.type === "LineString") {
    return pointToLineDistance(point, geometry);
  } else if (geometry.type === "MultiLineString") {
    return Math.min(...geometry.coordinates.map((c) => pointToLineDistance(point, { type: "LineString", coordinates: c })));
  } else if (geometry.type === "Polygon") {
    return pointToLineDistance(point, { type: "LineString", coordinates: geometry.coordinates[0] });
  } else if (geometry.type === "MultiPolygon") {
    return Math.min(...geometry.coordinates.map((c) => distanceBetweenPointAndGeometry(point, { type: "Polygon", coordinates: c })));
  } else if (geometry.type === "GeometryCollection") {
    return Math.min(...geometry.geometries.map((g) => distanceBetweenPointAndGeometry(point, g)));
  }
}

const _collectPoints = (geometry: Geometry): Point[] => {
  if (geometry.type === "Point") {
    return [geometry];
  } else if (geometry.type === "MultiPoint") {
    return geometry.coordinates.map((coordinate) => ({ type: "Point", coordinates: coordinate }));
  } else if (geometry.type === "LineString") {
    return geometry.coordinates.map((coordinate) => ({ type: "Point", coordinates: coordinate }));
  } else if (geometry.type === "MultiLineString") {
    return flatten(geometry.coordinates.map((c) => _collectPoints({ type: "LineString", coordinates: c })));
  } else if (geometry.type === "Polygon") {
    return geometry.coordinates[0].map((coordinate) => ({ type: "Point", coordinates: coordinate }));
  } else if (geometry.type === "MultiPolygon") {
    return flatten(geometry.coordinates.map((c) => _collectPoints({ type: "Polygon", coordinates: c })));
  } else if (geometry.type === "GeometryCollection") {
    return flatten(geometry.geometries.map((g) => _collectPoints(g)));
  }
}
