import { createSelector, createFeatureSelector } from "@ngrx/store";
import { State, featureKey } from "../reducers/edit-square-integration.reducer";

export const getState = createFeatureSelector<State>(featureKey);

export const getDone = createSelector(getState, (state) => state.done);
export const getError = createSelector(getState, (state) => state.error);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getDetails = createSelector(getState, (state) => state.details);
export const getErrorMessage = createSelector(getState, (state) => state.errorMessage);
