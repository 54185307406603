import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { map, catchError, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { SalonProductService } from "../../+salon-products/services";
import { Manufacturer, Product, SalonProduct } from "@getvish/model";
import { MatDialog } from "@angular/material/dialog";
import { ProductService } from "app/+product/+products/services";

import {
  show,
  load,
  selectManufacturer,
  importProducts,
  importProductsSuccess,
  importProductsFail,
  loadSuccess,
  loadFail,
  loadProductsForManufacturerSuccess,
  loadProductsForManufacturerFail,
  verifyPricing,
  verifyPricingSuccess,
  applyPricingList,
  updatePricingMulti,
} from "./import-salon-products.actions";

import * as salonProductActions from "../../+salon-products/store/salon-product.actions";
import * as snackbarActions from "../../../kernel/store/actions/snackbar.actions";

import { ImportProductsDialogComponent } from "../components/import-products-dialog";
import { ManufacturerService } from "app/+product/+manufacturers/services";
import { ProductCategoryService } from "app/+product/+product-categories/services";
import { JsonObject } from "@getvish/stockpile";
import { either } from "fp-ts";
import { Action, Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { getPricing, getSelectedManufacturer, getSelectedProducts } from "./import-salon-products.selectors";
import { MasterPricingService } from "app/+product/+master-pricing/services/master-pricing.service";
import { getSalonConfig } from "app/+salon-config/store/salon-config.selectors";

@Injectable()
export class ImportSalonProductEffects {
  public loadAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType(load),
      mergeMap(() =>
        forkJoin([this._manufacturerService.find({}, { name: 1 }), this._salonProductService.findAll()]).pipe(
          map(([manufacturersResult, salonProducts]) =>
            loadSuccess({
              manufacturers: manufacturersResult.records,
              salonProducts: salonProducts,
            })
          ),
          catchError((reason) => of(loadFail({ error: new Error(reason) })))
        )
      )
    )
  );

  public show$ = createEffect(() =>
    this._actions$.pipe(
      ofType(show),
      map(() =>
        this._matDialog.open<ImportProductsDialogComponent, JsonObject, { manufacturer: Manufacturer; products: Product[] }[]>(
          ImportProductsDialogComponent,
          { disableClose: true, width: "80vw", height: "95vh", maxHeight: "95vh", panelClass: "dlg-no-padding-pane" }
        )
      ),
      switchMap((dialogRef) =>
        this._actions$.pipe(ofType(importProducts)).pipe(
          withLatestFrom(this._store.select(getPricing)),
          mergeMap(([{ productGroups }, pricing]) =>
            this._salonProductService.importGroupedProducts(productGroups, pricing).pipe(
              map(
                either.fold<Error, SalonProduct[], Action>(
                  (error) => importProductsFail({ error }),
                  (importedSalonProducts) => {
                    dialogRef.close();

                    return importProductsSuccess({
                      salonProducts: importedSalonProducts,
                      manufacturers: productGroups.map((group) => group.manufacturer),
                    });
                  }
                )
              )
            )
          )
        )
      )
    )
  );

  public importProductsFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(importProductsFail),
      map(({ error }) => new snackbarActions.Info({ message: `Error importing products: ${error.message}` }))
    )
  );

  public loadProductsForManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(selectManufacturer),
      mergeMap(({ manufacturer }) =>
        forkJoin([
          this._productService.find({ manufacturerId: manufacturer._id }, { name: 1 }),
          this._productCategoryService.findForManufacturer(manufacturer._id),
        ])
      ),
      map(([productsResult, categoriesResult]) =>
        loadProductsForManufacturerSuccess({
          products: productsResult.records,
          categories: categoriesResult.records,
        })
      ),
      catchError((error) => of(loadProductsForManufacturerFail({ error })))
    )
  );

  public addDataOnImportSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(importProductsSuccess),
      map(() => new salonProductActions.LoadAll({}))
    )
  );

  public verifyPricing$ = createEffect(() =>
    this._actions$.pipe(
      ofType(verifyPricing),
      withLatestFrom(this._store.select(getSelectedManufacturer), this._store.select(getSelectedProducts)),
      map(([_, manufacturer, selectedProducts]) => selectedProducts.find((sp) => sp.manufacturer._id === manufacturer._id).products),
      withLatestFrom(this._store.select(getSalonConfig)),
      switchMap(([products, salonConfig]) =>
        this._masterPricingService.determineProductPricing(salonConfig, products).pipe(
          map((pricing) => verifyPricingSuccess({ pricing })),
          catchError((error) => {
            console.error(error);
            return [
              new snackbarActions.Info({ message: "An error occurred during automatic pricing." }),
              verifyPricingSuccess({ pricing: {} }),
            ];
          })
        )
      )
    )
  );

  public applyPricingList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(applyPricingList),
      switchMap(({ pricingList }) => this._masterPricingService.findProducts(pricingList._id)),
      withLatestFrom(
        this._store.select(getSelectedProducts),
        this._store.select(getSelectedManufacturer),
        this._store.select(getPricing),
        this._store.select(getSalonConfig)
      ),
      map(([masterListProducts, selectedProducts, selectedManufacturer, currentPricing, salonConfig]) => {
        const pricing = selectedProducts
          .find((sp) => sp.manufacturer._id === selectedManufacturer._id)
          .products.reduce((acc, product) => {
            const masterListProduct = masterListProducts.find((mlp) => mlp.globalProductId === product._id);

            if (masterListProduct == null) {
              return acc;
            }

            const _pricing = currentPricing[product._id];

            return {
              ...acc,
              [product._id]: {
                wholesalePrice: masterListProduct.wholesaleCost,
                markup: _pricing?.markup ?? salonConfig.defaultProductMarkup,
                containerSize: masterListProduct.containerSize,
              },
            };
          }, {});

        return updatePricingMulti({ pricing });
      })
    )
  );

  constructor(
    private _salonProductService: SalonProductService,
    private _productCategoryService: ProductCategoryService,
    private _manufacturerService: ManufacturerService,
    private _productService: ProductService,
    private _masterPricingService: MasterPricingService,
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _matDialog: MatDialog
  ) {}
}
