<div *ngIf="form | isDefined">
  <form
    [formGroup]="form"
    (submit)="saveForm(form.value, product)"
    novalidate>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input
        [readonly]="true"
        formControlName="name"
        matInput />
      <mat-error *ngIf="form.get('name').hasError('required')">Required</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Wholesale Price</mat-label>
      <span class="input-item">
        <input
          matInput
          formControlName="wholesalePrice"
          type="number"
          min="0" />
        <currency-suffix [currency]="currency"></currency-suffix>
      </span>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Markup</mat-label>
      <span class="input-item">
        <input
          matInput
          formControlName="markupPercent"
          type="number"
          min="0"
          step="25" />
        <span matSuffix>%</span>
      </span>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Retail Price</mat-label>
      <span class="input-item">
        <input
          matInput
          formControlName="retailPrice"
          type="number" />
        <currency-suffix [currency]="currency"></currency-suffix>
      </span>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Container Size</mat-label>
      <input
        matInput
        formControlName="containerSize"
        type="number"
        min="0.1"
        step="0.1" />
      <span matSuffix>{{ measurementUnit.abbreviation }}</span>
      <mat-error *ngIf="form.get('containerSize').hasError('valueNotGreaterThanNumber')">Must be bigger than zero</mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="showFlags === true"
      class="full-width">
      <mat-label>Flags</mat-label>
      <mat-select
        formControlName="flags"
        multiple>
        <mat-option value="INACTIVE">INACTIVE</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="saveForm(form.value, product)"
      type="button"
      [disabled]="form.invalid"
      class="button primary"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Save
    </button>
  </div>
</div>
