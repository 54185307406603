import { createAction, props, union } from "@ngrx/store";
import { IntegrationDetails } from "app/+integrations/services";
import { SalonSoftwareIntegration } from "app/kernel/models/salon-software-provider";

export const navigate = createAction("[Integration/Edit Square] Navigate", props<{ integration: SalonSoftwareIntegration }>());

export const loadData = createAction("[Integration/Edit Square] Load Data");

export const loadDataSuccess = createAction("[Integration/Edit Square] Load Data Success", props<{ details: IntegrationDetails }>());

export const loadDataFail = createAction("[Integration/Edit Square] Load Data Fail", props<{ error: Error }>());

export const navigateRevokeAccess = createAction("[Integration/Edit Square] Navigate Revoke Access");

export const revokeAccess = createAction("[Integration/Edit Square] Revoke Access");

export const revokeAccessSuccess = createAction("[Integration/Edit/Square] Revoke Access Success");

export const revokeAccessFail = createAction("[Integration/Edit Square] Revoke Access Fail");

export const close = createAction("[Integration/Edit Square] Close");

const actions = union({
  navigate,
  loadData,
  loadDataSuccess,
  loadDataFail,
  navigateRevokeAccess,
  revokeAccess,
  revokeAccessSuccess,
  revokeAccessFail,
  close,
});

export type Actions = typeof actions;
