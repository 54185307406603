import { LocationView } from "app/+integrations/models/aura-integration-provider";
import { Action, createReducer, on } from "@ngrx/store";
import * as AuraActions from "../actions/aura.actions";

export interface AuraState {
  availableSalons: LocationView[];
  token: string;
  tenantSubdomain: string;
  loading: boolean;
  error: boolean;
}

export const initialState: AuraState = {
  availableSalons: undefined,
  token: undefined,
  tenantSubdomain: undefined,
  loading: false,
  error: undefined,
};

const auraReducer = createReducer(
  initialState,
  on(AuraActions.fetchLocationsRequest, (state, { locationsRequest }) => ({
    ...state,
    loading: true,
    token: locationsRequest.token,
    tenantSubdomain: locationsRequest.tenantSubdomain,
  })),
  on(AuraActions.fetchLocationsRequestSuccess, (state, { availableSalons }) => ({
    ...state,
    loading: false,
    availableSalons,
  })),
  on(AuraActions.fetchLocationsRequestFail, (state) => ({
    ...state,
    loading: false,
    error: true,
  })),
  on(AuraActions.addProviderSuccess, (state) => ({ ...state, loading: false })),
  on(AuraActions.addProviderFail, (state) => ({ ...state, loading: false, error: true })),
  on(AuraActions.close, () => initialState)
);

export function reducer(state: AuraState | undefined, action: Action) {
  return auraReducer(state, action);
}

export const auraFeatureKey = "aura";
