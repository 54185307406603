import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { saloniqLoadAvailableSalons, saloniqAddProvider, close } from "../store/actions/saloniq.actions";
import { getAvailableSalons, getLoading, getError } from "../store/selectors/saloniq.selector";
import { SalonIqSalon } from "../models/saloniq-salon";
import { complement, isNil } from "ramda";

@Component({
  selector: "add-provider-saloniq-container",
  templateUrl: "add-provider-saloniq.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderSalonIQContainer {
  public availableSalons$: Observable<SalonIqSalon[]>;

  public loading$: Observable<boolean>;
  public error$: Observable<boolean>;
  public isStep1$: Observable<boolean>;
  public isStep2$: Observable<boolean>;

  constructor(public _store: Store<AppState>) {
    this.availableSalons$ = this._store.select(getAvailableSalons);
    this.loading$ = this._store.select(getLoading);
    this.error$ = this._store.select(getError);

    this.isStep1$ = this.availableSalons$.pipe(map(isNil));

    this.isStep2$ = this.availableSalons$.pipe(map(complement(isNil)));
  }

  public submitToken(idToken: string): void {
    this._store.dispatch(saloniqLoadAvailableSalons({ idToken }));
  }

  public chooseLocation(location: SalonIqSalon): void {
    this._store.dispatch(saloniqAddProvider({ location }));
  }

  public close(): void {
    this._store.dispatch(close());
  }
}
