import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as FromAura from "../reducers/aura.reducer";

export const getState = createFeatureSelector<FromAura.AuraState>(FromAura.auraFeatureKey);

export const getAvailableSalons = createSelector(getState, (state) => state.availableSalons);
export const getToken = createSelector(getState, (state) => state.token);
export const getTenantSubdomain = createSelector(getState, (state) => state.tenantSubdomain);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getError = createSelector(getState, (state) => state.error);
