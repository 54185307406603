import { HttpError } from "@getvish/stockpile";
import { createAction, props } from "@ngrx/store";
import { ZenotiIntegrationRequest } from "app/+integrations/models";

export const navigateAddZenotiProvider = createAction("[Integration/Zenoti] Navigate Add Zenoti Provider");

export const addProvider = createAction("[Integration/Zenoti] Add Provider", props<{ request: ZenotiIntegrationRequest }>());

export const addProviderSuccess = createAction("[Integration/Zenoti] Add Provider Sucess");

export const addProviderFail = createAction("[Integration/Zenoti] Add Provider Fail", props<{ error: HttpError }>());

export const close = createAction("[Integration/Zenoti] Close");
