import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { SERVER_CONFIG } from "app/kernel/services/common";
import { AppConfig } from "app/kernel/models/app-config";
import { BookerConfig } from "app/+booker-oauth/models";
import { LogRocketConfig } from "app/+reporting/logrocket/models";

if (environment.production) {
  fetch("/assets/config/config.json")
    .then((response) => response.json())
    .then((config) => {
      enableProdMode();

      platformBrowserDynamic([
        {
          provide: AppConfig,
          useValue: config.APP_CONFIG,
        },
        {
          provide: SERVER_CONFIG,
          useValue: config.SERVER_CONFIG,
        },
        {
          provide: BookerConfig,
          useValue: config.booker,
        },
        {
          provide: LogRocketConfig,
          useValue: config.LOGROCKET_CONFIG,
        },
      ])
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
    });
} else {
  platformBrowserDynamic([
    {
      provide: AppConfig,
      useValue: environment["APP_CONFIG"],
    },
    {
      provide: SERVER_CONFIG,
      useValue: environment["SERVER_CONFIG"],
    },
    {
      provide: BookerConfig,
      useValue: environment["booker"],
    },
    {
      provide: LogRocketConfig,
      useValue: environment["LOGROCKET_CONFIG"],
    },
  ])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}
