import { Action, createAction } from "@ngrx/store";
import { PhorestIntegrationProvider, ShortcutsIntegrationProvider, SalonbizIntegrationProvider } from "../../kernel/models";
import { navigateAddBoulevardProvider } from "./actions/boulevard.actions";
import { navigateAddEnvisionProvider } from "./actions/envision.actions";
import { navigateAddRosyProvider } from "./actions/rosy.actions";
import { navigateAddSaloniqProvider } from "./actions/saloniq.actions";
import { navigateAddZenotiProvider } from "./actions/zenoti.actions";
import { navigateAddAuraProvider } from "./actions/aura.actions";
import { navigateAddMeevo2Provider } from "./actions/meevo2.actions";
import { navigateAddMytimeProvider } from "./actions/mytime.actions";
import { navigateAddKitombaProvider } from "./actions/kitomba.actions";
import { IntegrationSoftwareProvider, SalonSoftwareIntegration } from "app/kernel/models/salon-software-provider";

import * as editSquareIntegration from "./actions/edit-square-integration.actions";

export enum Types {
  INIT_AND_LOAD_DATA = "[Integrations] Init and Load Data",
  LOAD_DATA = "[Integrations] Load Data",
  LOAD_DATA_SUCCESS = "[Integrations] Load Data Success",
  ADD_BOOKER_PROVIDER = "[Integrations] Add Booker Provider",
  ADD_BOOKER_PROVIDER_COMPLETE = "[Integrations] Add Booker Provider Complete",
  ADD_SQUARE_PROVIDER = "[Integrations] Add Square Provider",
  ADD_SQUARE_PROVIDER_COMPLETE = "[Integrations] Add Square Provider Complete",
  ADD_SALON_INTERACTIVE_PROVIDER = "[Integrations] Add SalonInteractive Provider",
  ADD_SALON_INTERACTIVE_PROVIDER_COMPLETE = "[Integrations] Add SalonInteractive Provider Complete",
  UNKNOWN_PROVIDER_ERROR = "[Integrations] Unknown Salon Software Provider Error",
  NAVIGATE_ADD_PHOREST_PROVIDER = "[Integrations] Navigate Add Phorest Provider",
  ADD_PHOREST_PROVIDER = "[Integrations] Add Phorest Provider",
  ADD_PHOREST_PROVIDER_SUCCESS = "[Integrations] Add Phorest Provider Success",
  NAVIGATE_ADD_SHORTCUTS_PROVIDER = "[Integrations] Navigate Add Shortcuts Provider",
  ADD_SHORTCUTS_PROVIDER = "[Integrations] Add Shortcuts Provider",
  ADD_SHORTCUTS_PROVIDER_SUCCESS = "[Integrations] Add Shortcuts Provider Success",
  NAVIGATE_ADD_SALONBIZ_PROVIDER = "[Integrations] Navigate Add Salonbiz Provider",
  ADD_SALONBIZ_PROVIDER = "[Integrations] Add Salonbiz Provider",
  ADD_SALONBIZ_PROVIDER_SUCCESS = "[Integrations] Add Salonbiz Provider Success",
  REMOVE_INTEGRATION = "[Integrations] Remove Integration",
  REMOVE_INTEGRATION_SUCCESS = "[Integrations] Remove Integration Success",
  REMOVE_INTEGRATION_FAIL = "[Integrations] Remove Integration Fail",
  ENABLE_INTEGRATION = "[Integrations] Enable Integration",
  ENABLE_INTEGRATION_SUCCESS = "[Integrations] Enable Integration Success",
  ENABLE_INTEGRATION_FAIL = "[Integrations] Enable Integration Fail",
  DISABLE_INTEGRATION = "[Integrations] Disable Integration",
  DISABLE_INTEGRATION_SUCCESS = "[Integrations] Disable Integration Success",
  DISABLE_INTEGRATION_FAIL = "[Integrations] Disable Integration Fail",
  NAVIGATE_MAP_PROVIDER = "[Integrations] Navigate Map Provider",
  EDIT_INTEGRATION = "[Integrations] Edit Integration",
  CLOSE = "[Integrations] Close",
}

export class InitAndLoadData implements Action {
  public readonly type = Types.INIT_AND_LOAD_DATA;
}

export class LoadData implements Action {
  public readonly type = Types.LOAD_DATA;
}

export class LoadDataSuccess implements Action {
  public readonly type = Types.LOAD_DATA_SUCCESS;

  constructor(public payload: { data: SalonSoftwareIntegration[] }) {}
}

export function addProvider(integration: IntegrationSoftwareProvider): Action {
  switch (integration.slug) {
    case "aura": {
      return navigateAddAuraProvider();
    }
    case "booker": {
      return new AddBookerProvider();
    }
    case "boulevard": {
      return navigateAddBoulevardProvider();
    }
    case "envision": {
      return navigateAddEnvisionProvider();
    }
    case "kitomba": {
      return navigateAddKitombaProvider();
    }
    case "meevo2": {
      return navigateAddMeevo2Provider();
    }
    case "mytime": {
      return navigateAddMytimeProvider();
    }
    case "phorest": {
      return new NavigateAddPhorestProvider();
    }
    case "rosy": {
      return navigateAddRosyProvider();
    }
    case "salonbiz": {
      return new NavigateAddSalonbizProvider();
    }
    case "saloniq": {
      return navigateAddSaloniqProvider();
    }
    case "shortcuts": {
      return new NavigateAddShortcutsProvider();
    }
    case "square": {
      return new AddSquareProvider();
    }
    case "zenoti": {
      return navigateAddZenotiProvider();
    }
    case "salon-interactive": {
      return new AddSalonInteractiveProvider();
    }
    default: {
      return new UnknownProviderError({ name: integration.name });
    }
  }
}

export const editProvider = (integration: SalonSoftwareIntegration): Action => {
  switch (integration.slug) {
    case "square": {
      return editSquareIntegration.navigate({ integration });
    }
    default: {
      return new UnknownProviderError({ name: integration.name });
    }
  }
};

export class RemoveIntegration implements Action {
  public readonly type = Types.REMOVE_INTEGRATION;

  constructor(public payload: { provider: SalonSoftwareIntegration }) {}
}

export class RemoveIntegrationSuccess implements Action {
  public readonly type = Types.REMOVE_INTEGRATION_SUCCESS;
}

export class RemoveIntegrationFail implements Action {
  public readonly type = Types.REMOVE_INTEGRATION_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class NavigateAddShortcutsProvider implements Action {
  public readonly type = Types.NAVIGATE_ADD_SHORTCUTS_PROVIDER;
}

export class AddShortcutsProvider implements Action {
  public readonly type = Types.ADD_SHORTCUTS_PROVIDER;

  constructor(public payload: { value: ShortcutsIntegrationProvider }) {}
}

export class AddShortcutsProviderSuccess implements Action {
  public readonly type = Types.ADD_SHORTCUTS_PROVIDER_SUCCESS;
}

export class AddBookerProvider implements Action {
  public readonly type = Types.ADD_BOOKER_PROVIDER;
}

// We won't be able to know if adding a Booker integration failed/was successful
// we'll only know when the process has finished which is why we don't have Fail/Success actions
export class AddBookerProviderComplete implements Action {
  public readonly type = Types.ADD_BOOKER_PROVIDER_COMPLETE;
}

export class AddSquareProvider implements Action {
  public readonly type = Types.ADD_SQUARE_PROVIDER;
}

// We won't be able to know if adding a Square integration failed/was successful
// we'll only know when the process has finished which is why we don't have Fail/Success actions
export class AddSquareProviderComplete implements Action {
  public readonly type = Types.ADD_SQUARE_PROVIDER_COMPLETE;
}

export class AddSalonInteractiveProvider implements Action {
  public readonly type = Types.ADD_SALON_INTERACTIVE_PROVIDER;
}

// We won't be able to know if adding a SalonInteractive integration failed/was successful
// we'll only know when the process has finished which is why we don't have Fail/Success actions
export class AddSalonInteractiveProviderComplete implements Action {
  public readonly type = Types.ADD_SALON_INTERACTIVE_PROVIDER_COMPLETE;
}

export class NavigateAddPhorestProvider implements Action {
  public readonly type = Types.NAVIGATE_ADD_PHOREST_PROVIDER;
}

export class AddPhorestProvider implements Action {
  public readonly type = Types.ADD_PHOREST_PROVIDER;

  constructor(public payload: { value: PhorestIntegrationProvider }) {}
}

export class AddPhorestProviderSuccess implements Action {
  public readonly type = Types.ADD_PHOREST_PROVIDER_SUCCESS;
}

export class NavigateAddSalonbizProvider implements Action {
  public readonly type = Types.NAVIGATE_ADD_SALONBIZ_PROVIDER;
}

export class AddSalonbizProvider implements Action {
  public readonly type = Types.ADD_SALONBIZ_PROVIDER;

  constructor(public payload: { value: SalonbizIntegrationProvider }) {}
}

export class AddSalonbizProviderSuccess implements Action {
  public readonly type = Types.ADD_SALONBIZ_PROVIDER_SUCCESS;
}

export class UnknownProviderError implements Action {
  public readonly type = Types.UNKNOWN_PROVIDER_ERROR;

  constructor(public payload: { name: string }) {}
}

export class EditIntegration implements Action {
  public readonly type = Types.EDIT_INTEGRATION;

  constructor(public payload: { integration: SalonSoftwareIntegration }) {}
}

export class Close implements Action {
  public readonly type = Types.CLOSE;
}

export const enableIntegration = createAction(Types.ENABLE_INTEGRATION, (provider: SalonSoftwareIntegration) => ({
  payload: { provider },
}));
export const enableIntegrationSuccess = createAction(Types.ENABLE_INTEGRATION_SUCCESS);
export const enableIntegrationFail = createAction(Types.ENABLE_INTEGRATION_FAIL, (error: Error) => ({ payload: { error } }));

export const disableIntegration = createAction(Types.DISABLE_INTEGRATION, (provider: SalonSoftwareIntegration) => ({
  payload: { provider },
}));
export const disableIntegrationSuccess = createAction(Types.DISABLE_INTEGRATION_SUCCESS);
export const disableIntegrationFail = createAction(Types.DISABLE_INTEGRATION_FAIL, (error: Error) => ({ payload: { error } }));

export const navigateMapProvider = createAction(Types.NAVIGATE_MAP_PROVIDER, (provider: SalonSoftwareIntegration) => ({
  payload: { provider },
}));

export type Actions =
  | InitAndLoadData
  | LoadData
  | LoadDataSuccess
  | AddBookerProvider
  | AddBookerProviderComplete
  | AddSquareProvider
  | AddSquareProviderComplete
  | NavigateAddPhorestProvider
  | AddPhorestProvider
  | AddPhorestProviderSuccess
  | NavigateAddSalonbizProvider
  | AddSalonbizProvider
  | AddSalonbizProviderSuccess
  | NavigateAddShortcutsProvider
  | AddShortcutsProvider
  | AddShortcutsProviderSuccess
  | RemoveIntegration
  | RemoveIntegrationSuccess
  | RemoveIntegrationFail
  | EditIntegration
  | Close;
