import { EntityService, HttpRepositoryFactory, HttpRequestHandler, HttpError } from "@getvish/stockpile";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { Inject, Injectable } from "@angular/core";
import { PhorestIntegrationProvider, ShortcutsIntegrationProvider, SalonbizIntegrationProvider } from "app/kernel/models";
import { catchError, map, mapTo, mergeMap } from "rxjs/operators";
import { Either } from "fp-ts/Either";
import { fold, toUndefined } from "fp-ts/Option";

import { FetchSalonsRequest, SalonIqSalon, AddIntegrationRequest as SalonIqAddIntegrationRequest } from "../models/saloniq-salon";

import {
  AddIntegrationRequest as BoulevardAddIntegrationRequest,
  FetchLocationsRequest as BoulevardFetchLocationsRequest,
  LocationView as BoulevardLocationView,
} from "../models/boulevard-integration-provider";

import {
  AddIntegrationRequest as EnvisionAddIntegrationRequest,
  FetchLocationsRequest as EnvisionFetchLocationsRequest,
  LocationView as EnvisionLocationView,
} from "../models/envision-integration-provider";
import { AddIntegrationRequest as RosyAddIntegrationRequest } from "../models/rosy-integration-provider";
import { ZenotiIntegrationRequest } from "../models";
import {
  AddIntegrationRequest as AuraAddIntegrationRequest,
  FetchLocationsRequest as AuraFetchLocationsRequest,
  LocationView as AuraLocationView,
} from "../models/aura-integration-provider";
import {
  AddIntegrationRequest as Meevo2AddIntegrationRequest,
  FetchLocationsRequest as Meevo2FetchLocationsRequest,
  LocationView as Meevo2LocationView,
} from "../models/meevo2-integration-provider";
import {
  AddIntegrationRequest as MytimeAddIntegrationRequest,
  FetchLocationsRequest as MytimeFetchLocationsRequest,
  LocationView as MytimeLocationView,
} from "../models/mytime-integration-provider";
import { AddIntegrationRequest as KitombaAddIntegrationRequest } from "../models/kitomba-integration-provider";
import { AppConfig } from "app/kernel/models/app-config";
import { BookerConfig } from "app/+booker-oauth/models";
import { ActiveSoftwareIntegration, SalonSoftwareIntegration } from "app/kernel/models/salon-software-provider";
import { IntegrationSoftwareProviderService } from "./salon-software-provider.service";
import { User } from "@getvish/model";
import { AuthStorageService, isAdmin } from "app/+auth/services";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { HTTP_URL } from "app/kernel/services/common";
import { WindowService } from "app/kernel";
import { left, right } from "fp-ts/lib/Either";

@Injectable()
export class SalonSoftwareIntegrationService extends EntityService<ActiveSoftwareIntegration> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _appConfig: AppConfig,
    private _httpRequestHandler: HttpRequestHandler,
    private _bookerConfig: BookerConfig,
    private _integrationProviderService: IntegrationSoftwareProviderService,
    protected _http: HttpClient,
    @Inject(HTTP_URL) private _httpUrl: string,
    private _windowService: WindowService,
    private _authStorage: AuthStorageService
  ) {
    super(repositoryFactory, { entityKey: "integrations" });
  }

  public loadProvidersAndActiveIntegrations(user: User): Observable<SalonSoftwareIntegration[]> {
    const getAvailableIntegrations = isAdmin(user)
      ? this._integrationProviderService.findAdminIntegrations()
      : this._integrationProviderService.findUserIntegrations();

    // fetch both the list of available integrations a salon may configure
    // and any active integrations this salon has already configured
    return forkJoin({
      activeIntegrations: this.findActiveForSalon(),
      availableIntegrations: getAvailableIntegrations,
      allIntegrations: this._integrationProviderService.findAdminIntegrations(),
    }).pipe(
      map(({ activeIntegrations, availableIntegrations, allIntegrations }) => {
        return {
          activeIntegrations,
          availableIntegrations: [
            ...availableIntegrations,
            ...activeIntegrations
              .filter((i) => !availableIntegrations.some((_i) => _i.provider === i.provider))
              .map((i) => allIntegrations.find((_i) => _i.provider === i.provider)),
          ],
          permittedIntegrations: availableIntegrations,
        };
      }),
      map(({ activeIntegrations, availableIntegrations, permittedIntegrations }) => {
        const integrations = availableIntegrations.map((availableIntegration) => ({
          ...availableIntegration,
          active: activeIntegrations.some((v) => v.provider.toLowerCase() === availableIntegration.provider.toLowerCase()),
          // enabled: activeIntegrations.some((v) => v.isEnabled && v.provider.toLowerCase() === availableIntegration.provider.toLowerCase()),
        }));

        return integrations.map((integration) => ({
          ...integration,
          canEdit: !integration.canEdit ? false : permittedIntegrations.some((i) => i.provider === integration.provider),
          canRemove: integration.active && permittedIntegrations.some((i) => i.provider === integration.provider),
        }));
      }),
      // now, if a salon has an active POS integration then _we only want to allow them access to that particular integration_
      // so in the case that there are any active POS integrations, filter out all the other POS providers
      // otherwise, if there's no active integration, just return all the available integrations
      map((records) => {
        if (records.some((v) => v.active === true)) {
          const { hasActivePos, hasActiveProducts } = records.reduce(
            (acc, value) => {
              if (value.active === true) {
                if (value.type === "pos") {
                  acc.hasActivePos = true;
                } else if (value.type === "products") {
                  acc.hasActiveProducts = true;
                }
              }
              return acc;
            },
            { hasActivePos: false, hasActiveProducts: false }
          );

          // really, really naive way of checking "if the provider is active, or if it's not a POS provider" (the only other type of provider at the moment is "products")
          return records.filter(
            (value) => value.active === true || (value.type === "products" && !hasActiveProducts) || (value.type === "pos" && !hasActivePos)
          );
        } else {
          return records;
        }
      })
    );
  }

  public findForSalon(): Observable<ActiveSoftwareIntegration[]> {
    return this._httpRequestHandler.get<ActiveSoftwareIntegration[]>(`integrations/salon`).pipe(map(toUndefined));
  }

  public findActiveForSalon(): Observable<ActiveSoftwareIntegration[]> {
    return this._httpRequestHandler.get<ActiveSoftwareIntegration[]>(`integrations/salon`).pipe(map(toUndefined));
  }

  public getBookerExternalUrl(salonId: string): Observable<string> {
    const endpoint = `${this._bookerConfig.authEntryPoint}/booker/auth?salonId=${salonId}`;

    return of(endpoint);
  }

  public getSquareExternalUrl(salonId: string): Observable<string> {
    // because we can only have one redirect/callback URL for Square's OAuth process
    // we'll need to share a single URL between both V1 and V2 versions of the webapp for the moment
    // so that any requests to complete Square's OAuth process will go through the V1 webapp which
    // is already configured to work. At some point in the future we may need to update so we route through
    // the V2 webapp
    const endpoint = `${this._appConfig.v1Url}/square/oauth?salonId=${salonId}`;

    return of(endpoint);
  }

  public getSalonInteractiveExternalUrl(salonId: string): Observable<string> {
    const endpoint = `${this._appConfig.v2Url}/salon-interactive/oauth?salonId=${salonId}`;

    return of(endpoint);
  }

  public addPhorestProvider(integration: PhorestIntegrationProvider): Observable<void> {
    const request = { ...integration };
    return this._httpRequestHandler.post<void>("integrations/phorest", request).pipe(map(() => undefined));
  }

  public addShortcutsProvider(integration: ShortcutsIntegrationProvider): Observable<void> {
    const request = { ...integration };

    return this._httpRequestHandler.post<void>("integrations/shortcuts", request).pipe(mapTo(undefined));
  }

  public addSalonbizProvider(integration: SalonbizIntegrationProvider): Observable<void> {
    const request = { ...integration };

    return this._httpRequestHandler.post<void>("integrations/salonbiz", request).pipe(mapTo(undefined));
  }

  public findSaloniqAvailableSalons(request: FetchSalonsRequest): Observable<Either<HttpError, SalonIqSalon[]>> {
    const payload = request;

    return this._httpRequestHandler.post<SalonIqSalon[]>("integrations/saloniq/locations", payload);
  }

  public addSaloniqProvider(value: SalonIqAddIntegrationRequest): Observable<Either<HttpError, void>> {
    const payload = { ...value };

    return this._httpRequestHandler.post<void>("integrations/saloniq", payload);
  }

  public findBoulevardAvailableSalons(payload: BoulevardFetchLocationsRequest): Observable<Either<HttpError, BoulevardLocationView[]>> {
    return this._httpRequestHandler.post<BoulevardLocationView[]>("integrations/boulevard/locations", payload);
  }

  public addBoulevardProvider(integrationRequest: BoulevardAddIntegrationRequest): Observable<Either<HttpError, void>> {
    const payload = { ...integrationRequest };

    return this._httpRequestHandler.post<void>("integrations/boulevard", payload);
  }

  public findEnvisionAvailableSalons(payload: EnvisionFetchLocationsRequest): Observable<Either<HttpError, EnvisionLocationView[]>> {
    return this._httpRequestHandler.post<EnvisionLocationView[]>("integrations/envision/locations", payload);
  }

  public addEnvisionProvider(integrationRequest: EnvisionAddIntegrationRequest): Observable<Either<HttpError, void>> {
    const payload = { ...integrationRequest };

    return this._httpRequestHandler.post<void>("integrations/envision", payload);
  }

  public addRosyProvider(integrationRequest: RosyAddIntegrationRequest): Observable<Either<HttpError, void>> {
    const payload = { ...integrationRequest };

    return this._httpRequestHandler.post<void>("integrations/rosy", payload);
  }

  public addZenotiProvider(request: ZenotiIntegrationRequest): Observable<Either<HttpError, void>> {
    return this._httpRequestHandler.post<void>("integrations/zenoti", {
      apiKey: request.apiKey,
      centerId: request.centerId,
    });
  }

  public findAuraAvailableSalons(payload: AuraFetchLocationsRequest): Observable<AuraLocationView[]> {
    return this._httpRequestHandler
      .get<AuraLocationView[]>(`integrations/aura/locations?token=${payload.token}&tenantSubdomain=${payload.tenantSubdomain}`)
      .pipe(
        mergeMap(
          fold(
            () => throwError(new Error("Request to fetch Aura Locations failed with None")),
            (locations) => of(locations)
          )
        )
      );
  }

  public addAuraProvider(integrationRequest: AuraAddIntegrationRequest): Observable<Either<HttpError, void>> {
    const payload = {
      locationId: integrationRequest.location.locationID,
      token: integrationRequest.token,
      tenantSubdomain: integrationRequest.tenantSubdomain,
    };

    return this._httpRequestHandler.post<void>("integrations/aura", payload);
  }

  public findMeevo2AvailableSalons(payload: Meevo2FetchLocationsRequest): Observable<Meevo2LocationView[]> {
    return this._httpRequestHandler.get<Meevo2LocationView[]>(`integrations/meevo2/locations?tenantId=${payload.tenantId}`).pipe(
      mergeMap(
        fold(
          () => throwError(new Error("Request to fetch Meevo2 locations failed with None")),
          (locations) => of(locations)
        )
      )
    );
  }

  public addMeevo2Provider(integrationRequest: Meevo2AddIntegrationRequest): Observable<Either<HttpError, void>> {
    return this._httpRequestHandler.post<void>("integrations/meevo2", integrationRequest);
  }

  public findMytimeAvailableSalons(payload: MytimeFetchLocationsRequest): Observable<MytimeLocationView[]> {
    return this._httpRequestHandler.get<MytimeLocationView[]>(`integrations/mytime/locations?apiKey=${payload.apiKey}`).pipe(
      mergeMap(
        fold(
          () => throwError(new Error("Request to fetch MyTime locations failed with None")),
          (locations) => of(locations)
        )
      )
    );
  }

  public addMytimeProvider(integrationRequest: MytimeAddIntegrationRequest): Observable<Either<HttpError, void>> {
    return this._httpRequestHandler.post<void>("integrations/mytime", integrationRequest);
  }

  public addKitombaProvider(integrationRequest: KitombaAddIntegrationRequest): Observable<Either<HttpError, void>> {
    return this._httpRequestHandler.post<void>("integrations/kitomba", integrationRequest);
  }

  public removeIntegration(provider: SalonSoftwareIntegration): Observable<Either<HttpError, void>> {
    return this._httpRequestHandler.delete<void>(`integrations/${provider.slug}`).pipe();
  }

  public enableIntegration(provider: SalonSoftwareIntegration): Observable<Either<HttpError, void>> {
    const headers = new HttpHeaders({
      "X-Salon-Slug": this._windowService.tenantPathName,
      "X-Auth-Token": this._authStorage.getAuthToken(),
    });

    return this._http.patch(`${this._httpUrl}/integrations/${provider.slug}/tenantConfig`, { isEnabled: true }, { headers }).pipe(
      map(() => right<HttpError, void>(undefined)),
      catchError((error: HttpErrorResponse) => of(left<HttpError, void>({ code: error.status, payload: error.error })))
    );
  }

  public disableIntegration(provider: SalonSoftwareIntegration): Observable<Either<HttpError, void>> {
    const headers = new HttpHeaders({
      "X-Salon-Slug": this._windowService.tenantPathName,
      "X-Auth-Token": this._authStorage.getAuthToken(),
    });

    return this._http.patch(`${this._httpUrl}/integrations/${provider.slug}/tenantConfig`, { isEnabled: false }, { headers }).pipe(
      map(() => right<HttpError, void>(undefined)),
      catchError((error: HttpErrorResponse) => of(left<HttpError, void>({ code: error.status, payload: error.error })))
    );
  }
}
