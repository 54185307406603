import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { ZenotiIntegrationRequest } from "../models";
import { addProvider, close } from "../store/actions/zenoti.actions";
import { getLoading, getError } from "../store/selectors/zenoti.selector";

@Component({
  selector: "add-provider-zenoti-container",
  templateUrl: "add-provider-zenoti.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderZenotiContainer {
  public loading$: Observable<boolean>;
  public error$: Observable<boolean>;

  constructor(public _store: Store<AppState>) {
    this.loading$ = this._store.select(getLoading);
    this.error$ = this._store.select(getError);
  }

  public save(request: ZenotiIntegrationRequest): void {
    this._store.dispatch(addProvider({ request }));
  }

  public close(): void {
    this._store.dispatch(close());
  }
}
