<slideout-panel (close)="close()">
  <header panel-header>Add Rosy Integration</header>

  <div panel-body>
    <div *ngIf="error$ | async">
      <alert-component type="danger"> It looks like something bad happened. Close this panel and try again. </alert-component>
    </div>
    <add-provider-rosy
      *ngIf="(loading$ | async) === false; else loadingElement"
      (save)="save($event)"></add-provider-rosy>
    <ng-template #loadingElement>
      <large-loading-indicator></large-loading-indicator>
    </ng-template>
  </div>
</slideout-panel>
