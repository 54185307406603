import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IntegrationDetails } from "app/+integrations/services";
import { getDetails, getError, getErrorMessage, getLoading } from "app/+integrations/store/selectors/edit-square-integration.selectors";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { close, loadData, navigateRevokeAccess } from "../../store/actions/edit-square-integration.actions";

@Component({
  selector: "manage-provider-square",
  templateUrl: "manage-provider-square.component.html",
  styleUrls: ["manage-provider-square.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageProviderSquareComponent implements OnInit {
  public loading$: Observable<boolean>;
  public error$: Observable<boolean>;
  public details$: Observable<IntegrationDetails>;
  public errorMessage$: Observable<string>;

  constructor(public _store: Store<AppState>) {
    this.loading$ = this._store.select(getLoading);
    this.error$ = this._store.select(getError);
    this.details$ = this._store.select(getDetails);
    this.errorMessage$ = this._store.select(getErrorMessage);
  }

  public ngOnInit(): void {
    this._store.dispatch(loadData());
  }

  public revokeAccess(): void {
    this._store.dispatch(navigateRevokeAccess());
  }

  public close(): void {
    this._store.dispatch(close());
  }
}
