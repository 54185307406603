import { Injectable } from "@angular/core";
import { HttpError } from "@getvish/stockpile";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { AddIntegrationRequest } from "app/+integrations/models/meevo2-integration-provider";
import { SalonSoftwareIntegrationService } from "app/+integrations/services";
import { go } from "app/kernel/store/actions/router.actions";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import { either } from "fp-ts";

import { AppState } from "app/kernel";
import { Close } from "../integrations.actions";
import { getTenantId, getVisitNoteTypeName } from "../selectors/meevo2.selector";

import * as actions from "../actions/meevo2.actions";

@Injectable()
export class Meevo2Effects {
  navigateAddMeevo2Provider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateAddMeevo2Provider),
      map(() => go({ path: ["/integrations", { outlets: { panel: "add-provider/meevo2" } }] }))
    )
  );

  fetchLocationsRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.fetchLocationsRequest),
      switchMap(({ request }) =>
        this._salonSoftwareIntegrationService.findMeevo2AvailableSalons(request).pipe(
          map((locations) => actions.fetchLocationsRequestSuccess({ availableSalons: locations })),
          catchError((error) => of(actions.fetchLocationsRequestFail({ error })))
        )
      )
    )
  );

  addProvider$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.addProvider),
      map((action) => action.location),
      withLatestFrom(this._store.pipe(select(getTenantId)), this._store.pipe(select(getVisitNoteTypeName))),
      switchMap(([location, tenantId, visitNoteTypeName]) => {
        const locationId = location.locationId;
        const request: AddIntegrationRequest = { tenantId, locationId, visitNoteTypeName, isEnabled: false };

        return this._salonSoftwareIntegrationService.addMeevo2Provider(request).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (error) => actions.addProviderFail({ error }),
              () => actions.addProviderSuccess()
            )
          )
        );
      })
    )
  );

  close$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.close, actions.addProviderSuccess),
      map(() => new Close())
    )
  );

  constructor(
    private _store: Store<AppState>,
    private _actions$: Actions,
    private _salonSoftwareIntegrationService: SalonSoftwareIntegrationService
  ) {}
}
