<dialog-contents
  dialogTitle="Edit Category Pricing"
  (close)="close()">
  <set-category-pricing-component
    [categoryId]="data.categoryId"
    [measurementUnit]="data.measurementUnit"
    [currency]="data.currency"
    (save)="save($event)"
    (close)="close()">
  </set-category-pricing-component>
</dialog-contents>
