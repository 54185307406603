<div class="verify-step">
  <div class="verify-step-header">
    <hero-boxes *ngLet="pricingFilter$ | async as pricingFilter">
      <hero-box
        [title]="{ text: 'Pricing Complete', backgroundColor: '#4CAF50' }"
        [content]="{ text: (numPriced$ | async) }"
        [active]="pricingFilter === 'PRICED'"
        (clicked)="setPricingFilter('PRICED')"></hero-box>
      <hero-box
        [title]="{ text: 'Pricing Incomplete', backgroundColor: '#f44336' }"
        [content]="{ text: (numUnpriced$ | async) }"
        [active]="pricingFilter === 'UNPRICED'"
        (clicked)="setPricingFilter('UNPRICED')"></hero-box>
      <hero-box
        [title]="{ text: 'All Products', backgroundColor: '#34383c' }"
        [content]="{ text: (salonProducts$ | async).length }"
        [active]="pricingFilter === 'ALL'"
        (clicked)="setPricingFilter('ALL')"></hero-box>
    </hero-boxes>
    <div class="header-buttons">
      <button
        *ngIf="isRootUser$ | async"
        [disabled]="filteredSalonProducts$ | async | isEmpty"
        class="button outline"
        (click)="showMasterPricing()">
        Master Pricing
      </button>
      <button
        [disabled]="filteredSalonProducts$ | async | isEmpty"
        class="button outline"
        (click)="setAllMarkup()">
        Set Markup
      </button>
    </div>
  </div>
  <salon-products-grouped-list
    class="product-grid"
    [products]="filteredSalonProducts$ | async"
    [productCategories]="allProductCategories$ | async"
    [measurementUnit]="measurementUnit$ | async"
    [currency]="currency$ | async"
    (editProductClicked)="editProductClicked($event)"
    (setCategoryPricingClicked)="setCategoryPricingClicked($event)"
    (setCategoryMarkupClicked)="setCategoryMarkup($event)"></salon-products-grouped-list>
</div>
