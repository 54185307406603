import { Injectable } from "@angular/core";
import { EntityService, HttpError, HttpRepositoryFactory, HttpRequestHandler, JsonObject, PagedResult } from "@getvish/stockpile";
import * as R from "ramda";

import { MasterList, MasterListProduct } from "../models/master-pricing.model";
import { Observable, forkJoin, map, mergeMap, of, switchMap, throwError } from "rxjs";
import { ManufacturerService } from "app/+product/+manufacturers/services";
import { Manufacturer, Product, Salon, SalonConfig } from "@getvish/model";
import * as E from "fp-ts/lib/Either";
import { RegionService } from "app/kernel";
import { getOrElse } from "fp-ts/lib/Option";
import { ProductPricing } from "../models/pricing";
import { SalonService } from "app/+salons/services";
import { Geometry } from "geojson";
import { distanceBetweenGeometries, geometriesIntersect } from "app/kernel/util/geometry";
import { IntegrationPricingService } from "app/+integrations/services/integration-pricing.service";

@Injectable()
export class MasterPricingService extends EntityService<MasterList> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _requestHandler: HttpRequestHandler,
    private _manufacturerService: ManufacturerService,
    private _regionService: RegionService,
    private _salonService: SalonService,
    private _integrationPricingService: IntegrationPricingService
  ) {
    super(repositoryFactory, { entityKey: "masterLists" });
  }

  public findHydrated(criteria?: JsonObject, sort?: {}, page?: number, limit?: number): Observable<PagedResult<MasterList>> {
    return this.find(criteria, sort, page, limit).pipe(
      switchMap((result) =>
        this._augmentMasterListsWithRegionNames(result.records).pipe(
          map((records) => ({
            ...result,
            records,
          }))
        )
      ),
      switchMap((result) => {
        const manufacturerIds = R.uniq(result.records.map((masterList) => masterList.manufacturerId));

        if (manufacturerIds.length === 0) {
          return of(result);
        }

        return this._manufacturerService.findByIds(manufacturerIds).pipe(
          map((manufacturers) => {
            const groupedManufacturers = R.groupBy((m: Manufacturer) => m._id)(manufacturers);

            return {
              ...result,
              records: result.records.map((masterList) => {
                return {
                  ...masterList,
                  manufacturer: groupedManufacturers[masterList.manufacturerId][0],
                };
              }),
            };
          })
        );
      })
    );
  }

  public findByIdHydrated(masterListId: string): Observable<MasterList> {
    return this.findHydrated({ _id: masterListId }).pipe(map((result) => result.records[0]));
  }

  private _augmentMasterListsWithRegionNames(masterLists: MasterList[]): Observable<MasterList[]> {
    if (masterLists.length === 0) {
      return of(masterLists);
    }

    return this._regionService.getAllAvailableRegions().pipe(
      switchMap((regions) => {
        return forkJoin(
          masterLists.map((masterList) => {
            let regionId = masterList.region.toLowerCase();

            // Fix a few region names that don't match the region name in the region service
            if (regionId === "canada") {
              regionId = "can";
            } else if (regionId === "australia") {
              regionId = "aus";
            } else if (regionId === "uk") {
              regionId = "gbr";
            }

            const matchingRegion = regions.find((region) => region.id.toLowerCase() === regionId);

            if (matchingRegion != null) {
              if (masterList.geometry == null) {
                return this.update({ ...masterList, geometry: matchingRegion.geometry }).pipe(
                  map(() => ({
                    ...masterList,
                    region: matchingRegion.id,
                    regionName: matchingRegion.name,
                    geometry: matchingRegion.geometry,
                  }))
                );
              }

              return of({
                ...masterList,
                region: matchingRegion.id,
                regionName: matchingRegion.name,
              });
            }

            return of(masterList);
          })
        );
      })
    );
  }

  public findProducts(masterListId: string): Observable<MasterListProduct[]> {
    return this._requestHandler.get<MasterListProduct[]>(`masterLists/${masterListId}/products`).pipe(map(getOrElse(() => [])));
  }

  public createMasterListWithProducts(masterList: Partial<MasterList>, masterListProducts: Partial<MasterListProduct>[]): Observable<void> {
    return this._requestHandler.post("masterLists/products", { masterList, masterListProducts }).pipe(
      mergeMap(
        E.fold(
          (error) => throwError(() => error),
          () => of(undefined)
        )
      )
    );
  }

  public propagateToSalons(masterList: MasterList): Observable<E.Either<HttpError, void>> {
    return this._requestHandler.post(`masterProductPricing/apply`, { masterListId: masterList._id });
  }

  public determineProductPricing(salonConfig: SalonConfig, products: Product[]): Observable<{ [productId: string]: ProductPricing }> {
    return this._integrationPricingService.getProductPricing(products).pipe(
      switchMap((integrationPricing) => {
        const manufacturerIds = R.uniq(products.map((product) => product.manufacturerId));

        if (manufacturerIds.length === 0) {
          return of({});
        }

        return forkJoin([this._salonService.getLocation(salonConfig.salonId), this._salonService.findByIdOrDie(salonConfig.salonId)]).pipe(
          switchMap(([geometry, salon]) => {
            return forkJoin(
              manufacturerIds.map((manufacturerId) => {
                const manufacturerProducts = products.filter((product) => product.manufacturerId === manufacturerId);

                return this.determineProductPricingForManufacturer(manufacturerId, manufacturerProducts, salon, salonConfig, geometry);
              })
            ).pipe(
              map((pricings) => pricings.reduce((acc, pricing) => ({ ...acc, ...pricing }), {})),
              map((manufacturerPricings) => {
                const pricing = { ...integrationPricing };

                for (const [productId, productPricing] of Object.entries(manufacturerPricings)) {
                  pricing[productId] = {
                    wholesalePrice: integrationPricing[productId]?.wholesalePrice ?? productPricing.wholesalePrice,
                    markup: integrationPricing[productId]?.markup ?? productPricing.markup,
                    containerSize: integrationPricing[productId]?.containerSize ?? productPricing.containerSize,
                  };
                }

                return pricing;
              })
            );
          })
        );
      })
    );
  }

  private determineProductPricingForManufacturer(
    manufacturerId: string,
    products: Product[],
    salon: Salon,
    salonConfig: SalonConfig,
    salonLocation: Geometry
  ): Observable<{ [productId: string]: ProductPricing }> {
    return this.findRelevantPricingListsSorted(manufacturerId, salon, salonLocation).pipe(
      switchMap((pricingLists) => {
        if (pricingLists.length === 0 && salonLocation != null) {
          return this.findRelevantPricingListsSorted(manufacturerId, salon, salonLocation, false);
        }

        return of(pricingLists);
      }),
      switchMap((pricingLists) => this.priceProductsWithLists(salonConfig, products, pricingLists))
    );
  }

  private findRelevantPricingListsSorted(
    manufacturerId: string,
    salon: Salon,
    salonLocation: Geometry,
    filterByRegion = true
  ): Observable<MasterList[]> {
    return this.find({ manufacturerId }).pipe(
      map((result) => result.records),
      map((pricingLists) => {
        if (!filterByRegion) {
          return pricingLists;
        }

        return pricingLists.filter((pricingList) => {
          if (salonLocation != null && pricingList.geometry != null) {
            if (geometriesIntersect(pricingList.geometry, salonLocation)) {
              return true;
            }
          }

          const salonCountry = salon.address?.country;

          if (salonCountry != null && pricingList.region != null) {
            return pricingList.region.toLowerCase().trim() === salonCountry.toLowerCase().trim();
          }

          return false;
        });
      }),
      map(
        R.sortWith([
          (a, b) => {
            if (salonLocation != null) {
              const distance1 = a.geometry != null ? distanceBetweenGeometries(salonLocation, a.geometry) : Number.MAX_SAFE_INTEGER;
              const distance2 = b.geometry != null ? distanceBetweenGeometries(salonLocation, b.geometry) : Number.MAX_SAFE_INTEGER;

              return distance1 - distance2;
            }

            return 0;
          },
          (a, b) => {
            return b.year - a.year;
          },
          (a, b) => {
            return a.createdAt > b.createdAt ? -1 : 1;
          },
        ])
      )
    );
  }

  private priceProductsWithLists(
    salonConfig: SalonConfig,
    products: Product[],
    pricingLists: MasterList[]
  ): Observable<{ [productId: string]: ProductPricing }> {
    if (pricingLists.length === 0) {
      return of({});
    }

    const pricingList = pricingLists[0];

    return this.findProducts(pricingList._id).pipe(
      switchMap((masterListProducts) => {
        const masterListProductsById = R.indexBy((mlp) => mlp.globalProductId, masterListProducts);

        const productPricing = products.reduce((acc, product) => {
          const masterListProduct = masterListProductsById[product._id];

          if (masterListProduct == null) {
            return acc;
          }

          return {
            ...acc,
            [product._id]: {
              wholesalePrice: masterListProduct.wholesaleCost,
              markup: salonConfig.defaultProductMarkup ?? 1,
              containerSize: masterListProduct.containerSize,
            },
          };
        }, {});

        const unpricedProducts = products.filter((product) => productPricing[product._id] == null);

        if (unpricedProducts.length === 0) {
          return of(productPricing);
        }

        return this.priceProductsWithLists(salonConfig, unpricedProducts, pricingLists.slice(1)).pipe(
          map((pricing) => {
            return {
              ...productPricing,
              ...pricing,
            };
          })
        );
      })
    );
  }
}
