import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";

import { AddIntegrationRequest } from "../models/kitomba-integration-provider";
import { addProvider, close } from "../store/actions/kitomba.actions";
import { getSaving, getError } from "../store/selectors/kitomba.selector";

@Component({
  selector: "add-provider-kitomba-container",
  templateUrl: "add-provider-kitomba.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProviderKitombaContainer {
  public saving$: Observable<boolean>;
  public error$: Observable<string>;

  constructor(public _store: Store<AppState>) {
    this.saving$ = this._store.select(getSaving);
    this.error$ = this._store.select(getError);
  }

  public addProvider(request: AddIntegrationRequest): void {
    this._store.dispatch(addProvider({ request }));
  }

  public close(): void {
    this._store.dispatch(close());
  }
}
