import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  templateUrl: "./set-markup-dialog.component.html",
  styleUrls: ["./set-markup-dialog.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetMarkupDialogComponent {
  public form: UntypedFormGroup;

  constructor(
    private _matDialogRef: MatDialogRef<SetMarkupDialogComponent, number>,
    private _fb: UntypedFormBuilder
  ) {
    this.form = this._fb.group({
      markupPercent: [null, Validators.required],
    });
  }

  public close(): void {
    this._matDialogRef.close();
  }

  public save(markup: number): void {
    this._matDialogRef.close(markup == null ? undefined : markup / 100);
  }
}
