import { NgModule } from "@angular/core";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import {
  faArrowLeft,
  faCirclePlus,
  faCloudUploadAlt,
  faDroplet,
  faEllipsisV,
  faListRadio,
  faListTree,
} from "@fortawesome/pro-solid-svg-icons";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../../kernel/shared";

import { ManufacturersSharedModule } from "../+manufacturers";
import { ProductCategoriesSharedModule } from "../+product-categories";
import { KernelServicesModule } from "../../kernel";
import { SlideoutPanelModule } from "../../kernel/shared/rocket-ui/slideout-panel";

import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTreeModule } from "@angular/material/tree";
import { CardModule, PageModule, ProgressIndicatorModule } from "app/+components";
import { DialogModule } from "app/+components/+dialog";
import { ListOrdererModule } from "app/+components/+list-orderer/list-orderer.module";
import { ProductSelectionModule } from "../+product-selection/product-selection.module";
import { ProductsSharedModule } from "../+products/products-shared.module";
import { ProductEffects } from "../+products/store/product.effects";
import { productReducer } from "../+products/store/product.reducer";
import { SalonProductCategoriesProductListComponent, SalonProductExtraDetailsComponent } from "./components";
import { ManufacturerOrderDialogComponent } from "./components/manufacturer-order-dialog/manufacturer-order-dialog.component";
import { ProductCategoryOrderDialogComponent } from "./components/product-category-order-dialog/product-category-order-dialog.component";
import { ProductOrderDialogComponent } from "./components/product-order-dialog/product-order-dialog.component";
import { UploadSalonProductSpreadsheetFormComponent } from "./components/upload-salon-product-spreadsheet-form.component";
import {
  EditSalonProductContainer,
  SalonProductManagementContainer,
  SalonProductsContainer,
  SetCategoryPricingContainer,
} from "./containers";
import { SetMarkupContainer } from "./containers/set-markup.container";
import { UploadSalonProductSpreadsheetContainer } from "./containers/upload-salon-product-spreadsheet.container";
import { SalonProductsRoutingModule } from "./salon-products-routing.module";
import { SalonProductsSharedModule } from "./salon-products-shared.module";
import { SalonManufacturerService } from "./services";
import { SalonProductSpreadsheetService } from "./services/salon-product-spreadsheet.service";
import { SalonProductEffects } from "./store/salon-product.effects";
import { salonProductReducer } from "./store/salon-product.reducer";

@NgModule({
  imports: [
    CardModule,
    PageModule,
    SharedModule,
    KernelServicesModule,
    SalonProductsRoutingModule,
    SlideoutPanelModule,
    ManufacturersSharedModule,
    ProductCategoriesSharedModule,
    ProductsSharedModule,
    SalonProductsSharedModule,
    ProductSelectionModule,
    ProgressIndicatorModule,
    StoreModule.forFeature("salonProducts", salonProductReducer),
    StoreModule.forFeature("products", productReducer),
    EffectsModule.forFeature([SalonProductEffects, ProductEffects]),
    MatTreeModule,
    MatStepperModule,
    MatChipsModule,
    DialogModule,
    ListOrdererModule,
  ],
  declarations: [
    SalonProductsContainer,
    EditSalonProductContainer,
    SalonProductManagementContainer,
    SetCategoryPricingContainer,
    SalonProductCategoriesProductListComponent,
    SalonProductExtraDetailsComponent,
    UploadSalonProductSpreadsheetContainer,
    UploadSalonProductSpreadsheetFormComponent,
    ManufacturerOrderDialogComponent,
    ProductCategoryOrderDialogComponent,
    ProductOrderDialogComponent,
    SetMarkupContainer,
  ],
  providers: [SalonProductSpreadsheetService, SalonManufacturerService],
})
export class SalonProductsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faArrowLeft, faEllipsisV, faCloudUploadAlt, faCirclePlus, faDroplet, faListTree, faListRadio);
  }
}
