import { fromMeasurementType, MeasurementUnitType, SalonConfig } from "@getvish/model";
import { getComplianceRatio, Metrics } from "app/kernel/models";
import { Observable, of } from "rxjs";
import * as XLSX from "xlsx-js-style";

export type MetricsRow = {
  name: string;
  metrics: Metrics;
};

export const generateMetricsXlsxReport = (sheetName: string, rows: MetricsRow[], salonConfig: SalonConfig): Observable<Blob> => {
  const rowDefinitions = [
    { key: "name", header: "Name" },
    {
      key: "numServicesPerformed",
      header: "Services",
      exclude: salonConfig.enableComplianceTracking,
      fn: (r) => r.metrics.numServicesPerformed,
    },
    {
      key: "numMixableServices",
      header: "Services",
      exclude: !salonConfig.enableComplianceTracking,
      fn: (r) => r.metrics.numMixableServices,
    },
    { key: "mixed", header: "Mixed", exclude: !salonConfig.enableComplianceTracking, fn: (r) => r.metrics.numMixedServices },
    {
      key: "wholesaleCostDollars",
      header: "Product Dispensed",
      fn: (r) => formatCurrency(r.metrics.wholesaleCostDollars),
      type: "currency",
    },
    {
      key: "productDispensedPerServiceWholesaleCost",
      header: "Product Dispensed Per Service",
      fn: (r) => formatCurrency(r.metrics.productDispensedPerServiceWholesaleCost),
      type: "currency",
    },
    {
      key: "completedFormulaRatio",
      header: "% Reweighed",
      fn: (r) => formatPercent(r.metrics.completedFormulaRatio),
      type: "percent",
    },
    {
      key: "wasteAmountGrams",
      header: "Product Waste",
      fn: (r) => formatMeasurement(r.metrics.wasteAmountGrams, salonConfig),
      type: "measurement",
    },
    {
      key: "estimatedWasteDollars",
      header: "Estimated Waste",
      fn: (r) => formatCurrency(r.metrics.estimatedWasteDollars),
      type: "currency",
    },
    {
      key: "estimatedWastePerServiceDollars",
      header: "Estimated Waste Per Service",
      fn: (r) => formatCurrency(r.metrics.estimatedWastePerServiceDollars),
      type: "currency",
    },
    {
      key: "productOveruseDollars",
      header: "Product Charges",
      fn: (r) => formatCurrency(r.metrics.productOveruseDollars),
      type: "currency",
    },
    {
      key: "wasteRatio",
      header: "% Waste",
      fn: (r) => formatPercent(r.metrics.wasteRatio),
      type: "percent",
    },
    {
      key: "complianceRatio",
      header: "% Engagement",
      exclude: !salonConfig.enableComplianceTracking,
      fn: (r) => formatPercent(getComplianceRatio(r.metrics)),
      type: "percent",
    },
  ] as { key: string; header: string; exclude?: boolean; fn?: (r: MetricsRow) => any; type?: string }[];

  const data = (rows ?? []).map((row) => {
    return rowDefinitions.reduce((acc, def) => {
      if (def.exclude) {
        return acc;
      }

      acc[def.header] = def.fn ? def.fn(row) : row[def.key];
      return acc;
    }, {});
  });

  const worksheet = XLSX.utils.json_to_sheet(data);

  worksheet["!cols"] = [];

  const range = XLSX.utils.decode_range(worksheet["!ref"]);

  let r = 0;
  for (const row of rowDefinitions.filter((def) => !def.exclude)) {
    if (row.type === "currency") {
      const currencySymbol = (0)
        .toLocaleString(undefined, {
          style: "currency",
          currency: salonConfig.currency ?? "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();

      salonConfig.currency;
      formatColumn(worksheet, r, (cell) => {
        cell.t = "n";
        cell.z = `"${currencySymbol}"#,##0.00_);\\("${currencySymbol}"#,##0.00\\)`;
      });
    } else if (row.type === "percent") {
      formatColumn(worksheet, r, (cell) => {
        cell.t = "n";
        cell.z = "0%";
      });
    } else if (row.type === "measurement") {
      const measurementUnit = fromMeasurementType(salonConfig.measurementUnit ?? MeasurementUnitType.GRAMS);

      formatColumn(worksheet, r, (cell) => {
        cell.t = "n";
        cell.z = `0.0#"${measurementUnit.abbreviation}"`;
      });
    }

    ++r;
  }

  for (let col = range.s.c; col <= range.e.c; col++) {
    const ref = XLSX.utils.encode_cell({ r: 0, c: col });
    if (worksheet[ref]) {
      worksheet[ref].s = { font: { bold: true, color: { rgb: "FFFFFF" } }, fill: { patternType: "solid", fgColor: { rgb: "CE0F69" } } };
    }

    let maxLength = 0;
    for (let row = range.s.r; row <= range.e.r; row++) {
      const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
      const v = worksheet[cellRef]?.v;

      if (v != null && `${v}`.length > maxLength) {
        maxLength = `${v}`.length;
      }
    }

    worksheet["!cols"][col] = { wch: maxLength + 3 };
  }

  for (let col = range.s.c; col <= range.e.c; col++) {
    for (let row = range.s.r; row <= range.e.r; row++) {
      const ref = XLSX.utils.encode_cell({ r: row, c: col });
      if (worksheet[ref]) {
        worksheet[ref].s = { ...(worksheet[ref].s ?? {}), font: { ...(worksheet[ref].s?.font ?? {}), name: "Rubik" } };
      }
    }
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName.substring(0, 31));

  return of(new Blob([XLSX.write(workbook, { bookType: "xlsx", type: "array" })], { type: "application/octet-stream" }));
};

const formatCurrency = (value: number): number => {
  if (value == null) {
    return undefined;
  }

  return Number(value.toFixed(2));
};

const formatPercent = (value: number, precision?: number): number => {
  if (value == null) {
    return undefined;
  }

  return Number(value.toFixed(precision ?? 2));
};

const formatMeasurement = (value: number, salonConfig: SalonConfig): number => {
  if (value == null) {
    return undefined;
  }

  const measurementUnit = fromMeasurementType(salonConfig.measurementUnit ?? MeasurementUnitType.GRAMS);
  return Number(value.toFixed(measurementUnit.precision));
};

const formatColumn = (worksheet: XLSX.WorkSheet, col: number, fn: (cell: any) => void) => {
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let row = range.s.r + 1; row <= range.e.r; ++row) {
    const ref = XLSX.utils.encode_cell({ r: row, c: col });
    if (worksheet[ref]) {
      fn(worksheet[ref]);
    }
  }
};
