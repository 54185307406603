import { Injectable } from "@angular/core";
import { NotificationService } from "app/+notifications/services";
import { filter, map, Observable, of, switchMap, take } from "rxjs";
import { AppState } from "app/kernel";
import { go } from "app/kernel/store/actions/router.actions";
import { Store } from "@ngrx/store";
import * as fromCurrentTenant from "app/kernel/store/reducers/current-tenant.reducer";
import { getSalonConfig } from "app/+salon-config/store/salon-config.selectors";
import * as R from "ramda";
import { Notification } from "app/+notifications/models";
import * as serviceMenuActions from "../store/service-menu.actions";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { getComplianceAnomalies } from "../store/service-menu.selectors";
import { getUtcDateRangeDaysFromZonedTime } from "app/kernel/util/zoned-time-utils";

@Injectable()
export class ServiceMenuNotificationsService {
  private _notificationsEnabled = false;

  constructor(
    private _notificationsService: NotificationService,
    private _store: Store<AppState>
  ) {}

  public enableNotifications() {
    if (this._notificationsEnabled) {
      return;
    }

    this._store
      .select(fromCurrentTenant.getCurrentTenant)
      .pipe(
        filter((tenant) => tenant !== undefined),
        take(1)
      )
      .subscribe(() => {
        this._notificationsService.registerNotificationObservable(
          "service-menu",
          this._store.select(getSalonConfig).pipe(
            filter((config) => R.not(R.isNil(config))),
            switchMap((config) => {
              if (config.enableComplianceTracking) {
                return this._generateComplianceNotifications(config.timeZone);
              }

              return of([]);
            })
          )
        );
      });
  }

  private _generateComplianceNotifications(timeZone: string): Observable<Notification[]> {
    const { startDate, endDate } = getUtcDateRangeDaysFromZonedTime(timeZone, 30);

    this._store.dispatch(new serviceMenuActions.LoadServiceComplianceAnomalies({ startDate, endDate }));

    return this._store.select(getComplianceAnomalies).pipe(
      filter((anomalies) => anomalies != null),
      map((anomalies) => {
        if (anomalies == null || anomalies.length === 0) {
          return [];
        }

        const title = anomalies.length === 1 ? "Service Engagement Anomaly" : "Service Engagement Anomalies";
        const description =
          anomalies.length === 1
            ? "There is an uncategorized or inactive service in the Service Menu that has been used for mixing."
            : `There are ${anomalies.length} uncategorized or inactive services in the Service Menu that have been used for mixing.`;

        return [
          {
            id: "services-compliance-anomalies",
            icon: "shopping-basket" as IconName,
            title,
            description,
            action: () =>
              this._store.dispatch(
                go({
                  path: ["/service-menu"],
                  query: { tableFilter: JSON.stringify({ flags: ["ANOMALY"] }), showInactive: true },
                })
              ),
          },
        ];
      })
    );
  }
}
