import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { close, navigateRevokeAccess } from "../store/actions/edit-square-integration.actions";
import { getLoading, getError } from "../store/selectors/zenoti.selector";

@Component({
  selector: "edit-provider-square-container",
  templateUrl: "edit-provider-square.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProviderSquareContainer {
  public loading$: Observable<boolean>;
  public error$: Observable<boolean>;

  constructor(public _store: Store<AppState>) {
    this.loading$ = this._store.select(getLoading);
    this.error$ = this._store.select(getError);
  }

  public revokeAccess(): void {
    this._store.dispatch(navigateRevokeAccess());
  }

  public close(): void {
    this._store.dispatch(close());
  }
}
