import { Action, createReducer, on } from "@ngrx/store";

import { SalonIqSalon } from "../../models/saloniq-salon";
import * as SaloniqActions from "../actions/saloniq.actions";

export interface SaloniqState {
  error: boolean;
  loading: boolean;
  idToken: string;
  availableSalons: SalonIqSalon[];
}

export const initialState: SaloniqState = {
  error: undefined,
  loading: false,
  idToken: undefined,
  availableSalons: undefined,
};

const saloniqReducer = createReducer(
  initialState,
  on(SaloniqActions.saloniqLoadAvailableSalons, (state, { idToken }) => ({
    ...state,
    loading: true,
    idToken,
  })),
  on(SaloniqActions.saloniqLoadAvailableSalonsSuccess, (state, { availableSalons }) => ({
    ...state,
    error: false,
    loading: false,
    availableSalons,
  })),
  on(SaloniqActions.saloniqLoadAvailableSalonsFail, (state) => ({
    ...state,
    loading: false,
    error: true,
  })),
  on(SaloniqActions.saloniqAddProviderSuccess, (state) => ({
    ...state,
    error: false,
    loading: false,
    availableSalons: undefined,
  }))
);

export function reducer(state: SaloniqState | undefined, action: Action) {
  return saloniqReducer(state, action);
}

export const saloniqFeatureKey = "saloniq";
