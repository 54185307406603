import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  Renderer2,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ContentChild,
  AfterContentInit,
  OnInit,
  OnDestroy,
} from "@angular/core";
import * as R from "ramda";
import { Subscription } from "rxjs";

import { Paging } from "../models";
import {
  ColumnsDirective,
  ColumnDirective,
  ColumnIconDirective,
  BodyDirective,
  RowDirective,
  CategoryDirective,
  FooterDirective,
} from "../directives";
import { Selections } from "../models/selections";
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from "@angular/material/checkbox";
import { LocalStorage } from "app/kernel";
import { JsonObject } from "@getvish/stockpile";

@Component({
  selector: "data-table",
  templateUrl: "data-table.component.html",
  styleUrls: ["data-table.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: "noop" } as MatCheckboxDefaultOptions }],
})
export class DataTableComponent implements OnChanges, OnDestroy, OnInit, AfterContentInit {
  @ContentChild(ColumnsDirective)
  private _columns: ColumnsDirective;

  @ContentChild(BodyDirective)
  public body: BodyDirective;

  @ContentChild(FooterDirective)
  public footer: FooterDirective;

  @Input()
  public paging?: Paging;

  @Input()
  public selections?: Selections;

  @Input()
  public bodyClass?: string;

  @Input()
  public localStorageId?: string;

  @Input()
  public loading?: boolean;

  @ViewChild("columnMenu") columnMenuRef: ElementRef;
  @ViewChild("table") tableRef: ElementRef;

  public rowsByCategory: Array<{ category: CategoryDirective; rows: Array<RowDirective> }>;
  public displayedColumns: Array<ColumnDirective>;
  public managedColumns: Array<ColumnDirective>;
  public hasHeader: boolean;
  public _menuOpenedColId: string;
  public _filtersOpenedColId: string;
  public _manageColumnsOpened: boolean;
  private _outsideClickListenerDestroyer;

  private _columnsSubscription: Subscription;
  private _bodySubscription: Subscription;

  private resizeObserver: ResizeObserver;
  private columnWidthCache: { [columnId: string]: number };

  private _columnVisibility: {
    [column: string]: boolean;
  };

  constructor(
    private ref: ChangeDetectorRef,
    private renderer: Renderer2,
    private _localStorage: LocalStorage
  ) {}

  public ngOnInit(): void {
    // Close column menu if we click outside.
    this._outsideClickListenerDestroyer = this.renderer.listen("window", "mousedown", (e: Event) => {
      if (this._filtersOpenedColId != null && !this._findParents(e.target, [this.columnMenuRef?.nativeElement])) {
        this.filtersOpened();
        this.ref.markForCheck();
      }

      if (this._manageColumnsOpened != null && !this._findParents(e.target, [this.columnMenuRef?.nativeElement])) {
        this.manageColumnsOpened(false);
        this.ref.markForCheck();
      }
    });
  }

  public ngAfterContentInit(): void {
    this._columnsSubscription = this._columns.values.changes.subscribe(() => {
      this.ngOnChanges();
      this.ref.markForCheck();
    });

    this._bodySubscription = this.body.values.changes.subscribe(() => {
      this.ngOnChanges();
      this.ref.markForCheck();
    });

    this.ngOnChanges();
  }

  public ngOnDestroy(): void {
    this._outsideClickListenerDestroyer();

    if (this._columnsSubscription != null) {
      this._columnsSubscription.unsubscribe();
    }

    if (this._bodySubscription != null) {
      this._bodySubscription.unsubscribe();
    }

    if (this.resizeObserver != null) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  public ngOnChanges(): void {
    if (!this._columns) {
      return;
    }

    if (this._columnVisibility == null) {
      this._columnVisibility =
        this.localStorageId != null
          ? JSON.parse(this._localStorage.getItem(`DATA_TABLE_COLUMN_MANAGER_${this.localStorageId}`) ?? "{}")
          : {};
    }

    const sortByCategoryTitle = R.sortBy(R.compose(R.toLower, R.path(["category", "title"])));

    this.rowsByCategory = sortByCategoryTitle(
      Object.values(
        this.body.values.reduce(
          (acc, cur) => {
            if (acc[cur.category?.id] == null) {
              acc[cur.category?.id] = { category: cur.category, rows: [] };
            }

            acc[cur?.category?.id].rows.push(cur);

            return acc;
          },
          {} as { [columnId: string]: { category: CategoryDirective; rows: Array<RowDirective> } }
        )
      )
    );

    this.hasHeader = this._columns.values.some((c) => c.title != null);

    this.displayedColumns = this._columns.values.filter((c) => this.isColumnVisible(c));

    this.managedColumns = this._columns.values.filter((c) => c.showInColumnManager);
  }

  public getColumnHeaderClass(c: ColumnDirective): string {
    const classes = [
      c.sort ? "sortable" : null,
      c.filter ? "filterable" : null,
      this.managedColumns.length > 0 && c.title != null ? "manageable" : null,
      c.filter?.value || this._filtersOpenedColId === c.id ? "filtered" : null,
      this._filtersOpenedColId === c.id ? "filtering" : null,
      this.isFront(c) ? "front" : "back",
      c.icons?.values.length ? "withIcons" : null,
    ];

    return classes.filter((_c) => _c != null).join(" ");
  }

  private isFront(c: ColumnDirective): boolean {
    return this.displayedColumns.findIndex((_c) => _c.id === c.id) < this.displayedColumns.length / 2;
  }

  public getCategoryColSpan(c: CategoryDirective): number {
    return this.displayedColumns.length - (c.change.observed ? 1 : 0) - 1;
  }

  public menuOpened(columnId?: string) {
    this._menuOpenedColId = columnId;
  }

  public filtersOpened(columnId?: string) {
    this._filtersOpenedColId = columnId;
  }

  public isColumnVisible(column: ColumnDirective): boolean {
    return (
      !column.hidden &&
      (!column.showInColumnManager ||
        (this._columnVisibility[column.id] == null && column.showByDefault) ||
        this._columnVisibility[column.id] === true)
    );
  }

  public setColumnVisibilty(columnId: string, visible = true) {
    this._columnVisibility[columnId] = visible;

    if (this.localStorageId != null) {
      this._localStorage.setItem(`DATA_TABLE_COLUMN_MANAGER_${this.localStorageId}`, JSON.stringify(this._columnVisibility));
    }

    this.ngOnChanges();
    this.ref.markForCheck();
  }

  public manageColumnsOpened(opened: boolean = true) {
    this._manageColumnsOpened = opened;
  }

  public iconClicked(icon: ColumnIconDirective) {
    if (icon.url) {
      window.open(icon.url, "_blank");
    } else if (icon.onClick) {
      icon.onClick();
    }
  }

  private _findParents(curEle, elesToFind) {
    if (curEle == null) {
      return false;
    }

    // This is very hacky, but how else will we know we are clicking a mat option outside the filters?
    if (curEle.nodeName === "MAT-OPTION") {
      return true;
    }

    if (elesToFind.includes(curEle)) {
      return true;
    }

    return this._findParents(curEle.parentElement, elesToFind);
  }

  public columnTrackBy(idx: number, column: ColumnDirective) {
    return column.id;
  }

  public rowTrackBy(idx: number, row: RowDirective) {
    return row.id;
  }

  public rowsByCategoryTrackBy(idx: number, row: { category: CategoryDirective; rows: Array<RowDirective> }) {
    return row?.category?.id ?? "";
  }

  public getStickyStyle(column: ColumnDirective) {
    if (this.tableRef == null || !column.sticky) {
      return;
    }

    if (this.resizeObserver == null) {
      this.resizeObserver = new ResizeObserver(() => {
        this.columnWidthCache = null;
        this.ref.markForCheck();
      });

      for (const col of this.displayedColumns) {
        if (col.sticky) {
          this.resizeObserver.observe(this.tableRef.nativeElement.querySelector(`#${col.id}`));
        }
      }
    }

    if (this.columnWidthCache == null) {
      this.columnWidthCache = {};
      for (const col of this.displayedColumns) {
        if (col.sticky) {
          this.columnWidthCache[col.id] = this.tableRef.nativeElement.querySelector(`#${col.id}`).getBoundingClientRect().width;
        }
      }
    }

    const style = {
      position: "sticky",
      "z-index": 1,
    } as JsonObject;

    if (this.isFront(column)) {
      let left = 0;
      for (const col of this.displayedColumns) {
        if (col.sticky) {
          if (col.id === column.id) {
            break;
          }

          left += this.columnWidthCache[col.id];
        }
      }

      style.left = `${left}px`;
    } else {
      let right = 0;
      for (const col of [...this.displayedColumns].reverse()) {
        if (col.sticky) {
          if (col.id === column.id) {
            break;
          }

          right += this.columnWidthCache[col.id];
        }
      }

      style.right = `${right}px`;
    }

    return style;
  }
}
