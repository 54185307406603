import { IntegrationSoftwareProvider } from "app/kernel/models/salon-software-provider";
import { of, Observable } from "rxjs";
import { Injectable } from "@angular/core";

const providers: IntegrationSoftwareProvider[] = [
  {
    name: "Aura",
    slug: "aura",
    provider: "AURA",
    logo: "assets/images/integrations/aura-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "Booker",
    slug: "booker",
    provider: "BOOKER",
    logo: "assets/images/integrations/booker-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "Shortcuts",
    slug: "shortcuts",
    provider: "SHORTCUTS",
    logo: "assets/images/integrations/shortcuts-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "SalonBiz",
    slug: "salonbiz",
    provider: "SALONBIZ",
    logo: "assets/images/integrations/salon-biz-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "SaloniQ",
    slug: "saloniq",
    provider: "SALONIQ",
    logo: "assets/images/integrations/salon-iq-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "Boulevard",
    slug: "boulevard",
    provider: "BOULEVARD",
    logo: "assets/images/integrations/blvd-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "Envision",
    slug: "envision",
    provider: "ENVISION",
    logo: "assets/images/integrations/envision-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "Meevo2",
    slug: "meevo2",
    provider: "MEEVO2",
    logo: "assets/images/integrations/meevo-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "MyTime",
    slug: "mytime",
    provider: "MYTIME",
    logo: "assets/images/integrations/mytime-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "Phorest",
    slug: "phorest",
    provider: "PHOREST",
    logo: "assets/images/integrations/phorest-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "Rosy",
    slug: "rosy",
    provider: "ROSY",
    logo: "assets/images/integrations/rosy-logo.jpg",
    canEdit: false,
    type: "pos",
  },
  {
    name: "Zenoti",
    slug: "zenoti",
    provider: "ZENOTI",
    logo: "assets/images/integrations/zenoti-logo.png",
    canEdit: false,
    type: "pos",
  },
  {
    name: "Square",
    slug: "square",
    provider: "SQUARE",
    logo: "assets/images/integrations/square-logo.png",
    canEdit: true,
    type: "pos",
  },
  {
    name: "SalonInteractive",
    slug: "salon-interactive",
    provider: "SALONINTERACTIVE",
    logo: "assets/images/integrations/salon-interactive-logo.png",
    canEdit: false,
    type: "products",
  },
  {
    name: "Kitomba",
    slug: "kitomba",
    provider: "KITOMBA",
    logo: "assets/images/integrations/kitomba-logo.png",
    canEdit: false,
    type: "pos",
  },
];

@Injectable()
export class IntegrationSoftwareProviderService {
  public findUserIntegrations(): Observable<IntegrationSoftwareProvider[]> {
    // at the moment end users can only configure either a Booker or Square integration
    // so don't offer any of the other integration providers
    const userIntegrationSlugs = ["square", "booker"];

    const userIntegrations = providers.filter((provider) => userIntegrationSlugs.includes(provider.slug));

    return of(userIntegrations);
  }

  public findAdminIntegrations(): Observable<IntegrationSoftwareProvider[]> {
    // of course admin/internal users are able to configure any type of intergration
    // so return all possible providers
    return of(providers);
  }
}
